










































































import VolumeComponent from "./VolumeComponent.vue";
import { getUrlParam } from "../../common/Utility";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";

@Component({
  components: {
    VolumeComponent
  }
})
export default class BGMCoreComponent extends Vue {
  @Action("setProperty") private setProperty: any;
  @Getter("masterMute") private masterMute: any;
  @Getter("masterVolume") private masterVolume: any;

  @Prop({ type: String, required: true })
  private bgmKey!: string;

  @Prop({ type: String, required: true })
  private tag!: string;

  @Prop({ type: Boolean, required: true })
  private isLoop!: boolean;

  @Prop({ type: String, required: true })
  private title!: string;

  @Prop({ type: Number, required: true })
  private initVolume!: number;

  @Prop({ type: String, required: true })
  private url!: string;

  @Prop({ type: String, default: "" })
  private creditUrl!: string;

  @Prop({ type: Number, required: true })
  private startSecond!: number;

  @Prop({ type: Number, required: true })
  private endSecond!: number;

  @Prop({ type: Number, required: true })
  private fadeIn!: number;

  @Prop({ type: Number, required: true })
  private fadeOut!: number;

  private jukeboxAudio: any = null;
  isYoutube: boolean = false;
  isPlay: boolean = true;
  playLength: number = 0;
  duration: number = 0;
  thumbnailData: string = "";

  private fadeInTable: number[] = [];
  private fadeOutTable: number[] = [];

  public click() {
    const elm: HTMLElement = this.$refs.elm as HTMLElement;
    elm.click();
  }

  private deleteItem() {
    this.setProperty({
      property: "private.display.jukeboxWindow.command",
      logOff: true,
      isNotice: false,
      value: { command: "remove", payload: this.bgmKey }
    });
  }

  mounted(this: any): void {
    this.isYoutube = /www\.youtube\.com/.test(this.url);
    this.thumbnailData = `http://i.ytimg.com/vi/${getUrlParam(
      "v",
      this.url
    )}/default.jpg`;

    const volumeComponent: VolumeComponent = this.$refs
      .volumeComponent as VolumeComponent;
    volumeComponent.setVolume(this.initVolume);
    volumeComponent.setMute(false);

    this.$emit("mounted");
  }
  destroyed(): void {
    this.changePlay(false);
    this.$emit("destroyed");
  }

  thumbnailClick(this: any): void {
    if (/www\.youtube\.com/.test(this.url))
      window.open(this.creditUrl || this.url, "_blank");
  }
  audioMute(this: any): void {
    const volumeComponent: VolumeComponent = this.$refs
      .volumeComponent as VolumeComponent;
    this.$emit(
      "mute",
      this.masterMute || (volumeComponent ? volumeComponent.mute : false)
    );
  }
  audioVolume(this: any): void {
    const volumeComponent: VolumeComponent = this.$refs
      .volumeComponent as VolumeComponent;
    this.$emit(
      "volume",
      this.masterVolume * (volumeComponent ? volumeComponent.volume : 0)
    );
  }
  setMute(): void {
    this.audioMute();
  }
  setVolume(): void {
    this.audioVolume();
  }
  changePlay(isPlay = !this.isPlay): void {
    this.isPlay = isPlay;
    this.$emit(isPlay ? "play" : "pause");
  }
  seekTo(): void {
    this.$emit("seekTo", this.playLength);
    this.changePlay(true);
  }
  timeUpdate(this: any, time: number): void {
    this.playLength = time;
    if (this.playLength < this.bgmStart) {
      this.playLength = this.bgmStart;
      this.$emit("seekTo", this.bgmStart);
    }
    if (this.playLength >= this.bgmEnd) {
      if (this.isLoop) {
        this.playLength = this.bgmStart;
        this.$emit("seekTo", this.bgmStart);
      } else {
        this.$emit("pause");
        this.$emit("end");
      }
    }
    this.fadeProc();
  }
  pause(): void {
    this.isPlay = false;
  }
  play(): void {
    this.isPlay = true;
    this.fadeProc();
  }
  setDuration(duration: number): void {
    this.duration = duration;

    this.fadeInTable = [];
    for (let i = 0; i <= this.fadeIn; i++) {
      this.fadeInTable.push(this.bgmStart + i / 10);
    }

    this.fadeOutTable = [];
    for (let i = 0; i <= this.fadeOut; i++) {
      this.fadeOutTable.push(this.bgmEnd - (this.fadeOut - i) / 10);
    }

    // window.console.log(this.fadeInTable, this.fadeOutTable);
  }

  fadeProc() {
    const volumeComponent: VolumeComponent = this.$refs
      .volumeComponent as VolumeComponent;
    let isProcessed: boolean = false;
    if (this.fadeIn) {
      this.fadeInTable.forEach((time, index) => {
        if (isProcessed) return;
        if (this.playLength <= time) {
          if (index === 0) volumeComponent.startFade(true);
          volumeComponent.setFadeCount(index, this.fadeIn);
          isProcessed = true;
        }
      });
    }
    if (this.fadeOut) {
      this.fadeOutTable
        .concat()
        .reverse()
        .forEach((time, index) => {
          if (isProcessed) return;
          if (this.playLength > time) {
            if (index === this.fadeOut) volumeComponent.startFade(false);
            volumeComponent.setFadeCount(
              this.fadeOut - index - 1,
              this.fadeOut
            );
            isProcessed = true;
          }
        });
    }
    if (!isProcessed) {
      if (this.fadeIn || this.fadeOut) {
        volumeComponent.endFade();
      }
    }
  }

  get playLengthStyle(): any {
    const useColor = this.isPlay ? "black" : "#8A084B";
    const per = (this.playLength * 100) / this.duration;
    return {
      background: `linear-gradient(to right, ${useColor} 0%, ${useColor} ${per}%, rgba(100, 100, 100, 1) ${per}%, rgba(100, 100, 100, 1) 100%)`
    };
  }
  get bgmStart(this: any): number {
    let start = 0;
    if (this.startSecond > 0) {
      start = this.startSecond;
    } else if (this.startSecond < 0) {
      start = this.duration + this.startSecond;
    }
    if (start > this.duration) start = this.duration;
    if (start < 0) start = 0;
    return start;
  }
  get bgmEnd(this: any): number {
    let end = this.duration;
    if (this.endSecond > 0) {
      end = this.endSecond;
    } else if (this.endSecond < 0) {
      end = this.duration + this.endSecond;
    }
    if (end > this.duration) end = this.duration;
    if (end < 0) end = 0;
    return end;
  }
  get thumbnailTitle(this: any): string {
    let title = `【標題】\n${this.title}`;
    if (this.isYoutube) {
      title += `\n\n【URL】\n${this.url}`;
    }
    return title;
  }

  @Watch("masterMute", { immediate: true })
  onChangeMasterMute() {
    setTimeout(() => this.audioMute(), 0);
  }

  @Watch("masterVolume", { immediate: true })
  onChangeMasterVolue() {
    setTimeout(() => this.audioVolume(), 0);
  }
}
