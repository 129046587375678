




































































import WindowFrame from "../../WindowFrame.vue";
import WindowMixin from "../../WindowMixin.vue";
import PlayerTypeSelect from "@/components/parts/select/PlayerTypeSelect.vue";
import CtrlButton from "@/components/parts/CtrlButton.vue";

import { Action, Getter, Mutation } from "vuex-class";
import { Watch } from "vue-property-decorator";
import { Component, Mixins } from "vue-mixin-decorator";

@Component({
  components: {
    CtrlButton,
    WindowFrame,
    PlayerTypeSelect: PlayerTypeSelect
  }
})
export default class InputPlayerInfoWindow extends Mixins<WindowMixin>(
  WindowMixin
) {
  @Action("setProperty") private setProperty: any;
  @Action("windowClose") private windowClose: any;
  @Action("loading") private loading: any;
  @Mutation("updateIsModal") private updateIsModal: any;
  @Getter("getObj") private getObj: any;
  @Getter("playerList") private playerList: any;
  @Getter("roles") private roles: any;
  @Getter("volatileRoomName") private volatileRoomName: any;
  @Getter("volatilePlayerName") private volatilePlayerName: any;
  @Getter("volatilePlayerPassword") private volatilePlayerPassword: any;
  @Getter("volatilePlayerType") private volatilePlayerType: any;
  @Getter("volatileFontColor") private volatileFontColor: any;
  @Getter("volatileResolve") private volatileResolve: any;

  private inputPlayerName: string = "";
  private inputPlayerPassword: string = "";
  private inputPlayerType: string = "";
  private inputPlayerTypeSave: string = "";
  private useRoomName: string = "";

  private isPlayerExist: boolean = false;

  initWindow(this: any): void {
    this.useRoomName = this.volatileRoomName;
    this.inputPlayerName = this.volatilePlayerName;
    this.inputPlayerPassword = this.volatilePlayerPassword;
    this.inputPlayerType = this.volatilePlayerType;
    this.loading(false);
    this.updateIsModal(true);
    this.$refs.playerInput["focus"]();
  }

  @Watch("inputPlayerType")
  onChangeInputPlayerType(value: string) {
    if (!this.isPlayerExist) this.inputPlayerTypeSave = value;
  }

  @Watch("inputPlayerName")
  onChangeInputPlayerName(value: string) {
    const player: any = this.playerList.filter(
      (player: any) => player.name === value
    )[0];
    this.isPlayerExist = !!player;
    this.inputPlayerType = player ? player.type : this.inputPlayerTypeSave;
  }

  commit() {
    // 輸入檢查
    const errorMsg = [];
    this.inputPlayerName = this.inputPlayerName.trim();
    if (!this.inputPlayerName) errorMsg.push("・玩家名");
    if (errorMsg.length > 0) {
      alert(`未輸入必須填寫的資料\n${errorMsg.join("\n")}\n請輸入。`);
      return;
    }
    const player = this.playerList.filter(
      (p: any) => p.name === this.inputPlayerName
    )[0];
    if (player && player.password !== this.inputPlayerPassword) {
      alert("密碼錯誤。\n重新輸入你的密碼或以其他身份加入。");
      return;
    }
    this.windowClose("private.display.inputPlayerInfoWindow");

    this.loading(true);
    this.volatileResolve({
      playerName: this.inputPlayerName,
      playerPassword: this.inputPlayerPassword,
      playerType: this.inputPlayerType,
      fontColor: this.volatileFontColor
    });
  }

  /**
   * ====================================================================================================
   * 権限に関する説明リンク點擊した際の処理
   */
  onClickDescription(): void {
    alert(
      this.roles.filter(
        (role: any) => role.value === this.volatilePlayerType
      )[0].description
    );
  }
}
