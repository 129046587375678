





















































































import WindowFrame from "../WindowFrame.vue";
import WindowMixin from "../WindowMixin.vue";
import Divider from "../parts/Divider.vue";
import CtrlButton from "@/components/parts/CtrlButton.vue";

import { Action, Getter } from "vuex-class";
import { Component, Mixins } from "vue-mixin-decorator";
import { saveJson } from "@/components/common/Utility";
import SettingBgmTrComponent from "@/components/music/component/SettingBgmTrComponent.vue";

@Component({
  components: { SettingBgmTrComponent, CtrlButton, WindowFrame, Divider }
})
export default class SettingBGMWindow extends Mixins<WindowMixin>(WindowMixin) {
  @Action("setProperty") setProperty: any;
  @Action("windowOpen") windowOpen: any;
  @Action("addListObj") addListObj: any;
  @Action("deleteListObj") deleteListObj: any;
  @Action("moveListObj") moveListObj: any;
  @Getter("bgmList") bgmList: any;
  @Getter("isGameMaster") private isGameMaster: any;
  @Getter("magicWord") private magicWord: any;
  @Getter("encrypt") encrypt: any;

  private isYoutube(url: string) {
    return /www\.youtube\.com/.test(url);
  }

  private isDropBox(url: string) {
    return /dropbox/.test(url);
  }

  private open(): void {
    this.setProperty({
      property: "private.display.settingBGMWindow.selectLineKey",
      value: null,
      logOff: true
    });

    const button: CtrlButton = this.$refs.button as CtrlButton;
    button.requestFocus();
  }

  private doPlay(): void {
    this.playBGM();
  }

  private doPreview(): void {
    this.playBGM(true);
  }

  private doAdd(): void {
    this.windowOpen("private.display.addBGMWindow");
  }

  private doModify(): void {
    if (!this.selectLineKey) {
      alert("請選擇BGM");
      return;
    }
    this.setProperty({
      property: "private.display.editBGMWindow.key",
      value: this.selectLineKey,
      logOff: true
    });
    this.windowOpen("private.display.editBGMWindow");
  }

  private doCopy(): void {
    if (!this.selectLineKey) {
      alert("請選擇BGM");
      return;
    }
    const bgmObj = JSON.parse(
      JSON.stringify(
        this.bgmList.filter(
          (bgmObj: any) => bgmObj.key === this.selectLineKey
        )[0]
      )
    );
    bgmObj.propName = "bgm";
    bgmObj.kind = "bgm";
    this.addListObj(bgmObj);
  }

  private doDelete(): void {
    if (!this.selectLineKey) {
      alert("請選擇BGM");
      return;
    }
    const index = this.bgmList.findIndex(
      (bgm: any) => bgm.key === this.selectLineKey
    );
    const nextIndex: number =
      index === this.bgmList.length - 1 ? index - 1 : index;
    this.deleteListObj({
      key: this.selectLineKey,
      propName: "bgm"
    }).then(() => {
      if (nextIndex === -1) return;
      this.selectLine(this.bgmList[nextIndex].key);
    });
  }

  private doUp(): void {
    if (!this.selectLineKey) {
      alert("請選擇BGM");
      return;
    }
    const index = this.bgmList.findIndex(
      (bgm: any) => bgm.key === this.selectLineKey
    );
    this.moveListObj({
      key: this.selectLineKey,
      afterIndex: index - 1
    });
  }

  private doDown(): void {
    if (!this.selectLineKey) {
      alert("請選擇BGM");
      return;
    }
    const index = this.bgmList.findIndex(
      (bgm: any) => bgm.key === this.selectLineKey
    );
    this.moveListObj({
      key: this.selectLineKey,
      afterIndex: index + 1
    });
  }

  private doSave(): void {
    const data: any = JSON.parse(
      JSON.stringify({
        saveDataTypeName: "Quoridorn_BGM_01",
        saveData: this.bgmList
      })
    );

    data.saveData.forEach((bgmObj: any) => {
      if (!this.isYoutube(bgmObj.url))
        bgmObj.url = this.encrypt({ planeText: bgmObj.url });
    });
    // window.console.log(JSON.stringify(data, null, "    "));
    saveJson("bgm", data);
  }

  private doImport(): void {
    this.windowOpen("private.display.importBGMWindow");
  }

  // changeSortMode(event) {
  //   const val = event.target.checked;
  //   window.console.log(`changeSortMode: ${val}`);
  //   if (val) {
  //     setTimeout(() => {
  //       alert("未實裝此功能。");
  //     }, 20);
  //   }
  // }

  private selectLine(bgmKey: string): void {
    this.setProperty({
      property: "private.display.settingBGMWindow.selectLineKey",
      value: bgmKey,
      logOff: true
    });
  }

  private playBGM(isPreview: boolean = false): void {
    const addBgmObj: any = this.bgmList.find(
      (bgmObj: any) => bgmObj.key === this.selectLineKey
    );
    if (!addBgmObj) {
      alert("請選擇BGM");
      return;
    }
    this.setProperty({
      property: "private.display.jukeboxWindow.command",
      logOff: true,
      isNotice: !isPreview,
      value: { command: "add", payload: addBgmObj }
    });
  }

  private moveDev(event: any): void {
    if (this.movingIndex > -1) {
      const diff = event.clientX - this.startX;
      const afterLeftWidth = this.startLeftWidth + diff;
      const afterRightWidth = this.startRightWidth - diff;
      if (afterLeftWidth >= 10 && afterRightWidth >= 10) {
        const paramObj: any = {};
        paramObj[this.movingIndex] = afterLeftWidth;
        paramObj[this.movingIndex + 1] = afterRightWidth;
        this.setProperty({
          property: "private.display.settingBGMWindow.widthList",
          value: paramObj,
          logOff: true
        });
      }
    }
  }

  private moveDevEnd(): void {
    this.setProperty({
      property: "private.display.settingBGMWindow",
      value: {
        hoverDevIndex: -1,
        movingIndex: -1,
        startX: -1,
        startLeftWidth: -1,
        startRightWidth: -1
      },
      logOff: true
    });
  }

  private get convertSecond(): Function {
    return (start: number, end: number): string => {
      if (start && end) return `${start}〜${end}`;
      if (start) return `${start}〜`;
      if (end) return `〜${end}`;
      return "All";
    };
  }

  /* Start 列幅可変テーブル的プロパティ */
  private get selectLineKey(): string {
    return this.$store.state.private.display.settingBGMWindow.selectLineKey;
  }

  private get widthList(): number[] {
    return this.$store.state.private.display.settingBGMWindow.widthList;
  }

  private get movingIndex(): number {
    return this.$store.state.private.display.settingBGMWindow.movingIndex;
  }

  private get startX(): number {
    return this.$store.state.private.display.settingBGMWindow.startX;
  }

  private get startLeftWidth(): number {
    return this.$store.state.private.display.settingBGMWindow.startLeftWidth;
  }

  private get startRightWidth(): number {
    return this.$store.state.private.display.settingBGMWindow.startRightWidth;
  }

  private get colStyle(): any {
    return (index: number) => ({ width: `${this.widthList[index]}px` });
  }
  /* End 列幅可変テーブル的プロパティ */
}
