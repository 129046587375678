
































































































































































































import SubBlockTitle from "./SubBlockTitle.vue";
import CtrlButton from "@/components/parts/CtrlButton.vue";
import DiceBotSelect from "@/components/parts/select/DiceBotSelect.vue";
import PlayerTypeSelect from "@/components/parts/select/PlayerTypeSelect.vue";

import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";

@Component({
  components: {
    CtrlButton,
    SubBlockTitle,
    DiceBotSelect,
    PlayerTypeSelect
  }
})
export default class CreateNewRoom extends Vue {
  @Action("setProperty") private setProperty: any;
  @Action("checkRoomName") private checkRoomName: any;
  @Action("windowClose") private windowClose: any;
  @Action("windowOpen") private windowOpen: any;
  @Action("loading") private loading: any;
  @Action("simpleJoinRoom") private simpleJoinRoom: any;
  @Action("doNewRoom") private doNewRoom: any;
  @Action("doJoinRoom") private doJoinRoom: any;
  @Mutation("updateIsWait") private updateIsWait: any;
  @Mutation("updateIsModal") private updateIsModal: any;
  @Mutation("updateIsJoined") private updateIsJoined: any;
  @Getter("paramRoomName") private paramRoomName: any;
  @Getter("paramRoomPassword") private paramRoomPassword: any;
  @Getter("paramPlayerName") private paramPlayerName: any;
  @Getter("paramPlayerPassword") private paramPlayerPassword: any;
  @Getter("paramPlayerType") private paramPlayerType: any;
  @Getter("isRoomExist") private isRoomExist: any;
  @Getter("peerId") private peerId: any;
  @Getter("roles") private roles: any;

  /*
   * data
   */
  static ENTRANCE_ROOM_NAME = "的等待房間";
  private roomName: string = "";
  private roomPassword: string = "";
  private playerName: string = "";
  private playerPassword: string = "";
  private playerType: string = "";
  private inputPlayerType: string = "";
  private currentSystem: string = "DiceBot";
  private isViewWait: boolean = false;
  private isViewNewRoom: boolean = false;

  /** ====================================================================================================
   * ライフサイクルメソッド
   */
  created() {
    this.roomName = this.paramRoomName;
    this.roomPassword = this.paramRoomPassword;
    this.playerName = this.paramPlayerName;
    this.playerPassword = this.paramPlayerPassword;
    this.inputPlayerType = this.paramPlayerType || "PL";
    setTimeout(() => {
      this.playerType = this.paramPlayerType;
    });
  }

  /** ====================================================================================================
   * ライフサイクルメソッド
   */
  mounted(this: any) {
    // TODO 本質的にはここじゃダメ
    this.$refs.roomNameInput["focus"]();
  }

  @Watch("inputPlayerType")
  onChangeInputPlayerType(inputPlayerType: string) {
    this.playerType = inputPlayerType;
  }

  openWaitRoom() {
    this.isViewWait = !this.isViewWait;
    this.isViewNewRoom = false;
  }

  openNewRoom() {
    this.isViewNewRoom = !this.isViewNewRoom;
    this.isViewWait = false;
  }

  /**
   * ====================================================================================================
   * 房間名を輸入して檢查鍵を押下した際的処理
   */
  commitRoomName() {
    /* ------------------------------
     * 輸入檢查
     */
    const errorMsg = [];
    if (!this.roomName) errorMsg.push("・房間名");
    if (errorMsg.length > 0) {
      alert(`必要的資料未輸入\n${errorMsg.join("\n")}\n請輸入。`);
      return;
    }

    /* ------------------------------
     * URLを書き換える（リ讀取無）
     */
    const paramList: string[] = [];
    paramList.push(`roomName=${this.roomName}`);
    if (this.roomPassword !== null)
      paramList.push(`roomPassword=${this.roomPassword}`);
    if (this.currentSystem !== null)
      paramList.push(`system=${this.currentSystem}`);
    if (this.playerName !== null)
      paramList.push(`playerName=${this.playerName}`);
    if (this.playerPassword !== null)
      paramList.push(`playerPassword=${this.playerPassword}`);
    if (this.playerType !== null)
      paramList.push(`playerType=${this.playerType}`);
    const newUrl = `?${paramList.join("&")}`;
    window.history.replaceState("", "", newUrl);

    // パラメータ更新
    this.setProperty({
      property: `param.roomName`,
      value: this.roomName,
      logOff: true
    });

    /* ------------------------------
     * 房間存在檢查
     */
    this.loading(true);
    Promise.resolve()
      .then(() => this.simpleJoinRoom({ roomName: this.roomName }))
      .then((peerId: string) => {
        // const logTexts = [];
        // logTexts.push(`create room by peer:"${peerId}"`);
        // logTexts.push(`本番: ${this.peerId(false)}`);
        // logTexts.push(`待ち: ${this.peerId(true)}`);
        // window.console.log(logTexts.join(", "));
        return this.checkRoomName({ roomName: this.roomName });
      })
      .then(isExist => {
        if (!this.playerName) return null;
        const baseArg: any = {
          roomName: this.roomName,
          roomPassword: this.roomPassword,
          playerName: this.playerName,
          playerPassword: this.playerPassword,
          playerType: this.playerType,
          fontColor: "#000000",
          system: this.currentSystem
        };
        if (!isExist && this.roomPassword !== null) {
          baseArg.system = undefined;
          return this.doNewRoom(baseArg);
        }
        if (isExist) {
          baseArg.useWindow = true;
          baseArg.useAlert = true;
          return this.doJoinRoom(baseArg);
        }
        return null;
      })
      .then(() => this.loading(false))
      .catch(() => this.loading(false));
    // end of 房間存在檢查
  }

  /**
   * ====================================================================================================
   * 房間建て・進房振り分け
   */
  roomProcess(isNewRoom: boolean) {
    // 輸入檢查
    const errorMsg = [];
    if (!this.roomName) errorMsg.push("・房間名");
    if (isNewRoom && !this.playerName) errorMsg.push("・用戶名");
    if (errorMsg.length > 0) {
      alert(`必要的資料未輸入\n${errorMsg.join("\n")}\n請輸入。`);
      return;
    }

    // 存在檢查してから決める
    this.loading(true);
    Promise.resolve()
      .then(() => this.checkRoomName({ roomName: this.roomName }))
      .then((isExist: boolean) => {
        const baseArg: any = {
          roomName: this.roomName,
          roomPassword: this.roomPassword || "",
          playerName: this.playerName,
          playerPassword: this.playerPassword,
          playerType: this.playerType,
          fontColor: "#000000",
          system: this.currentSystem
        };
        if (!isExist && isNewRoom) {
          baseArg.system = this.currentSystem;
          baseArg.playerPassword = baseArg.playerPassword || "";
          baseArg.playerType = baseArg.playerType || "PL";
          return this.doNewRoom(baseArg);
        }
        if (isExist && !isNewRoom) {
          baseArg.useWindow = true;
          baseArg.useAlert = true;
          return this.doJoinRoom(baseArg);
        }
        return null;
      })
      .then(() => this.loading(false))
      .catch(() => this.loading(false));
  }

  /**
   * ====================================================================================================
   * 房間ができる的を待つ
   */
  doWaitRoom() {
    // 輸入檢查
    const errorMsg = [];
    if (!this.roomName) errorMsg.push("・房間名");
    if (errorMsg.length > 0) {
      alert(`必要的資料未輸入\n${errorMsg.join("\n")}\n請輸入。`);
      return;
    }

    // まず目的的房間的存在檢查是
    Promise.resolve()
      .then(() => this.checkRoomName({ roomName: this.roomName }))
      .then((isExist: boolean) => {
        window.console.log(`房間${this.roomName}的存在檢查完成`);
        const joinArg = {
          roomName: this.roomName,
          roomPassword: this.roomPassword,
          playerName: this.playerName,
          playerPassword: this.playerPassword || "",
          playerType: this.playerType,
          fontColor: "#000000",
          useWindow: true,
          useAlert: true
        };
        if (isExist) {
          // 存在したら普通に進房是
          alert(`房間${this.roomName}剛剛已經新增了\n請進入。`);
          this.loading(true);
          return this.doJoinRoom(joinArg);
        } else {
          // 存在しなかったら待合室に入りつつ、目的的房間ができる的を檢查しながら待つ

          // 再帰呼び出しで檢查し続け、房間ができたら進房是
          const checkFunc = () => {
            Promise.resolve()
              .then(() => this.checkRoomName({ roomName: this.roomName }))
              .then((isExist: boolean) => {
                if (isExist) {
                  alert(`房間${this.roomName}已新增\n請進入。`);

                  const endFunc = () => {
                    this.loading(false);
                    this.updateIsWait(false);
                  };

                  this.loading(true);
                  this.updateIsJoined(false);
                  this.doJoinRoom(joinArg)
                    .then(endFunc)
                    .catch(endFunc);
                  return;
                }
                setTimeout(() => {
                  checkFunc();
                }, 5000);
              });
          };
          checkFunc();

          // モーダル狀態的解除
          this.updateIsModal(false);

          // エントランス房間に連接是
          this.loading(true);
          const entranceRoomName =
            this.roomName + CreateNewRoom.ENTRANCE_ROOM_NAME;
          Promise.resolve()
            .then(() =>
              this.simpleJoinRoom({ roomName: entranceRoomName, isWait: true })
            )
            .then((peerId: string) => {
              // const logTexts = [];
              // logTexts.push(`create room by peer:"${peerId}"`);
              // logTexts.push(`本番: ${this.peerId(false)}`);
              // logTexts.push(`待ち: ${this.peerId(true)}`);
              // window.console.log(logTexts.join(", "));
              return this.checkRoomName({
                roomName: entranceRoomName,
                isWait: true
              });
            })
            .then((isExist: boolean) => {
              const baseArg: any = {
                roomName: entranceRoomName,
                roomPassword: "",
                playerName: this.playerName,
                playerPassword: this.playerPassword,
                playerType: this.playerType,
                fontColor: "#000000",
                isWait: true,
                system: this.currentSystem
              };
              const loadingEnd = this.loading.bind(this, false);
              this.updateIsWait(true);
              if (!isExist) {
                baseArg.system = this.currentSystem;
                return this.doNewRoom(baseArg);
              } else {
                baseArg.useWindow = true;
                baseArg.useAlert = true;
                return this.doJoinRoom(baseArg);
              }
            })
            .then(() => this.loading(false))
            .catch(() => this.loading(false));
        }
      });
  }

  /**
   * ====================================================================================================
   * 權限に関是説明リンク點擊した際的処理
   */
  onClickDescription(): void {
    alert(
      this.roles.filter((role: any) => role.value === this.playerType)[0]
        .description
    );
  }
}
