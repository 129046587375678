import { render, staticRenderFns } from "./CreateRoomFromRoomData.vue?vue&type=template&id=5d1e9d72&scoped=true&"
import script from "./CreateRoomFromRoomData.vue?vue&type=script&lang=ts&"
export * from "./CreateRoomFromRoomData.vue?vue&type=script&lang=ts&"
import style0 from "./login.css?vue&type=style&index=0&id=5d1e9d72&scoped=true&lang=css&"
import style1 from "./CreateRoomFromRoomData.vue?vue&type=style&index=1&id=5d1e9d72&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d1e9d72",
  null
  
)

export default component.exports