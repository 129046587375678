



























import CtrlButton from "@/components/parts/CtrlButton.vue";
import { Component, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";

@Component({ components: { CtrlButton } })
export default class CreateRoomFromRoomData extends Vue {
  @Action("importStart") private importStart: any;

  private files: File[] = [];

  chooseFile(this: any): void {
    const fileChooser: HTMLElement = this.$refs.fileChooser;
    fileChooser.click();
  }

  selectFile(event: any) {
    if (event.target.files.length === 0) return;
    this.files = [];
    Array.prototype.push.apply(this.files, event.target.files);
  }

  /**
   * 確定鍵押下時
   */
  commit(): void {
    if (!this.files.length) {
      alert("請選擇一個文件。");
      return;
    }
    this.importStart({ zipFiles: this.files, isRoomCreate: true });
  }
}
