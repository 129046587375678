




















































import WindowFrame from "../../WindowFrame.vue";
import WindowMixin from "../../WindowMixin.vue";
import CtrlButton from "../../parts/CtrlButton.vue";
import ImageSelector from "@/components/parts/ImageSelector.vue";

import { Action, Getter } from "vuex-class";
import { Component, Mixins } from "vue-mixin-decorator";
import { Watch } from "vue-property-decorator";

@Component({
  components: {
    CtrlButton,
    WindowFrame,
    ImageSelector
  }
})
export default class EditFloorTileWindow extends Mixins<WindowMixin>(
  WindowMixin
) {
  @Action("windowOpen") private windowOpen: any;
  @Action("windowClose") private windowClose: any;
  @Action("changeListObj") private changeListObj: any;
  @Getter("getObj") private getObj: any;
  @Getter("imageList") private imageList: any;
  @Getter("imageListFromTagKey") private imageListFromTagKey: any;

  private currentImageTag: string = "imgTag-0";
  private selectImage: string = "image-12";
  private isReverse: boolean = false;
  private rows: number = 1;
  private columns: number = 1;
  private description: string = "";

  private commit(): void {
    this.changeListObj({
      key: this.key,
      currentImageTag: this.currentImageTag,
      imageKey: this.selectImage,
      isReverse: this.isReverse,
      rows: this.rows,
      columns: this.columns,
      description: this.description,
      isNotice: true
    });
    this.windowClose("private.display.editFloorTileWindow");
  }

  private cancel(): void {
    this.windowClose("private.display.editFloorTileWindow");
  }

  @Watch("selectImage")
  private onChangeSelectImage(selectImage: string) {
    this.isReverse = /:R/.test(this.selectImage);
  }

  private getImage(key: string): string {
    return this.getKeyObj(this.imageList, key).data;
  }

  private getKeyObj(list: any[], key: string): any {
    return list.filter(obj => obj.key === key)[0];
  }

  private initWindow(): void {
    let floorTileObj = this.getObj(this.key);
    window.console.log(this.key, floorTileObj);
    this.currentImageTag = floorTileObj.currentImageTag;
    this.selectImage = floorTileObj.imageKey;
    this.isReverse = floorTileObj.isReverse;
    this.rows = floorTileObj.rows;
    this.columns = floorTileObj.columns;
    this.description = floorTileObj.description;
  }

  private get key(): string {
    return this.$store.state.private.display["editFloorTileWindow"].key;
  }

  private get currentImage(): string | null {
    const imageObj = this.getKeyObj(
      this.imageList,
      this.selectImage.replace(":R", "")
    );
    return imageObj ? imageObj.data : null;
  }
}
