import { render, staticRenderFns } from "./CreateNewRoom.vue?vue&type=template&id=05af71ce&scoped=true&"
import script from "./CreateNewRoom.vue?vue&type=script&lang=ts&"
export * from "./CreateNewRoom.vue?vue&type=script&lang=ts&"
import style0 from "./login.css?vue&type=style&index=0&id=05af71ce&scoped=true&lang=css&"
import style1 from "./CreateNewRoom.vue?vue&type=style&index=1&id=05af71ce&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05af71ce",
  null
  
)

export default component.exports