

































































































































import WindowFrame from "../WindowFrame.vue";
import WindowMixin from "../WindowMixin.vue";
import CharacterChip from "../map/character/CharacterChip.vue";
import PlayerSelect from "@/components/parts/select/PlayerSelect.vue";
import CtrlSelect from "@/components/parts/CtrlSelect.vue";

import { Action, Getter } from "vuex-class";
import { Watch } from "vue-property-decorator";
import { Component, Mixins } from "vue-mixin-decorator";

@Component({
  components: {
    CtrlSelect,
    PlayerSelect,
    WindowFrame,
    CharacterChip
  }
})
export default class PlayerBoxWindow extends Mixins<WindowMixin>(WindowMixin) {
  @Action("setProperty") private setProperty: any;
  @Action("changeChatFontColor") private changeChatFontColor: any;
  @Action("changeListObj") private changeListObj: any;
  @Getter("getObj") private getObj: any;
  @Getter("playerKey") private playerKey: any;
  @Getter("getMapObjectList") private getMapObjectList: any;

  private currentPlayerKey: string = "aaa";

  changeFontColorType(this: any, key: string, value: string): void {
    const characterList = this.getMapObjectList({ kind: "character" });
    const targetCharacter = characterList.filter(
      (character: any) => character.key === key
    )[0];
    if (!targetCharacter) return;
    const index = characterList.indexOf(targetCharacter);
    this.setProperty({
      property: `public.character.list.${index}.fontColorType`,
      value: value,
      isNotice: true,
      logOff: true
    });
  }
  changeCharacterFontColor(this: any, key: string, value: boolean): void {
    this.changeChatFontColor({ key: key, color: value });
  }
  changePlayerFontColor(this: any, value: string): void {
    const targetPlayer = this.getPlayer;
    this.changeChatFontColor({ key: targetPlayer.key, color: value });
  }
  getPlayerName(this: any, playerKey: string): string {
    const player = this.getObj(playerKey);
    if (!player) return "";
    return player.name;
  }

  @Watch("currentPlayerKey")
  onChangeCurrentPlayerKey(currentPlayerKey: string) {
    // window.console.log("%%%%%%%%%%%%%%", currentPlayerKey);
  }

  @Watch("playerKey")
  onChangePlayerKey(playerKey: string) {
    // window.console.error(playerKey);
    this.currentPlayerKey = playerKey;
  }

  get getPlayer(): any {
    return this.getObj(this.currentPlayerKey);
  }

  private get fontColorTypeOptionInfoList(): any[] {
    const resultList: any[] = [];
    resultList.push({
      key: 0,
      value: "0",
      text: "和主要的相同",
      disabled: false
    });
    resultList.push({
      key: 1,
      value: "1",
      text: "獨立設定",
      disabled: false
    });
    return resultList;
  }
}
