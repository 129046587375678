














































import Card from "./Card.vue";

import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";

@Component({
  components: {
    Card
  }
})
export default class Deck extends Vue {
  @Action("setProperty") private setProperty: any;
  @Getter("deck") private deck: any;
  @Getter("deckCardList") private deckCardList: any;
  @Getter("deckCommand") private deckCommand: any;
  @Getter("deckHoverIndex") private deckHoverIndex: any;
  @Getter("deckHoverKey") private deckHoverKey: any;
  @Getter("isModal") private isModal: any;

  createRefStr(
    { author, title }: { author: string; title: string },
    index: number
  ): string {
    if (!author && !title) return `link-${index}`;
    if (author && title) return `${title}(${author})`;
    if (author) return `${author}`;
    if (title) return `${title}`;
    return "";
  }

  get containerStyle(): any {
    const obj: any = {};
    obj.width = this.deck.width * 0.8 + 22 + "px";
    if (this.isModal) {
      obj.filter = "blur(3px)";
    }
    return obj;
  }

  get deckStyle(): any {
    const obj: any = {};
    obj.width = this.deck.width * 0.8 + 15 + "px";
    obj.height = this.deck.height * 0.8 + 15 + "px";
    return obj;
  }

  @Watch("deckCommand")
  onChangeDeckCommand(this: any, deckCommand: any): void {
    if (!deckCommand) return;
    if (deckCommand.type === "draw") {
      let cards = Array.prototype.slice.call(
        this.$refs.deck.getElementsByClassName(this.deckHoverKey)
      );
      let cardElm = cards[0];
      if (!cardElm) {
        const key = this.deckCardList[this.deckCardList.length - 1].key;
        cards = Array.prototype.slice.call(
          this.$refs.deck.getElementsByClassName(key)
        );
        cardElm = cards[0];
      }
      if (cardElm.classList.contains("turn-animation")) {
        cardElm.classList.remove("turn-animation");
      } else {
        cardElm.classList.add("turn-animation");
      }
      this.setProperty({
        property: "deck",
        value: {
          hoverIndex: -1,
          hoverKey: ""
        },
        logOff: true
      });
    } else if (deckCommand.type === "changeViewMode") {
      this.setProperty({
        property: "deck.viewMode",
        value: deckCommand.value,
        logOff: true
      });
    } else if (deckCommand.type === "shuffle") {
      const cards = Array.prototype.slice.call(
        this.$refs.deck.getElementsByClassName("card")
      );
      if (cards.length) {
        cards.forEach(cardElm => {
          if (cardElm.classList.contains("shuffle-animation")) {
            cardElm.classList.remove("shuffle-animation");
          } else {
            cardElm.classList.add("shuffle-animation");
          }
        });
      }
    }
    this.setProperty({
      property: "deck.command",
      value: null,
      logOff: true
    });
  }
}
