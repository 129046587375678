export default {
  state: {
    command: null,
    isDisplay: false,
    zIndex: 1,
    key: -1
  },
  actions: {},
  mutations: {},
  getters: {}
};
