




































































import WindowMixin from "../WindowMixin.vue";
import WindowFrame from "../WindowFrame.vue";
import CounterSelect from "@/components/parts/select/CounterSelect.vue";
import CharacterSelect from "@/components/parts/select/CharacterSelect.vue";
import CtrlButton from "@/components/parts/CtrlButton.vue";
import CtrlSelect from "@/components/parts/CtrlSelect.vue";

import { Action, Getter } from "vuex-class";
import { Component, Mixins } from "vue-mixin-decorator";
import { Watch } from "vue-property-decorator";

@Component({
  components: {
    CtrlSelect,
    CtrlButton,
    WindowFrame,
    CounterSelect,
    CharacterSelect
  }
})
export default class CounterRemoconEditorWindow extends Mixins<WindowMixin>(
  WindowMixin
) {
  @Action("changeListObj") private changeListObj: any;
  @Action("addCounterRemocon") private addCounterRemocon: any;
  @Action("windowClose") private windowClose: any;
  @Action("sendBcdiceServer") private sendBcdiceServer: any;
  @Getter("propertyList") private propertyList: any;
  @Getter("counterRemoconEditorKey") private counterRemoconEditorKey: any;
  @Getter("getObj") private getObj: any;
  @Getter("activeChatTab") private activeChatTab: any;
  @Getter("playerKey") private playerKey: any;
  @Action("sendChatLog") private sendChatLog: any;

  private buttonName: string = "";
  private target: string = "";
  private counterName: string = "";
  private modifyType: string = "0";
  private modifyValue: string = "";
  private sampleValue: number = 0;
  private sampleDiceValue: string = "";
  private message: string = "{0}的{1}{2}，結果{4}";
  private exampleText: string = "";

  /*********************************************************************************************************************
   * 子畫面顯示時
   */
  private initWindow() {
    const counterRemocon = this.getObj(this.counterRemoconEditorKey);
    if (counterRemocon) {
      this.buttonName = counterRemocon.buttonName;
      this.target = counterRemocon.target;
      this.counterName = counterRemocon.counterName;
      this.modifyType = counterRemocon.modifyType;
      this.modifyValue = counterRemocon.modifyValue;
      this.message = counterRemocon.message;
      this.exampleText = counterRemocon.exampleText;
    } else {
      const firstProperty = this.propertyList[0];
      this.buttonName = "";
      this.target = "";
      this.counterName = firstProperty ? firstProperty.property : "先攻表";
      this.modifyType = this.COUNTER_REMOCON_TYPE.PLUS;
      this.modifyValue = "";
      this.message = "{0}的{1}{2}，結果{4}";
      this.exampleText = `的${this.counterName}`;
    }
  }

  /*********************************************************************************************************************
   * コミット快捷鍵押下時
   */
  private commitButtonOnClick() {
    // 輸入檢查
    const messageList: string[] = [];
    if (!this.buttonName) messageList.push("必須有快捷鍵名稱。");
    if (messageList.length) {
      this.sendChatLog({
        actorKey: "HKTRPG",
        text: messageList.join("\n"),
        chatTarget: this.playerKey,
        statusName: "◆",
        outputTab: this.activeChatTab
      });
      //  alert(messageList.join("\n"));
      return;
    }

    // 情報更新
    const counterRemocon = this.getObj(this.counterRemoconEditorKey);
    if (counterRemocon) {
      this.changeListObj({
        key: this.counterRemoconEditorKey,
        isNotice: true,
        buttonName: this.buttonName,
        target: this.target,
        counterName: this.counterName,
        modifyType: this.modifyType,
        modifyValue: this.modifyValue,
        message: this.message,
        exampleText: this.exampleText
      });
    } else {
      this.addCounterRemocon({
        buttonName: this.buttonName,
        target: this.target,
        counterName: this.counterName,
        modifyType: this.modifyType,
        modifyValue: this.modifyValue,
        message: this.message,
        exampleText: this.exampleText
      });
    }
    this.windowClose("private.display.counterRemoconEditorWindow");
  }

  /*********************************************************************************************************************
   * 取消快捷鍵押下時
   */
  private cancelButtonOnClick() {
    this.windowClose("private.display.counterRemoconEditorWindow");
  }

  /*********************************************************************************************************************
   * 実行例的更新
   */
  @Watch("counterName")
  @Watch("modifyType")
  @Watch("sampleValue")
  @Watch("message")
  onChangeValues() {
    const useSampleValue: number =
      this.modifyType === this.COUNTER_REMOCON_TYPE.MINUS
        ? -this.sampleValue
        : this.sampleValue;

    const afterValue: number =
      this.modifyType === this.COUNTER_REMOCON_TYPE.EQUALS
        ? useSampleValue
        : 3 + useSampleValue;

    const character: any = this.getObj(this.target);
    const characterName: string = character ? character.name : "";

    this.exampleText = this.message
      .replace("{0}", characterName || "[選擇角色]")
      .replace("{1}", this.counterName || "[選擇項目]")
      .replace(
        "{2}",
        `${
          !(this.modifyType === this.COUNTER_REMOCON_TYPE.EQUALS) &&
          useSampleValue > 0
            ? "+"
            : ""
        }${useSampleValue}` +
          (this.sampleDiceValue ? `（${this.sampleDiceValue}）` : "")
      )
      .replace(
        "{3}",
        `${useSampleValue}` +
          (this.sampleDiceValue ? `（${this.sampleDiceValue}）` : "")
      )
      .replace(
        "{4}",
        `（${this.counterName || "[選擇項目]"}：${3}->${afterValue}）`
      );
  }

  /*********************************************************************************************************************
   * 變更値的評価
   */
  @Watch("modifyValue")
  onChangeModifyValue() {
    if (/^-?[0-9]+$/.test(this.modifyValue)) {
      this.sampleValue = parseInt(this.modifyValue, 10);
      this.sampleDiceValue = "";
    } else {
      this.sendBcdiceServer({
        system: "DiceBot",
        command: this.modifyValue
      }).then((json: any) => {
        if (json.ok) {
          // bcdiceとして結果が取れた場合
          const resultValue = json.result.replace(/^.+＞ /, "");
          if (/^-?[0-9]+$/.test(resultValue)) {
            // 数値として応答された
            const matchResult = json.result.match(/^.+＞ ([^＞]+) ＞ [^＞]+$/);
            this.sampleValue = parseInt(resultValue, 10);
            this.sampleDiceValue = `${this.modifyValue}=${matchResult[1]}`;
            return;
          }
        }
        this.sampleValue = 0;
        this.sampleDiceValue = "";
      });
    }
  }

  private get modifyTypeOptionInfoList(): any[] {
    const resultList: any[] = [];
    resultList.push({
      key: 0,
      value: this.COUNTER_REMOCON_TYPE.PLUS,
      text: "＋",
      disabled: false
    });
    resultList.push({
      key: 1,
      value: this.COUNTER_REMOCON_TYPE.MINUS,
      text: "ー",
      disabled: false
    });
    resultList.push({
      key: 2,
      value: this.COUNTER_REMOCON_TYPE.EQUALS,
      text: "＝",
      disabled: false
    });
    resultList.push({
      key: 3,
      value: this.COUNTER_REMOCON_TYPE.PLUS_MINUS,
      text: "±",
      disabled: false
    });
    return resultList;
  }
}
