var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('window-frame',{attrs:{"titleText":"棋子管理盒","display-property":"private.display.playerBoxWindow","align":"center","fixSize":"300, 400"}},[_c('div',{staticClass:"contents",on:{"contextmenu":function($event){$event.preventDefault();}}},[_c('label',{staticClass:"playerSelect"},[_c('player-select',{model:{value:(_vm.currentPlayerKey),callback:function ($$v) {_vm.currentPlayerKey=$$v},expression:"currentPlayerKey"}}),_vm._v("的棋子管理盒\n    ")],1),_c('label',{staticClass:"playerFontColor"},[_vm._v("\n      角色的文字顏色\n      "),_c('input',{attrs:{"type":"color"},domProps:{"value":_vm.getPlayer ? _vm.getPlayer.fontColor : ''},on:{"change":function($event){$event.stopPropagation();return (function (event) { return _vm.changePlayerFontColor(event.target.value); })($event)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.stopPropagation();},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.stopPropagation();}}})]),_c('fieldset',{staticClass:"field map"},[_c('legend',[_vm._v("在地圖上的")]),_c('ul',{staticClass:"objList"},_vm._l((_vm.getMapObjectList({
            kind: 'character',
            place: 'field',
            playerKey: _vm.currentPlayerKey
          })),function(character){return _c('li',{key:character.key},[_c('character-chip',{attrs:{"type":character.kind,"objKey":character.key}}),_c('fieldset',{staticClass:"fontColorArea"},[_c('legend',[_vm._v("角色的文字顏色")]),_c('label',[_c('ctrl-select',{attrs:{"value":character.fontColorType,"optionInfoList":_vm.fontColorTypeOptionInfoList},on:{"input":function (value) { return _vm.changeFontColorType(character.key, value); }}}),_c('input',{attrs:{"type":"color","disabled":character.fontColorType === '0'},domProps:{"value":character.fontColorType === '0'
                    ? _vm.getPlayer
                      ? _vm.getPlayer.fontColor
                      : ''
                    : character.fontColor},on:{"change":function($event){$event.stopPropagation();return (function (event) { return _vm.changeCharacterFontColor(
                      character.key,
                      event.target.value
                    ); })($event)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.stopPropagation();},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.stopPropagation();}}})],1)])],1)}),0)]),(_vm.currentPlayerKey === _vm.playerKey)?_c('fieldset',{staticClass:"field waiting"},[_c('legend',[_vm._v("在待機室的")]),_c('ul',{staticClass:"objList"},_vm._l((_vm.getMapObjectList({
            kind: 'character',
            place: 'waiting',
            playerKey: _vm.currentPlayerKey
          })),function(character){return _c('li',{key:character.key},[_c('character-chip',{attrs:{"type":character.kind,"objKey":character.key}})],1)}),0)]):_vm._e(),(_vm.currentPlayerKey === _vm.playerKey)?_c('fieldset',{staticClass:"field graveyard"},[_c('legend',[_vm._v("在墓場的")]),_c('ul',{staticClass:"objList"},_vm._l((_vm.getMapObjectList({
            kind: 'character',
            place: 'graveyard',
            playerKey: _vm.currentPlayerKey
          })),function(character){return _c('li',{key:character.key},[_c('character-chip',{attrs:{"type":character.kind,"objKey":character.key}})],1)}),0)]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }