







































































import WindowFrame from "../WindowFrame.vue";
import WindowMixin from "../WindowMixin.vue";
import draggable from "vuedraggable";
import CtrlButton from "@/components/parts/CtrlButton.vue";

import { Action, Getter } from "vuex-class";
import { Component, Mixins } from "vue-mixin-decorator";

@Component({
  components: {
    CtrlButton,
    WindowFrame,
    draggable
  }
})
export default class SettingChatTabWindow extends Mixins<WindowMixin>(
  WindowMixin
) {
  @Action("setProperty") private setProperty: any;
  @Action("windowClose") private windowClose: any;
  @Action("addChatTab") private addChatTab: any;
  @Action("updateChatTab") private updateChatTab: any;
  @Action("deleteChatTab") private deleteChatTab: any;
  @Getter("chatTabs") private chatTabs: any;
  @Getter("isChatTabVertical") private isChatTabVertical: any;
  @Getter("isViewTime") private isViewTime: any;
  @Getter("isViewTotalTab") private isViewTotalTab: any;

  private tabs: any[] = [];
  private addIndex: number = -1;
  private delTabs: string[] = [];
  private isTabVertical: boolean = false;
  private isLogViewTime: boolean = false;
  private isLogViewAllTab: boolean = false;

  private open() {
    this.tabs = this.chatTabs.filter((tab: any) => !tab.isTotal).concat();
    this.delTabs = [];
    this.isTabVertical = this.isChatTabVertical;
    this.isLogViewTime = this.isViewTime;
    this.isLogViewAllTab = this.isViewTotalTab;

    const button: CtrlButton = this.$refs.button as CtrlButton;
    button.requestFocus();
  }

  private addTab() {
    const key = `chatTabAdd-${++this.addIndex}`;
    this.tabs.push({
      key: key,
      name: ""
    });
  }

  private delTab(key: string, index: number) {
    this.tabs.splice(index, 1);
    if (!key.startsWith("chatTabAdd")) {
      this.delTabs.push(key);
    }
  }

  private commit() {
    this.delTabs.forEach((key: string) => this.deleteChatTab({ key }));
    this.tabs.forEach((tab: any, index: number) => {
      if (tab.key.startsWith("chatTabAdd")) {
        this.addChatTab({
          name: tab.name,
          order: index
        });
      } else {
        this.updateChatTab({
          key: tab.key,
          name: tab.name,
          order: index + 1
        });
      }
    });
    this.setProperty({
      property: "public.chat.tab",
      value: {
        isChatTabVertical: this.isTabVertical,
        isViewTime: this.isLogViewTime,
        isViewTotalTab: this.isLogViewAllTab
      },
      isNotice: true,
      logOff: true
    });
    this.delTabs = [];
    this.windowClose("private.display.settingChatTabWindow");
  }

  private cancel() {
    this.delTabs = [];
    this.windowClose("private.display.settingChatTabWindow");
  }
}
