













































































































import WindowFrame from "../WindowFrame.vue";
import WindowMixin from "../WindowMixin.vue";
import Divider from "../parts/Divider.vue";
import CtrlButton from "@/components/parts/CtrlButton.vue";

import { Action, Getter, Mutation } from "vuex-class";
import { Component, Mixins } from "vue-mixin-decorator";

@Component({
  components: {
    CtrlButton,
    WindowFrame,
    Divider
  }
})
export default class CustomDiceBotTableWindow extends Mixins<WindowMixin>(
  WindowMixin
) {
  @Action("windowClose") private windowClose: any;
  @Action("windowOpen") private windowOpen: any;
  @Action("setProperty") private setProperty: any;
  @Action("addListObj") private addListObj: any;
  @Action("deleteListObj") private deleteListObj: any;
  @Getter("loadYaml") private loadYaml: any;
  @Getter("getViewName") private getViewName: any;
  @Getter("getObj") private getObj: any;
  @Getter("playerKey") private playerKey: any;
  @Getter("chatActorKey") private chatActorKey: any;
  @Getter("customDiceBotList") private customDiceBotList: any;
  @Getter("customDiceBotRoomSysList") private customDiceBotRoomSysList: any;
  @Getter("diceSystemList") private diceSystemList: any;

  private open() {
    const button: CtrlButton = this.$refs.button as CtrlButton;
    button.requestFocus();
  }

  private addButtonOnClick(): void {
    this.loadYaml("/static/conf/system/default/customDiceBot.yaml")
      .then((customDiceBot: any) => {
        // 読み込めた場合
        const tableContents: any = customDiceBot!.tableContents;
        const tableContentsArr = [];
        for (const prop in tableContents) {
          if (!tableContents.hasOwnProperty(prop)) continue;
          tableContentsArr.push(`${prop}:${tableContents[prop]}`);
        }
        customDiceBot.tableContents = tableContentsArr.join("\n");

        this.addListObj({
          propName: "customDiceBot",
          kind: "customDiceBot",
          commandName: customDiceBot.commandName,
          diceRoll: customDiceBot.diceRoll,
          tableTitle: customDiceBot.tableTitle,
          diceBotSystem: "DiceBot",
          tableContents: tableContentsArr.join("\n")
        }).then(() => {
          const lastKey: string = this.customDiceBotList[
            this.customDiceBotList.length - 1
          ].key;
          this.setProperty({
            property: "private.display.editCustomDiceBotTableWindow.objKey",
            value: lastKey,
            logOff: true
          }).then(() => {
            this.windowOpen("private.display.editCustomDiceBotTableWindow");
          });
        });
      })
      .catch((err: any) => {
        window.console.log(err);

        this.addListObj({
          propName: "customDiceBot",
          kind: "customDiceBot",
          commandName: "HKTRPG",
          diceRoll: "1d6",
          tableTitle: "HKTRPG是什麼",
          diceBotSystem: "DiceBot",
          tableContents: [
            "1:HKTRPG.com 主站是一個wiki TRPG百科",
            "2:https://roll.hktrpg.com 是一個簡易擲骰工具",
            "3:HKTRPG @Facebook 是個資訊平台，可以發佈招團信息",
            "4:HKTRPG @Telegram Line Discord Bot 是個擲骰Bot, 可以用來跑團",
            "5:https://mindmap.hktrpg.com/ 是個跑團輔助工具",
            "6:https://www.hktrpg.com/board/ 是個留言版，可以說說意見。"
          ].join("\n")
        }).then(() => {
          const lastKey: string = this.customDiceBotList[
            this.customDiceBotList.length - 1
          ].key;
          this.setProperty({
            property: "private.display.editCustomDiceBotTableWindow.objKey",
            value: lastKey,
            logOff: true
          }).then(() => {
            this.windowOpen("private.display.editCustomDiceBotTableWindow");
          });
        });
      });
  }

  private copyButtonOnClick(): void {
    if (!this.selectLineKey) {
      alert("請選擇複製目標。");
      return;
    }

    const kind = this.selectLineKey.split("-")[0];

    const useList =
      kind === "customDiceBotRoomSys"
        ? this.customDiceBotRoomSysList
        : this.customDiceBotList;
    const customDiceBotObj: any = JSON.parse(
      JSON.stringify(
        useList.filter((bot: any) => bot.key === this.selectLineKey)[0]
      )
    );

    customDiceBotObj.propName = "customDiceBot";
    customDiceBotObj.kind = "customDiceBot";
    this.addListObj(customDiceBotObj);
  }

  private changeButtonOnClick(key: string): void {
    if (!this.selectLineKey) {
      alert("請選擇變更目標。");
      return;
    }

    this.setProperty({
      property: "private.display.editCustomDiceBotTableWindow.objKey",
      value: this.selectLineKey,
      logOff: true
    });
    this.windowOpen("private.display.editCustomDiceBotTableWindow");
  }

  private deleteButtonOnClick(): void {
    if (!this.selectLineKey) {
      alert("請選擇刪除目標。");
      return;
    }

    this.deleteListObj({ key: this.selectLineKey, propName: "customDiceBot" });
    this.selectLine("");
  }

  private cancelButtonOnClick(): void {
    this.windowClose("private.display.customDiceBotTableWindow");
  }

  private moveDev(event: any) {
    if (this.movingIndex > -1) {
      const diff = event.clientX - this.startX;
      const afterLeftWidth = this.startLeftWidth + diff;
      const afterRightWidth = this.startRightWidth - diff;
      if (afterLeftWidth >= 10 && afterRightWidth >= 10) {
        const paramObj: any = {};
        paramObj[this.movingIndex] = afterLeftWidth;
        paramObj[this.movingIndex + 1] = afterRightWidth;
        this.setProperty({
          property: "private.display.customDiceBotTableWindow.widthList",
          value: paramObj,
          logOff: true
        });
      }
    }
  }

  private moveDevEnd() {
    this.setProperty({
      property: "private.display.customDiceBotTableWindow",
      value: {
        hoverDevIndex: -1,
        movingIndex: -1,
        startX: -1,
        startLeftWidth: -1,
        startRightWidth: -1
      },
      logOff: true
    });
  }

  private selectLine(selectLineKey: string) {
    this.setProperty({
      property: "private.display.customDiceBotTableWindow.selectLineKey",
      value: selectLineKey,
      logOff: true
    });
  }

  private getSystemName(systemKey: string) {
    if (systemKey === "DiceBot") return "無指定";
    const systemObj: any = this.diceSystemList.filter(
      (systemObj: any) => systemObj.system === systemKey
    )[0];
    return systemObj.name;
  }

  private get listLengthIsOdd(): boolean {
    return (
      (this.customDiceBotList.length + this.customDiceBotRoomSysList.length) %
        2 !==
      0
    );
  }

  /* Start 列幅可変テーブル的プロパティ */
  private get selectLineKey() {
    return this.$store.state.private.display.customDiceBotTableWindow
      .selectLineKey;
  }

  private get widthList() {
    return this.$store.state.private.display.customDiceBotTableWindow.widthList;
  }

  private get movingIndex() {
    return this.$store.state.private.display.customDiceBotTableWindow
      .movingIndex;
  }

  private get startX() {
    return this.$store.state.private.display.customDiceBotTableWindow.startX;
  }

  private get startLeftWidth() {
    return this.$store.state.private.display.customDiceBotTableWindow
      .startLeftWidth;
  }

  private get startRightWidth() {
    return this.$store.state.private.display.customDiceBotTableWindow
      .startRightWidth;
  }

  private get colStyle() {
    return (index: number) => ({ width: `${this.widthList[index]}px` });
  }
  /* End 列幅可変テーブル的プロパティ */
}
