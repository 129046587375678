















































































































import StandImageComponent from "@/components/parts/StandImageComponent.vue";

import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import WindowFrameKnob from "@/components/WindowFrameKnob.vue";
import CtrlSelect from "@/components/parts/CtrlSelect.vue";

@Component({
  components: { CtrlSelect, WindowFrameKnob, StandImageComponent }
})
export default class WindowFrame extends Vue {
  @Action("windowClose") private windowClose: any;
  @Action("setProperty") private setProperty: any;
  @Action("windowActive") private windowActive: any;
  @Getter("getStateValue") private getStateValue: any;
  @Getter("isModal") private isModal: any;
  @Getter("isViewStandImage") private isViewStandImage: any;

  @Prop({ type: String, required: true })
  private titleText!: string;

  @Prop({ type: String, required: true })
  private displayProperty!: string;

  @Prop({ type: String, required: true })
  private align!: string;

  @Prop({ type: String })
  private baseSize!: string | null;

  @Prop({ type: String })
  private fixSize!: string | null;

  @Prop({ type: Boolean, default: false })
  private isBanClose!: boolean;

  @Prop({ type: Boolean, default: false })
  private fontSizeBar!: boolean;

  @Prop({ type: Boolean, default: false })
  private standImageSizeChooser!: boolean;

  @Prop({ type: String })
  private message!: string | null;

  private moveMode: string = "";
  private mouse: any = {
    x: 0,
    y: 0,
    saveX: 0,
    saveY: 0
  };

  public windowFactor: any = {
    l: 0, // left
    r: 0, // right
    t: 0, // top
    b: 0, // bottom
    w: 0, // width
    h: 0, // height
    draggingX: 0,
    draggingY: 0
  };

  private fontSize: number = 12;
  private standImageSize: string = "192*256";
  private standImageWidth: number = 192;
  private standImageHeight: number = 256;

  @Watch("standImageSize", { immediate: true })
  private onChangeStandImageSize(standImageSize: string) {
    const split: string[] = standImageSize.split("*");
    this.standImageWidth = parseInt(split[0]);
    this.standImageHeight = parseInt(split[1]);
  }

  private mounted(): void {
    const _ = this;
    document.addEventListener("mousemove", event => {
      _.mouse.x = event.pageX;
      _.mouse.y = event.pageY;
      _.reflesh();
    });
    document.addEventListener("touchmove", event => {
      _.mouse.x = event.changedTouches[0].pageX;
      _.mouse.y = event.changedTouches[0].pageY;
      _.reflesh();
    });
    this.addEventForIFrame();
  }

  private closeWindow(this: any): void {
    // window.console.log(`  [methods] closeWindow(click [x]button)`)
    this.windowClose(this.displayProperty);
    this.$emit("cancel");
  }

  private mouseUp(event: any, num: number): void {
    const evtObj = {
      clientX: event.pageX,
      clientY: event.pageY,
      button: event.button
    };
    if (event.button === 2) {
      this.setProperty({
        property: `map.isOverEvent`,
        value: true,
        logOff: true
      });
    }
    const gameTableElm = document.getElementById("mapBoardFrame");
    const evt = document.createEvent("MouseEvents");
    evt.initMouseEvent(
      "mouseup",
      true,
      true,
      window,
      1,
      event.screenX,
      event.screenY,
      event.clientX,
      event.clientY,
      event.ctrlKey,
      event.altKey,
      event.shiftKey,
      event.metaKey,
      event.buttons,
      gameTableElm
    );
    // gameTableElm!.dispatchEvent(new MouseEvent("mouseUp", evtObj));
    gameTableElm!.dispatchEvent(evt);
  }

  private resize(
    this: any,
    event: any,
    direct: string,
    flg: boolean,
    isTouch: boolean
  ): void {
    if (flg) {
      this.mouse.saveX = isTouch
        ? event.changedTouches[0]["pageX"]
        : event.pageX;
      this.mouse.saveY = isTouch
        ? event.changedTouches[0]["pageY"]
        : event.pageY;
    } else {
      const moveMode = this.moveMode;
      const winFac = this.windowFactor;
      // window.console.log(this.moveMode, winFac.x, winFac.y, winFac.w, winFac.h, winFac.draggingX, winFac.draggingY)
      if (moveMode.indexOf("right") >= 0) {
        winFac.r -= winFac.draggingX;
        winFac.w += winFac.draggingX;
      }
      if (moveMode.indexOf("left") >= 0) {
        winFac.l += winFac.draggingX;
        winFac.w -= winFac.draggingX;
      }
      if (moveMode.indexOf("top") >= 0) {
        winFac.t += winFac.draggingY;
        winFac.h -= winFac.draggingY;
      }
      if (moveMode.indexOf("bottom") >= 0) {
        winFac.b -= winFac.draggingY;
        winFac.h += winFac.draggingY;
      }
      winFac.draggingX = 0;
      winFac.draggingY = 0;
      this.mouseUp(event, 4);
    }
    this.moveMode = flg ? direct : "";
  }

  private reflesh(this: any): void {
    switch (this.moveMode) {
      case "side-top":
      case "side-bottom":
      case "corner-left-top":
      case "corner-left-bottom":
      case "corner-right-top":
      case "corner-right-bottom":
      case "move":
        this.windowFactor.draggingY = this.mouse.y - this.mouse.saveY;
    }
    switch (this.moveMode) {
      case "side-left":
      case "side-right":
      case "corner-left-top":
      case "corner-left-bottom":
      case "corner-right-top":
      case "corner-right-bottom":
      case "move":
        this.windowFactor.draggingX = this.mouse.x - this.mouse.saveX;
    }
  }

  private move(
    this: any,
    event: any,
    isStart: boolean,
    isTouch: boolean
  ): void {
    if (isStart) {
      this.mouse.saveX = isTouch
        ? event.changedTouches[0]["pageX"]
        : event.pageX;
      this.mouse.saveY = isTouch
        ? event.changedTouches[0]["pageY"]
        : event.pageY;
      this.setProperty({
        property: `map.moveObj`,
        value: {
          key: this.displayProperty,
          isMoving: true
        },
        logOff: true
      });
    } else {
      this.setProperty({
        property: `map.moveObj.isMoving`,
        value: false,
        logOff: true
      });
      this.windowFactor.r -= this.windowFactor.draggingX;
      this.windowFactor.t += this.windowFactor.draggingY;
      this.windowFactor.l += this.windowFactor.draggingX;
      this.windowFactor.b -= this.windowFactor.draggingY;
      this.windowFactor.draggingX = 0;
      this.windowFactor.draggingY = 0;
      this.mouseUp(event, 5);
    }
    this.moveMode = isStart ? "move" : "";
  }

  private addEventForIFrame(this: any): void {
    const elms: HTMLCollection = document.getElementsByTagName("iFrame");
    Array.prototype.slice.call(elms).forEach((iFrameElm: HTMLIFrameElement) => {
      // マウス移動
      const mouseMoveListener = (event: any) => {
        const iFrameRect = iFrameElm.getBoundingClientRect();
        const evtObj = {
          clientX: event.pageX + iFrameRect.left,
          clientY: event.pageY + iFrameRect.top
        };
        document.dispatchEvent(new MouseEvent("mousemove", evtObj));
      };
      // タッチ移動
      const touchMoveListener = (event: any) => {
        const iFrameRect = iFrameElm.getBoundingClientRect();
        const evtObj: any = {
          changedTouches: [
            {
              clientX: event.changedTouches[0]["pageX"] + iFrameRect.left,
              clientY: event.changedTouches[0]["pageY"] + iFrameRect.top
            }
          ]
        };
        document.dispatchEvent(new MouseEvent("touchmove", evtObj));
      };
      // クリック
      const clickListener = (event: any) => {
        const iFrameRect = iFrameElm.getBoundingClientRect();
        const evtObj = {
          clientX: event.pageX + iFrameRect.left,
          clientY: event.pageY + iFrameRect.top,
          button: event.button
        };
        document
          .getElementById("mapBoardFrame")!
          .dispatchEvent(new MouseEvent("click", evtObj));
      };
      // マウス離す
      const _ = this;
      const mouseUpListener = (event: any) => {
        const iFrameRect = iFrameElm.getBoundingClientRect();
        const evtObj = {
          clientX: event.pageX + iFrameRect.left,
          clientY: event.pageY + iFrameRect.top,
          button: event.button
        };
        if (event.button === 2) {
          _.setProperty({
            property: `map.isOverEvent`,
            value: true,
            logOff: true
          });
        }
        document
          .getElementById("mapBoardFrame")!
          .dispatchEvent(new MouseEvent("mouseUp", evtObj));
      };
      // コン文字內容菜單防止
      const contextMenuListener = () => {
        return false;
      };
      if (!iFrameElm.onload) {
        try {
          iFrameElm.onload = () => {
            try {
              const bodyElm: HTMLDocument = iFrameElm.contentWindow!.document;
              if (!bodyElm.onmousemove) {
                bodyElm.onmousemove = mouseMoveListener;
              }
              if (!bodyElm.ontouchmove) {
                bodyElm.ontouchmove = touchMoveListener;
              }
              if (!bodyElm.onmouseup) {
                bodyElm.onmouseup = mouseUpListener;
              }
              if (!bodyElm.oncontextmenu) {
                bodyElm.oncontextmenu = contextMenuListener;
              }
              if (!bodyElm.onclick) {
                bodyElm.onclick = clickListener;
              }
              /*
                const aElms = bodyElm.getElementsByTagName('a')
                for (const aElm of aElms) {
                  if (!aElm.onmousemove) { aElm.onmousemove = mouseMoveListener }
                  if (!aElm.ontouchmove) { aElm.ontouchmove = touchMoveListener }
                  if (!aElm.oncontextmenu) { aElm.oncontextmenu = contextMenuListener }
                  if (!aElm.onclick) { aElm.onclick = clickListener }
                }
                */
            } catch (error) {
              /* Nothing */
            }
          };
        } catch (error) {
          /* Nothing */
        }
      }
      if (!iFrameElm.onmousemove) {
        iFrameElm.onmousemove = mouseMoveListener;
      }
      if (!iFrameElm.ontouchmove) {
        iFrameElm.ontouchmove = touchMoveListener;
      }
      if (!iFrameElm.onmouseup) {
        iFrameElm.onmouseup = mouseUpListener;
      }
      if (!iFrameElm.onclick) {
        iFrameElm.onclick = clickListener;
      }
    });
  }

  private clickStandImage(standImage: any, index: number): void {
    this.standImageList.splice(index, 1);
  }

  private standImageStyle(standImage: any, index: number): any {
    const locate = standImage.locate;
    const mpx: number = (this.standImageWidth * (locate - 1)) / 12;
    return {
      width: `${this.standImageWidth}px`,
      height: `${this.standImageHeight}px`,
      left: `calc((100% - ${this.standImageWidth}px) * ${locate - 1} / 11)`,
      zIndex: index + 2
    };
  }

  @Watch("command", { deep: true })
  private onChangeCommand(this: any, command: any) {
    if (!command) {
      return;
    }
    if (command.command === "open" || command.command === "reset") {
      this.windowFactor.l = 0;
      this.windowFactor.r = 0;
      this.windowFactor.t = 0;
      this.windowFactor.b = 0;
      this.windowFactor.w = 0;
      this.windowFactor.h = 0;
    }
    if (command.command === "open") {
      setTimeout(this.addEventForIFrame, 0);
    }
    this.setProperty({
      property: `${this.displayProperty}.command`,
      value: null,
      logOff: true
    });
    setTimeout(() => this.$emit(command.command, command.payload), 0);
  }

  private get isDisplay(this: any): string {
    return !this.displayProperty
      ? ""
      : this.getStateValue(this.displayProperty).isDisplay;
  }

  private get command(this: any): string {
    return !this.displayProperty
      ? ""
      : this.getStateValue(this.displayProperty).command;
  }
  private get zIndex(this: any): any {
    return this.getStateValue(this.displayProperty).zIndex;
  }
  private get standImageList(this: any): any[] {
    return this.getStateValue(this.displayProperty).standImageList || [];
  }
  private get isFix(this: any): boolean {
    return this.fixSize !== undefined;
  }
  private get fixW(this: any): number {
    return !this.isFix ? -1 : parseInt(this.fixSize.split(",")[0].trim(), 10);
  }
  private get fixH(this: any): number {
    return !this.isFix ? -1 : parseInt(this.fixSize.split(",")[1].trim(), 10);
  }
  private get base(this: any): any {
    if (!this.baseSize) {
      return { w: 0, h: 0 };
    }
    return {
      w: parseInt(this.baseSize.split(",")[0].trim(), 10),
      h: parseInt(this.baseSize.split(",")[1].trim(), 10)
    };
  }

  private get standImageSizeOptionInfoList(): any[] {
    const resultList: any[] = [];
    resultList.push({
      key: 0,
      value: "192*256",
      text: "192*256",
      disabled: false
    });
    resultList.push({
      key: 1,
      value: "300*400",
      text: "300*400",
      disabled: false
    });
    resultList.push({
      key: 2,
      value: "450*600",
      text: "450*600",
      disabled: false
    });
    return resultList;
  }

  @Emit("windowStyle")
  @Watch("windowStyle")
  private onChangeWindowStyle(windowStyle: any) {}

  private get windowStyle(this: any): any {
    const moveMode = this.moveMode;
    const winFac = this.windowFactor;

    let left = winFac.l;
    let bottom = winFac.b;
    let right = winFac.r;
    let top = winFac.t;
    let height = winFac.h;
    let width = winFac.w;

    if (moveMode.indexOf("top") >= 0 || moveMode === "move") {
      top += winFac.draggingY;
      if (moveMode.indexOf("top") >= 0) {
        height -= winFac.draggingY;
      }
    }

    if (moveMode.indexOf("bottom") >= 0 || moveMode === "move") {
      bottom -= winFac.draggingY;
      if (moveMode.indexOf("bottom") >= 0) {
        height += winFac.draggingY;
      }
    }

    if (moveMode.indexOf("right") >= 0 || moveMode === "move") {
      right -= winFac.draggingX;
      if (moveMode.indexOf("right") >= 0) {
        width += winFac.draggingX;
      }
    }

    if (moveMode.indexOf("left") >= 0 || moveMode === "move") {
      left += winFac.draggingX;
      if (moveMode.indexOf("left") >= 0) {
        width -= winFac.draggingX;
      }
    }

    const obj: any = {
      left: this.align.indexOf("left") >= 0 ? left + "px" : undefined,
      right: this.align.indexOf("right") >= 0 ? right + "px" : undefined,
      top: this.align.indexOf("top") >= 0 ? top + 37 + "px" : undefined,
      bottom: this.align.indexOf("bottom") >= 0 ? bottom + "px" : undefined,
      "z-index": this.zIndex
    };
    if (
      this.align.indexOf("left") < 0 &&
      this.align.indexOf("right") < 0 &&
      this.align.indexOf("top") < 0 &&
      this.align.indexOf("bottom") < 0
    ) {
      if (this.isFix) {
        // obj.left = `calc((100% - ${this.fixW}px) / 2 + ${left}px)`
        obj.left = `calc(50% - ${this.fixW / 2 - left}px)`;
        obj.top = `calc(50% - ${this.fixH / 2 - top}px)`;
      } else {
        obj.left =
          this.base.w > 0
            ? `calc(50% - ${this.base.w / 2 - left}px)`
            : `calc(${-this.base.w / 2 + left}px)`;
        obj.top =
          this.base.h > 0
            ? `calc(50% - ${this.base.h / 2 - top}px)`
            : `calc(${-this.base.h / 2 + top}px)`;
      }
    }
    if (this.isFix) {
      obj.width = this.fixW + "px";
      obj.height = this.fixH + "px";
    } else {
      obj.width =
        this.base.w > 0
          ? `${this.base.w + width}px`
          : `calc(100% - ${-this.base.w - width + 10}px)`;
      obj.height =
        this.base.h > 0
          ? `${this.base.h + height}px`
          : `calc(100% - ${-this.base.h - height - 10}px)`;
    }
    if (this.isModal && this.zIndex <= 99999) {
      obj.filter = "blur(3px)";
    }
    return obj;
  }
}
