

































import PieceMixin from "../../PieceMixin.vue";

import { Component } from "vue-property-decorator";
import { Getter } from "vuex-class";

@Component
export default class Chit extends PieceMixin {
  @Getter("imageList") imageList: any;

  private get imageData() {
    const obj: any = this.imageList.filter(
      (obj: any) => obj.key === this.imageKey
    )[0];
    return obj ? obj.data : null;
  }

  private get chitStyle() {
    let obj: any = this.style;
    if (this.storeObj.isDraggingLeft) {
      const plus = 1.5;
      obj.left = this.rect.left - plus + "px";
      obj.top = this.rect.top - plus + "px";
      obj.width = this.rect.width + plus * 2 + "px";
      obj.height = this.rect.height + plus * 2 + "px";
      obj.opacity = 0.6;
    }
    return obj;
  }

  private get imageKey() {
    return this.storeObj.imageKey.replace(":R", "");
  }
}
