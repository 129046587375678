var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"card",staticClass:"card",class:[
    _vm.viewMode,
    {
      isViewer: _vm.isViewer,
      isOdd: _vm.useIndex % 2 === 1,
      isEven: _vm.useIndex % 2 === 0
    }
  ],style:(_vm.cardStyle),on:{"mouseover":_vm.mouseOver,"mouseout":_vm.mouseOut,"contextmenu":[function($event){$event.preventDefault();return _vm.openContext($event)},function($event){$event.preventDefault();}]}},[_c('figure',{staticClass:"front",style:(_vm.frontStyle)}),_c('div',{staticClass:"back",style:(_vm.backStyle),domProps:{"innerHTML":_vm._s(_vm.card ? _vm.card.back.text.replace(/\n/g, '<br />') : '')}})])}
var staticRenderFns = []

export { render, staticRenderFns }