














































































































import WindowFrame from "../WindowFrame.vue";
import WindowMixin from "../WindowMixin.vue";
import Divider from "../parts/Divider.vue";
import CtrlButton from "@/components/parts/CtrlButton.vue";

import { Action, Getter, Mutation } from "vuex-class";
import { Component, Mixins } from "vue-mixin-decorator";

@Component({
  components: {
    CtrlButton,
    WindowFrame,
    Divider
  }
})
export default class SettingChatTargetTabWindow extends Mixins<WindowMixin>(
  WindowMixin
) {
  @Action("windowClose") private windowClose: any;
  @Action("windowOpen") private windowOpen: any;
  @Action("setProperty") private setProperty: any;
  @Action("addGroupTargetTab") private addGroupTargetTab: any;
  @Action("deleteGroupTargetTab") private deleteGroupTargetTab: any;
  @Getter("getSelfActors") private getSelfActors: any;
  @Getter("getViewName") private getViewName: any;
  @Getter("getObj") private getObj: any;
  @Getter("playerKey") private playerKey: any;
  @Getter("chatActorKey") private chatActorKey: any;
  @Getter("isChatTabVertical") private isChatTabVertical: any;
  @Getter("groupTargetTabList") private groupTargetTabList: any;
  @Getter("getMapObjectList") private getMapObjectList: any;

  private open() {
    const button: CtrlButton = this.$refs.button as CtrlButton;
    button.requestFocus();
  }

  private isEditableTab(key: string): boolean {
    if (key === "groupTargetTab-0") return false;

    const tabObj: any = this.groupTargetTabList.find(
      (tabObj: any) => tabObj.key === key
    );
    if (tabObj.isAll) return true;

    const playerKey: string = this.playerKey;
    const fieldCharacters: any[] = this.getMapObjectList({
      kind: "character",
      place: "field",
      playerKey
    });

    const actor = tabObj.group.filter(
      (g: any) =>
        g === playerKey ||
        fieldCharacters.findIndex((c: any) => c.key === g) >= 0
    )[0];

    return actor;
  }

  private addButtonOnClick() {
    this.windowOpen("private.display.addGroupChatWindow");
  }

  private delButtonOnClick() {
    if (this.selectLineKey) {
      this.deleteGroupTargetTab({ key: this.selectLineKey });
    } else {
      alert("請先選擇刪除對像。");
    }
  }

  private edit(key: string) {
    this.setProperty({
      property: "private.display.editGroupChatWindow.key",
      value: key,
      logOff: true
    });
    this.windowOpen("private.display.editGroupChatWindow");
  }

  private moveDev(event: any) {
    if (this.movingIndex > -1) {
      const diff = event.clientX - this.startX;
      const afterLeftWidth = this.startLeftWidth + diff;
      const afterRightWidth = this.startRightWidth - diff;
      if (afterLeftWidth >= 10 && afterRightWidth >= 10) {
        const paramObj: any = {};
        paramObj[this.movingIndex] = afterLeftWidth;
        paramObj[this.movingIndex + 1] = afterRightWidth;
        this.setProperty({
          property: "private.display.settingChatTargetTabWindow.widthList",
          value: paramObj,
          logOff: true
        });
      }
    }
  }

  private moveDevEnd() {
    this.setProperty({
      property: "private.display.settingChatTargetTabWindow",
      value: {
        hoverDevIndex: -1,
        movingIndex: -1,
        startX: -1,
        startLeftWidth: -1,
        startRightWidth: -1
      },
      logOff: true
    });
  }

  private selectLine(selectLineKey: string) {
    this.setProperty({
      property: "private.display.settingChatTargetTabWindow.selectLineKey",
      value: selectLineKey,
      logOff: true
    });
  }

  private isSelected(groupTargetTab: any, player: any) {
    return !!groupTargetTab.group.filter((t: string) => t === player.key)[0];
  }

  private changeProp(groupTargetTab: any, prop: string, newValue: any) {
    const target = this.groupTargetTabList.filter(
      (tab: any) => tab.key === groupTargetTab.key
    )[0];
    if (!target) return;
    const index = this.groupTargetTabList.indexOf(target);

    const value: any = {};
    value[prop] = newValue;
    if (prop === "isAll" && newValue) {
      value.group = [];
    }

    this.setProperty({
      property: `public.chat.groupTargetTab.list.${index}`,
      value: value,
      isNotice: true,
      logOff: true
    });
  }

  private changeGroupTargetMember(
    groupTargetTab: any,
    player: any,
    flg: boolean
  ) {
    const newArr = groupTargetTab.group.concat();
    if (flg) {
      // 增加的場合
      newArr.push(player.key);
    } else {
      // 除外的場合
      const index = groupTargetTab.group.indexOf(player.key);
      newArr.splice(index, 1);
    }
    this.changeProp(groupTargetTab, "group", newArr);
  }

  private getViewNames(tab: any) {
    return tab.isAll
      ? "所有人"
      : tab.group.map((g: string) => this.getViewName(g)).join(", ");
  }

  /* Start 列幅可変テーブル的プロパティ */
  private get selectLineKey() {
    return this.$store.state.private.display.settingChatTargetTabWindow
      .selectLineKey;
  }

  private get widthList() {
    return this.$store.state.private.display.settingChatTargetTabWindow
      .widthList;
  }

  private get movingIndex() {
    return this.$store.state.private.display.settingChatTargetTabWindow
      .movingIndex;
  }

  private get startX() {
    return this.$store.state.private.display.settingChatTargetTabWindow.startX;
  }

  private get startLeftWidth() {
    return this.$store.state.private.display.settingChatTargetTabWindow
      .startLeftWidth;
  }

  private get startRightWidth() {
    return this.$store.state.private.display.settingChatTargetTabWindow
      .startRightWidth;
  }

  private get colStyle() {
    return (index: number) => ({ width: `${this.widthList[index]}px` });
  }
  /* End 列幅可変テーブル的プロパティ */

  private get windowSize() {
    return this.$store.state.private.display.settingChatTargetTabWindow
      .windowSize;
  }

  private get isTabVertical() {
    return this.isChatTabVertical;
  }
  private set isTabVertical(flg: boolean) {
    this.setProperty({
      property: `private.display.settingChatTargetTabWindow.isTabVertical`,
      value: flg,
      logOff: true
    });
  }
}
