





















import WindowFrame from "../../WindowFrame.vue";
import WindowMixin from "../../WindowMixin.vue";
import CtrlSelect from "../../parts/CtrlSelect.vue";
import PlayerSelect from "../../parts/select/PlayerSelect.vue";
import CtrlButton from "../../parts/CtrlButton.vue";

import { Action, Getter } from "vuex-class";
import { Component, Mixins } from "vue-mixin-decorator";

@Component({
  components: {
    CtrlButton,
    PlayerSelect,
    CtrlSelect,
    WindowFrame
  }
})
export default class SelectNewOwnerWindow extends Mixins<WindowMixin>(
  WindowMixin
) {
  @Action("windowClose") private windowClose: any;
  @Action("changeListObj") private changeListObj: any;
  @Getter("getObj") private getObj: any;

  private currentPlayerKey: string = "";

  private get objKey() {
    return this.$store.state.private.display["selectNewOwnerWindow"].objKey;
  }

  private get characterName() {
    const character = this.getObj(this.objKey);
    return character ? character.name : "";
  }

  private open() {
    this.currentPlayerKey = "";
  }

  private commit() {
    this.changeListObj({
      key: this.objKey,
      owner: this.currentPlayerKey,
      isNotice: true
    });
    this.windowClose("private.display.selectNewOwnerWindow");
  }

  private cancel() {
    this.windowClose("private.display.selectNewOwnerWindow");
  }
}
