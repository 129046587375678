






































import vueSlider from "vue-slider-component";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    vueSlider
  }
})
export default class RangeMultiplePersent extends Vue {
  @Prop({ type: Array, default: [20, 80] })
  private value!: number[];

  @Emit("input")
  public input(value: number[]) {}

  private get localValue(): number[] {
    return this.value;
  }

  private set localValue(value: number[]) {
    this.input(value);
  }
}
