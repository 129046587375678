var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('window-frame',{attrs:{"titleText":"圖片上傳","display-property":"private.display.fileUploaderWindow","align":"center","fixSize":"530, 400","message":_vm.message},on:{"open":_vm.open,"reset":_vm.open}},[_c('div',{staticClass:"contents",on:{"contextmenu":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"image-container"},_vm._l((_vm.useImageList),function(image){return _c('div',{key:image.key,staticClass:"image-block",class:{ hover: image.key === _vm.hoverImageKey },on:{"mouseenter":function($event){return _vm.imageOnHover(image.key)},"mouseleave":function($event){return _vm.imageOnHover()}}},[_c('img',{directives:[{name:"img",rawName:"v-img",value:(image.image),expression:"image.image"}],attrs:{"alt":""}}),_c('span',{staticClass:"icon-cross",on:{"click":function($event){return _vm.deleteImageOnClick(image.key)}}})])}),0),_c('div',{staticClass:"ctrl-type-1"},[_c('label',[_vm._v("支援圖片：JPEG/GIF/PNG")]),_c('ctrl-select',{attrs:{"disabled":true,"optionInfoList":[
          {
            key: null,
            value: '',
            text: '房間専用',
            disabled: true
          }
        ]}}),_c('ctrl-button',{on:{"click":_vm.passwordButtonOnClick}},[_vm._v("隱藏圖片密碼(未實裝)")])],1),_c('div',{staticClass:"ctrl-type-1"},[_c('label',{attrs:{"for":"fileUploader-tag"}},[_vm._v("加上標籤（半型・全型和空格分隔）：")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputImageTag),expression:"inputImageTag"}],attrs:{"type":"text","id":"fileUploader-tag"},domProps:{"value":(_vm.inputImageTag)},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.stopPropagation();},function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==229){ return null; }$event.stopPropagation();}],"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.stopPropagation();},function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==229){ return null; }$event.stopPropagation();}],"input":function($event){if($event.target.composing){ return; }_vm.inputImageTag=$event.target.value}}}),_c('image-tag-select',{staticClass:"tagSelect",model:{value:(_vm.selectImageTag),callback:function ($$v) {_vm.selectImageTag=$$v},expression:"selectImageTag"}})],1),_c('div',{staticClass:"ctrl-type-2"},[_c('ctrl-file-selector',{ref:"button",on:{"change":_vm.fileOnChange}},[_vm._v("選擇要讀取的圖片")]),_c('ctrl-button',{attrs:{"disabled":!_vm.useImageList.length},on:{"click":_vm.commitOnClick}},[_vm._v("讀取")]),_c('ctrl-button',{on:{"click":_vm.cancelOnClick}},[_vm._v("關閉")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }