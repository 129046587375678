





































































import WindowFrame from "../../WindowFrame.vue";
import WindowMixin from "../../WindowMixin.vue";
import CtrlSelect from "@/components/parts/CtrlSelect.vue";

import { Action, Getter } from "vuex-class";
import { Component, Mixins } from "vue-mixin-decorator";
import { Watch } from "vue-property-decorator";

@Component({
  components: {
    CtrlSelect,
    WindowFrame
  }
})
export default class AddDiceSymbolWindow extends Mixins<WindowMixin>(
  WindowMixin
) {
  @Action("windowOpen") private windowOpen: any;
  @Action("windowClose") private windowClose: any;
  @Getter("imageTagList") private imageTagList: any;
  @Getter("imageList") private imageList: any;
  @Getter("dice") private dice: any;
  @Getter("dicePipsImage") private dicePipsImage: any;

  private faceNum: string = "6";
  private type: string = "";
  private pips: number = 1;
  private isHide: boolean = false;

  private open() {
    this.faceNum = "6";
    this.pips = 1;
    this.isHide = false;
    this.onChangeFaceNum(this.faceNum);

    const input: HTMLInputElement = this.$refs.input as HTMLInputElement;
    input.focus();
  }

  @Watch("faceNum", { immediate: true })
  onChangeFaceNum(faceNum: string) {
    this.pips = 1;
    const diceSetList: any[] = this.dice[faceNum];
    this.type = diceSetList ? diceSetList[0].type : "";
  }

  private dragStart(event: any) {
    event.dataTransfer.setData("kind", "diceSymbol");
    event.dataTransfer.setData("faceNum", this.faceNum);
    event.dataTransfer.setData("type", this.type);
    event.dataTransfer.setData("pips", this.pips);
    event.dataTransfer.setData("isHide", this.isHide);
  }

  private get diceImage() {
    return this.dicePipsImage(this.faceNum, this.type, this.pips);
  }

  private get faceNumOptionInfoList(): any[] {
    const resultList: any[] = [];
    resultList.push({
      key: 0,
      value: "4",
      text: "D4",
      disabled: false
    });
    resultList.push({
      key: 1,
      value: "6",
      text: "D6",
      disabled: false
    });
    resultList.push({
      key: 2,
      value: "8",
      text: "D8",
      disabled: false
    });
    resultList.push({
      key: 3,
      value: "10",
      text: "D10",
      disabled: false
    });
    resultList.push({
      key: 4,
      value: "12",
      text: "D12",
      disabled: false
    });
    resultList.push({
      key: 5,
      value: "20",
      text: "D20",
      disabled: false
    });
    return resultList;
  }

  private get diceTypeOptionInfoList(): any[] {
    return !this.dice[this.faceNum]
      ? []
      : this.dice[this.faceNum].map((diceObj: any) => ({
          key: diceObj.type,
          value: diceObj.type,
          text: diceObj.label,
          disabled: false
        }));
  }
}
