import { render, staticRenderFns } from "./EditCustomDiceBotTableWindow.vue?vue&type=template&id=728d2298&scoped=true&"
import script from "./EditCustomDiceBotTableWindow.vue?vue&type=script&lang=ts&"
export * from "./EditCustomDiceBotTableWindow.vue?vue&type=script&lang=ts&"
import style0 from "./EditCustomDiceBotTableWindow.vue?vue&type=style&index=0&id=728d2298&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "728d2298",
  null
  
)

export default component.exports