
















































































































































































import CtrlButton from "../parts/CtrlButton.vue";
import WindowFrame from "../WindowFrame.vue";
import WindowMixin from "../WindowMixin.vue";

import { Action, Getter } from "vuex-class";
import { Component, Mixins } from "vue-mixin-decorator";
import { Watch } from "vue-property-decorator";
import ImageSelector from "@/components/parts/ImageSelector.vue";

@Component({
  components: {
    ImageSelector,
    CtrlButton,
    WindowFrame
  }
})
export default class EditMapWindow extends Mixins<WindowMixin>(WindowMixin) {
  @Action("setProperty") private setProperty: any;
  @Action("windowOpen") private windowOpen: any;
  @Action("windowClose") private windowClose: any;
  @Getter("peerId") private peerId: any;
  @Getter("isWait") private isWait: any;
  @Getter("imageListFromTagKey") private imageListFromTagKey: any;
  @Getter("currentMap") private currentMap: any;

  private edit: any = {
    imageTag: "imgTag-1",
    imageKey: "image-2",
    isReverse: false,
    marginGridSize: 5,
    marginGridColor: "#FFFFFF",
    maskColor: "#145014",
    maskAlpha: 0.1,
    isUseGridColor: true,
    isUseImage: true,
    borderWidth: 20,
    totalColumn: 20,
    totalRow: 15,
    gridColor: "#000000",
    backgroundColor: "#92A8B3"
  };

  private original: any = {
    imageTag: "imgTag-1",
    imageKey: "image-2",
    isReverse: false,
    marginGridSize: 5,
    marginGridColor: "#FFFFFF",
    maskColor: "#145014",
    maskAlpha: 0.1,
    isUseGridColor: true,
    isUseImage: true,
    borderWidth: 20,
    totalColumn: 20,
    totalRow: 15,
    gridColor: "#000000",
    backgroundColor: "#92A8B3"
  };

  private open() {
    const peerId = this.peerId(this.isWait);
    if (this.storeMap.isEditing && this.storeMap.isEditing !== peerId) {
      alert("因為地圖更改操作與其他人的面面衝突，所以該操作將被取消。");
      this.windowClose("private.display.editMapWindow");
      return;
    }
    this.edit.imageTag = this.currentMap.imageTag;
    this.edit.imageKey = this.currentMap.imageKey;
    this.edit.isReverse = this.currentMap.isReverse;
    this.edit.marginGridSize = this.currentMap.margin.gridSize;
    this.edit.isUseGridColor = this.currentMap.margin.isUseGridColor;
    this.edit.isUseImage = this.currentMap.margin.isUseImage;
    this.edit.marginGridColor = this.currentMap.margin.gridColor;
    this.edit.maskColor = this.currentMap.margin.maskColor;
    this.edit.maskAlpha = this.currentMap.margin.maskAlpha;
    this.edit.borderWidth = this.currentMap.margin.borderWidth;
    this.edit.totalColumn = this.currentMap.grid.totalColumn;
    this.edit.totalRow = this.currentMap.grid.totalRow;
    this.edit.gridColor = this.currentMap.grid.color;
    this.edit.backgroundColor = this.currentMap.background;

    this.original.imageTag = this.currentMap.imageTag;
    this.original.imageKey = this.currentMap.imageKey;
    this.original.isReverse = this.currentMap.isReverse;
    this.original.marginGridSize = this.currentMap.margin.gridSize;
    this.original.isUseGridColor = this.currentMap.margin.isUseGridColor;
    this.original.isUseImage = this.currentMap.margin.isUseImage;
    this.original.marginGridColor = this.currentMap.margin.gridColor;
    this.original.maskColor = this.currentMap.margin.maskColor;
    this.original.maskAlpha = this.currentMap.margin.maskAlpha;
    this.original.borderWidth = this.currentMap.margin.borderWidth;
    this.original.totalColumn = this.currentMap.grid.totalColumn;
    this.original.totalRow = this.currentMap.grid.totalRow;
    this.original.gridColor = this.currentMap.grid.color;
    this.original.backgroundColor = this.currentMap.background;
    this.setProperty({
      property: "public.map.isEditing",
      isNotice: true,
      value: peerId,
      logOff: true
    });

    const selecter: ImageSelector = this.$refs.selecter as ImageSelector;
    selecter.requestFocus();
  }

  private commit() {
    const peerId = this.peerId(this.isWait);
    if (this.storeMap.isEditing === peerId) {
      const index = this.storeMap.list.findIndex(
        (mapObj: any) => mapObj.key === this.currentMap.key
      );
      this.setProperty({
        property: `public.map.list.${index}`,
        isNotice: true,
        value: {
          imageTag: this.edit.imageTag,
          imageKey: this.edit.imageKey,
          isReverse: this.edit.isReverse,
          margin: {
            gridSize: parseInt(this.edit.marginGridSize, 10),
            borderWidth: parseInt(this.edit.borderWidth, 10),
            gridColor: this.edit.marginGridColor,
            isUseGridColor: this.edit.isUseGridColor,
            isUseImage: this.edit.isUseImage,
            maskColor: this.edit.maskColor,
            maskAlpha: parseFloat(this.edit.maskAlpha)
          },
          grid: {
            totalColumn: parseInt(this.edit.totalColumn, 10),
            totalRow: parseInt(this.edit.totalRow, 10),
            color: this.edit.gridColor
          },
          background: this.edit.backgroundColor,
          isEditing: false
        },
        logOff: true
      });
    } else {
      alert("因為地圖更改操作與其他人的面面衝突，所以該操作將被取消。");
    }
    this.setProperty({
      property: "public.map.isEditing",
      isNotice: true,
      value: null,
      logOff: true
    }).then(() => {
      this.windowClose("private.display.editMapWindow");
    });
  }

  private cancel() {
    if (this.storeMap.isEditing === this.peerId(this.isWait)) {
      const index = this.storeMap.list.findIndex(
        (mapObj: any) => mapObj.key === this.currentMap.key
      );
      this.setProperty({
        property: `public.map.list.${index}`,
        isNotice: true,
        value: {
          imageTag: this.original.imageTag,
          imageKey: this.original.imageKey,
          isReverse: this.original.isReverse,
          margin: {
            gridSize: parseInt(this.original.marginGridSize, 10),
            borderWidth: parseInt(this.original.borderWidth, 10),
            gridColor: this.original.marginGridColor,
            isUseGridColor: this.original.isUseGridColor,
            isUseImage: this.original.isUseImage,
            maskColor: this.original.maskColor,
            maskAlpha: parseFloat(this.original.maskAlpha)
          },
          grid: {
            totalColumn: parseInt(this.original.totalColumn, 10),
            totalRow: parseInt(this.original.totalRow, 10),
            color: this.original.gridColor
          },
          background: this.original.backgroundColor,
          isEditing: false
        },
        logOff: true
      });
    }
    this.windowClose("private.display.editMapWindow");
  }

  private close() {
    this.setProperty({
      property: "public.map.isEditing",
      isNotice: true,
      value: null,
      logOff: true
    });
  }

  private doReverse() {
    this.edit.isReverse = !this.edit.isReverse;
  }

  private selectImage(key: string) {
    this.edit.imageKey = key;
  }

  private getKeyObj(list: any[], key: string) {
    const filteredList = list.filter(obj => obj.key === key);
    if (filteredList.length === 0) {
      // window.console.log(`key:"${key}" is not find.`);
      return null;
    }
    if (filteredList.length > 1) {
      // window.console.log(`key:"(${key})" is duplicate.`);
      return null;
    }
    return filteredList[0];
  }

  private get selectedTagIndexText(): string {
    const useImageList = this.useImageList;
    const keyObj = this.getKeyObj(useImageList, this.edit.imageKey);
    const index = keyObj ? useImageList.indexOf(keyObj) + 1 : 0;
    return `${index}/${useImageList.length}`;
  }

  private get tagList() {
    return this.$store.state.public.image.tags.list;
  }

  private get storeImages() {
    return this.$store.state.public.image.list;
  }

  private get currentImage() {
    return this.getKeyObj(this.storeImages, this.edit.imageKey).data;
  }

  private get useImageList(): any[] {
    return this.imageListFromTagKey(this.edit.imageTag);
  }

  private get storeMap() {
    return this.$store.state.public.map;
  }

  private watchBase(property: string, value: any) {
    const index = this.storeMap.list.findIndex(
      (mapObj: any) => mapObj.key === this.currentMap.key
    );
    this.setProperty({
      property: `public.map.list.${index}.${property}`,
      value,
      logOff: true
    });
  }

  @Watch("edit.imageTag")
  private onChangeEditImageTag(imageTag: string) {
    this.watchBase("imageTag", imageTag);
  }

  @Watch("edit.imageKey")
  private onChangeEditImageKey(imageKey: string) {
    this.watchBase("imageKey", imageKey);
  }

  @Watch("edit.isReverse")
  private onChangeEditIsReverse(isReverse: boolean) {
    this.watchBase("isReverse", isReverse);
  }

  @Watch("edit.marginGridSize")
  private onChangeEditMarginGridSize(marginGridSize: string) {
    this.watchBase("margin.gridSize", parseInt(marginGridSize, 10));
  }

  @Watch("edit.isUseGridColor")
  private onChangeEditIsUseGridColor(isUseGridColor: boolean) {
    this.watchBase("margin.isUseGridColor", isUseGridColor);
  }

  @Watch("edit.isUseImage")
  private onChangeEditIsUseImage(isUseImage: boolean) {
    this.watchBase("margin.isUseImage", isUseImage);
  }

  @Watch("edit.marginGridColor")
  private onChangeEditMarginGridColor(marginGridColor: string) {
    this.watchBase("margin.gridColor", marginGridColor);
  }

  @Watch("edit.maskColor")
  private onChangeEditMaskColor(maskColor: string) {
    this.watchBase("margin.maskColor", maskColor);
  }

  @Watch("edit.maskAlpha")
  private onChangeEditMaskAlpha(maskAlpha: string) {
    this.watchBase("margin.maskAlpha", parseFloat(maskAlpha));
  }

  @Watch("edit.borderWidth")
  private onChangeEditBorderWidth(borderWidth: string) {
    this.watchBase("margin.borderWidth", parseInt(borderWidth, 10));
  }

  @Watch("edit.totalColumn")
  private onChangeEditTotalColumn(totalColumn: string) {
    this.watchBase("grid.totalColumn", parseInt(totalColumn, 10));
  }

  @Watch("edit.totalRow")
  private onChangeEditTotalRow(totalRow: string) {
    this.watchBase("grid.totalRow", parseInt(totalRow, 10));
  }

  @Watch("edit.gridColor")
  private onChangeEditGridColor(gridColor: string) {
    this.watchBase("grid.color", gridColor);
  }

  @Watch("edit.backgroundColor")
  private onChangeEditBackgroundColor(backgroundColor: string) {
    this.watchBase("background", backgroundColor);
  }
}
