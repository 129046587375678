export default {
  /** 骰子シンボル */
  state: {
    list: [],
    maxKey: -1
  },
  actions: {},
  mutations: {},
  getters: {}
};
