import { render, staticRenderFns } from "./RoomInfo.vue?vue&type=template&id=5c8b5b67&scoped=true&"
import script from "./RoomInfo.vue?vue&type=script&lang=ts&"
export * from "./RoomInfo.vue?vue&type=script&lang=ts&"
import style0 from "./login.css?vue&type=style&index=0&id=5c8b5b67&scoped=true&lang=css&"
import style1 from "./RoomInfo.vue?vue&type=style&index=1&id=5c8b5b67&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c8b5b67",
  null
  
)

export default component.exports