































import ImageTagSelect from "@/components/parts/select/ImageTagSelect.vue";
import CtrlButton from "@/components/parts/CtrlButton.vue";

import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import { Getter, Action } from "vuex-class";

@Component({ components: { CtrlButton, ImageTagSelect } })
export default class ImageSelector extends Vue {
  @Getter("getObj") private getObj: any;
  @Getter("imageListFromTagKey") private imageListFromTagKey: any;

  @Getter("activeChatTab") private activeChatTab: any;
  @Getter("playerKey") private playerKey: any;
  @Action("sendChatLog") private sendChatLog: any;
  @Prop({ type: String, required: true })
  public value!: string;

  @Prop({ type: String, default: "imgTag-0" })
  private imageTag!: string;

  private selectImageTag: string = "";

  public requestFocus(): void {
    const input: ImageTagSelect = this.$refs.input as ImageTagSelect;
    input.requestFocus();
  }

  @Emit("input")
  public input(value: string | null) {}

  @Watch("imageTag", { immediate: true })
  private onChangeImageTag(value: string) {
    this.selectImageTag = value || "imgTag-0";
  }

  @Watch("selectImageTag")
  @Emit("update:imageTag")
  private onChangeSelectImageTag(value: string) {}

  private get localValue(): string | null {
    return this.value;
  }

  private set localValue(value: string | null) {
    this.input(value);
  }

  private get imageKey(): string | null {
    if (this.localValue === null) return null;
    return this.localValue.replace(":R", "");
  }

  private get isReverse(): boolean {
    if (!this.localValue) return false;
    return /:R/.test(this.localValue);
  }

  private set isReverse(isReverse: boolean) {
    const imageKey = this.imageKey;
    if (imageKey === null) this.localValue = null;
    this.localValue = imageKey + (isReverse ? ":R" : "");
  }

  private selectTagImage(key: string) {
    this.localValue = key;
  }

  private doReverse() {
    this.isReverse = !this.isReverse;
  }

  private onClickHideImage() {
    this.sendChatLog({
      actorKey: "HKTRPG",
      text: "未實裝此功能。",
      chatTarget: this.playerKey,
      statusName: "◆",
      outputTab: this.activeChatTab
    });
    //  alert("未實裝此功能。");
  }

  private get selectedTagIndexText() {
    const index = this.useImageList.findIndex(
      image => image.key === this.imageKey
    );
    return `${index + 1}/${this.useImageList.length}`;
  }

  private get useImageList(): any[] {
    return this.imageListFromTagKey(this.selectImageTag);
  }
}
