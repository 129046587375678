















































import WindowMixin from "../WindowMixin.vue";
import WindowFrame from "../WindowFrame.vue";
import CtrlButton from "@/components/parts/CtrlButton.vue";

import { Action, Getter } from "vuex-class";
import { Component, Mixins } from "vue-mixin-decorator";

@Component({
  components: {
    CtrlButton,
    WindowFrame
  }
})
export default class InitiativeSettingWindow extends Mixins<WindowMixin>(
  WindowMixin
) {
  @Action("setProperty") private setProperty: any;
  @Action("windowClose") private windowClose: any;
  @Action("setInitiativeParams") private setInitiativeParams: any;
  @Getter("rowStr") private rowStr: any;

  private format: string = "";

  open() {
    this.format = this.value;

    const input: HTMLInputElement = this.$refs.input as HTMLInputElement;
    input.focus();
  }

  commit() {
    this.setProperty({
      property: "public.initiative.rowStr",
      value: this.format,
      isNotice: true,
      logOff: true
    });
    this.windowClose("private.display.initiativeSettingWindow");
    this.setInitiativeParams({ format: this.format.trim() });
  }

  cancel() {
    this.windowClose("private.display.initiativeSettingWindow");
  }

  get value(): string {
    return this.rowStr;
  }
}
