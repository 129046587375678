


























































































import WindowFrame from "../WindowFrame.vue";
import WindowMixin from "../WindowMixin.vue";
import Divider from "../parts/Divider.vue";
import CtrlButton from "@/components/parts/CtrlButton.vue";

import { Action, Getter } from "vuex-class";
import { Component, Mixins } from "vue-mixin-decorator";
import ImportTypeRadio from "@/components/parts/radio/ImportTypeRadio.vue";
import { Watch } from "vue-property-decorator";
import SettingBgmTrComponent from "@/components/music/component/SettingBgmTrComponent.vue";

@Component({
  components: {
    SettingBgmTrComponent,
    ImportTypeRadio,
    CtrlButton,
    WindowFrame,
    Divider
  }
})
export default class ImportBGMWindow extends Mixins<WindowMixin>(WindowMixin) {
  @Action("setProperty") private setProperty: any;
  @Action("windowOpen") private windowOpen: any;
  @Action("addListObj") private addListObj: any;
  @Action("deleteListObj") private deleteListObj: any;
  @Action("moveListObj") private moveListObj: any;
  @Action("importBgmList") private importBgmList: any;
  @Getter("decrypt") private decrypt: any;

  private bgmList: any[] = [];
  private importType: string = "1";
  private file: File | null = null;

  private chooseFile(this: any): void {
    const fileChooser: HTMLElement = this.$refs.fileChooser;
    fileChooser.click();
  }

  private selectFile(event: any) {
    if (event.target.files.length === 0) return;
    this.file = event.target.files[0];
  }

  @Watch("file")
  private onChangeFile(file: File) {
    this.bgmList = [];
    if (!file) return;

    //FileReaderのインスタンスを作成する
    const reader = new FileReader();

    //ファイルの中身を取得後に処理を行う
    reader.addEventListener("load", () => {
      const text: string = reader.result as string;

      const nameSplit: string[] = file.name.split(".");
      const ext: string = nameSplit[nameSplit.length - 1];
      const reset: Function = () => {
        this.file = null;
        const fileChooser: HTMLInputElement = this.$refs
          .fileChooser as HTMLInputElement;
        fileChooser.value = "";
      };

      if (ext !== "json") {
        reset();
        alert("請指定一個JSON文件。");
        return;
      }

      let json: any = {};
      try {
        json = JSON.parse(text);
      } catch (err) {
        reset();
        alert("JSON格式文件已損壞。");
        return;
      }
      if (json.saveDataTypeName === "Quoridorn_BGM_01") {
        const regExp = new RegExp(/youtube/);
        json.saveData.forEach((bgm: any, index: number) => {
          bgm.key = String(index);
          const url: string = bgm.url;
          if (!regExp.test(url)) bgm.url = this.decrypt({ cipherText: url });
        });
        this.bgmList = json.saveData;
      } else {
        reset();
        alert("文件格式錯誤。\n（正：Quoridorn_BGM_01）");
      }
    });

    //読み込んだファイルの中身を取得する
    reader.readAsText(file);
  }

  private isYoutube(url: string) {
    return /www\.youtube\.com/.test(url);
  }

  private isDropBox(url: string) {
    return /dropbox/.test(url);
  }

  private initWindow(): void {
    this.setProperty({
      property: "private.display.importBGMWindow.selectLineKey",
      value: null,
      logOff: true
    });
  }

  private doPreview(): void {
    this.playBGM(true);
  }

  private doDelete(): void {
    if (!this.selectLineKey) {
      alert("請選擇BGM");
      return;
    }
    const index = this.bgmList.findIndex(
      (bgm: any) => bgm.key === this.selectLineKey
    );
    const nextIndex: number =
      index === this.bgmList.length - 1 ? index - 1 : index;
    this.bgmList.splice(index, 1);
    if (nextIndex === -1) return;
    this.selectLine(this.bgmList[nextIndex].key);
  }

  private doUp(): void {
    if (!this.selectLineKey) {
      alert("請選擇BGM");
      return;
    }
    const index = this.bgmList.findIndex(
      (bgm: any) => bgm.key === this.selectLineKey
    );
    const obj = this.bgmList.splice(index, 1)[0];

    const nextIndex: number = index === 0 ? this.bgmList.length : index - 1;
    this.bgmList.splice(nextIndex, 0, obj);
  }

  private doDown(): void {
    if (!this.selectLineKey) {
      alert("請選擇BGM");
      return;
    }
    const index = this.bgmList.findIndex(
      (bgm: any) => bgm.key === this.selectLineKey
    );
    const obj = this.bgmList.splice(index, 1)[0];

    const nextIndex: number = index === this.bgmList.length ? 0 : index + 1;
    this.bgmList.splice(nextIndex, 0, obj);
  }

  private doImport(): void {
    this.importBgmList({
      bgmList: this.bgmList,
      addType: this.importType
    }).then(() => {
      this.file = null;
      const fileChooser: HTMLInputElement = this.$refs
        .fileChooser as HTMLInputElement;
      fileChooser.value = "";
    });
  }

  private selectLine(bgmKey: string): void {
    this.setProperty({
      property: "private.display.importBGMWindow.selectLineKey",
      value: bgmKey,
      logOff: true
    });
  }

  private playBGM(isPreview: boolean = false): void {
    const addBgmObj: any = this.bgmList.find(
      (bgmObj: any) => bgmObj.key === this.selectLineKey
    );
    if (!addBgmObj) {
      alert("請選擇BGM");
      return;
    }
    this.setProperty({
      property: "private.display.jukeboxWindow.command",
      logOff: true,
      isNotice: !isPreview,
      value: { command: "add", payload: addBgmObj }
    });
  }

  private moveDev(event: any): void {
    if (this.movingIndex > -1) {
      const diff = event.clientX - this.startX;
      const afterLeftWidth = this.startLeftWidth + diff;
      const afterRightWidth = this.startRightWidth - diff;
      if (afterLeftWidth >= 10 && afterRightWidth >= 10) {
        const paramObj: any = {};
        paramObj[this.movingIndex] = afterLeftWidth;
        paramObj[this.movingIndex + 1] = afterRightWidth;
        this.setProperty({
          property: "private.display.importBGMWindow.widthList",
          value: paramObj,
          logOff: true
        });
      }
    }
  }

  private moveDevEnd(): void {
    this.setProperty({
      property: "private.display.importBGMWindow",
      value: {
        hoverDevIndex: -1,
        movingIndex: -1,
        startX: -1,
        startLeftWidth: -1,
        startRightWidth: -1
      },
      logOff: true
    });
  }

  private get convertSecond(): Function {
    return (start: number, end: number): string => {
      if (start && end) return `${start}〜${end}`;
      if (start) return `${start}〜`;
      if (end) return `〜${end}`;
      return "All";
    };
  }

  /* Start 列幅可変テーブルのプロパティ */
  private get selectLineKey(): string {
    return this.$store.state.private.display.importBGMWindow.selectLineKey;
  }

  private get widthList(): number[] {
    return this.$store.state.private.display.importBGMWindow.widthList;
  }

  private get movingIndex(): number {
    return this.$store.state.private.display.importBGMWindow.movingIndex;
  }

  private get startX(): number {
    return this.$store.state.private.display.importBGMWindow.startX;
  }

  private get startLeftWidth(): number {
    return this.$store.state.private.display.importBGMWindow.startLeftWidth;
  }

  private get startRightWidth(): number {
    return this.$store.state.private.display.importBGMWindow.startRightWidth;
  }

  private get colStyle(): any {
    return (index: number) => ({ width: `${this.widthList[index]}px` });
  }
  /* End 列幅可変テーブルのプロパティ */

  private get fadeStr(): Function {
    return (bgmObj: any): string => {
      if (bgmObj.fadeIn > 0 && bgmObj.fadeOut > 0) return "in/out";
      if (bgmObj.fadeIn > 0 && bgmObj.fadeOut === 0) return "in";
      if (bgmObj.fadeIn === 0 && bgmObj.fadeOut > 0) return "out";
      return "-";
    };
  }

  private get fadeTitle(): Function {
    return (bgmObj: any): string => {
      if (bgmObj.fadeIn > 0 && bgmObj.fadeOut > 0)
        return `in:${bgmObj.fadeIn}\nout:${bgmObj.fadeOut}`;
      if (bgmObj.fadeIn > 0 && bgmObj.fadeOut === 0)
        return `in:${bgmObj.fadeIn}`;
      if (bgmObj.fadeIn === 0 && bgmObj.fadeOut > 0)
        return `out:${bgmObj.fadeOut}`;
      return "-";
    };
  }

  private get linkageStr(): Function {
    return (bgmObj: any): string => {
      if (bgmObj.chatLinkage === 1)
        return `【末尾文字】\n${bgmObj.chatLinkageSearch}`;
      if (bgmObj.chatLinkage === 2)
        return `【正規表現】\n${bgmObj.chatLinkageSearch}`;
      return "無";
    };
  }
}
