























































































































































































import WindowFrame from "../WindowFrame.vue";
import WindowMixin from "../WindowMixin.vue";
import CtrlButton from "../parts/CtrlButton.vue";
import CtrlSelect from "../parts/CtrlSelect.vue";
import VolumeComponent from "./component/VolumeComponent.vue";

import { Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { Component, Mixins } from "vue-mixin-decorator";

@Component({
  components: {
    CtrlSelect,
    CtrlButton,
    WindowFrame,
    VolumeComponent
  }
})
export default class EditBGMWindow extends Mixins<WindowMixin>(WindowMixin) {
  private isHideUrl: boolean = true;
  private isYoutube: boolean = false;
  private url: string = "";
  private title: string = "";
  private creditUrl: string = "";
  private tag: string = "";
  private isLoop: boolean = false;
  private fadeIn: number | string = 0;
  private fadeOut: number | string = 0;
  private start: number | string = 0;
  private end: number | string = 0;
  private isMute: boolean = false;
  private volume: number = 0.8;
  private options: any[] = [
    { value: "0", label: "無" },
    { value: "1", label: "末端文字" },
    { value: "2", label: "正則表達式" }
  ];
  private tags: string[] = ["BGM", "SE"];
  private chatLinkage: string = "0";
  private chatLinkageSearch: string = "";
  private forceReset: boolean = true;

  @Action("setProperty") private setProperty: any;
  @Action("windowOpen") private windowOpen: any;
  @Action("windowClose") private windowClose: any;
  @Action("changeListObj") private changeListObj: any;
  @Getter("parseColor") private parseColor: any;

  private initWindow() {
    const key = this.key;
    const bgmObj = this.bgmList.filter((bgmObj: any) => bgmObj.key === key)[0];
    if (!bgmObj) {
      alert("背景音樂已被刪除。");
      this.windowClose("private.display.editBGMWindow");
    }
    this.isHideUrl = true;
    this.url = bgmObj.url;
    this.isYoutube = /www\.youtube\.com/.test(this.url);
    this.title = bgmObj.title;
    this.creditUrl = bgmObj.creditUrl;
    this.tag = bgmObj.tag;
    this.isLoop = bgmObj.isLoop;
    this.fadeIn = bgmObj.fadeIn;
    this.fadeOut = bgmObj.fadeOut;
    this.start = bgmObj.start;
    this.end = bgmObj.end;
    this.isMute = bgmObj.isMute;
    this.volume = Math.floor(parseFloat(bgmObj.volume) * 100) / 100;

    const volumeComponentElm: VolumeComponent = this.$refs
      .volumeComponent as VolumeComponent;
    volumeComponentElm!.setVolume(this.volume);
    this.chatLinkage = String(bgmObj.chatLinkage);
    this.chatLinkageSearch = bgmObj.chatLinkageSearch;
    this.forceReset = bgmObj.forceReset;
  }

  private commit() {
    this.changeListObj({
      key: this.key,
      url: this.url,
      title: this.title,
      creditUrl: this.creditUrl,
      tag: this.tag,
      isLoop: this.isLoop,
      start: parseInt(String(this.start), 10),
      end: parseInt(String(this.end), 10),
      fadeIn: Math.floor(parseFloat(String(this.fadeIn)) * 10) / 10,
      fadeOut: Math.floor(parseFloat(String(this.fadeOut)) * 10) / 10,
      isMute: this.isMute,
      volume: this.volume,
      chatLinkage: parseInt(this.chatLinkage, 10),
      chatLinkageSearch: this.chatLinkageSearch,
      forceReset: this.forceReset
    });
    this.windowClose("private.display.editBGMWindow");
  }

  private cancel() {
    this.windowClose("private.display.editBGMWindow");
  }

  private getCredit() {
    this.creditUrl = this.url.replace(/^(https?:\/\/[^/]+).+$/, "$1");
  }

  private preview() {
    this.setProperty({
      property: "private.display.jukeboxWindow.command",
      logOff: true,
      isNotice: false,
      value: {
        command: "add",
        payload: {
          key: this.key || "",
          url: this.url,
          title: this.title,
          creditUrl: this.creditUrl,
          tag: this.tag,
          isLoop: this.isLoop,
          start: parseInt(String(this.start), 10),
          end: parseInt(String(this.end), 10),
          fadeIn: Math.floor(parseFloat(String(this.fadeIn)) * 10) / 10,
          fadeOut: Math.floor(parseFloat(String(this.fadeOut)) * 10) / 10,
          isMute: this.isMute,
          volume: this.volume,
          chatLinkage: this.chatLinkage,
          chatLinkageSearch: this.chatLinkageSearch,
          forceReset: this.forceReset
        }
      }
    });
  }

  private change(this: any, param: string) {
    this[param] = !this[param];
  }

  private setIsMute(isMute: boolean) {
    this.isMute = isMute;
  }

  private setVolume(volume: string) {
    this.volume = Math.floor(parseFloat(volume) * 100) / 100;
  }

  @Watch("isHideUrl")
  private onChangeIsHideUrl(isHideUrl: boolean) {
    if (!isHideUrl) {
      this.url = "";

      const urlElm: HTMLInputElement = this.$refs.urlElm as HTMLInputElement;
      setTimeout(() => urlElm!.focus(), 0);
    }
  }

  private get key() {
    return this.$store.state.private.display["editBGMWindow"].key;
  }

  private get bgmList() {
    return this.$store.state.public.bgm.list;
  }

  private get chatLinkageOptionInfoList() {
    return this.options.map((optionInfo: any) => ({
      key: optionInfo.value,
      value: optionInfo.value,
      text: optionInfo.label
    }));
  }
}
