var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"character",class:[
    _vm.isThisRolling ? 'rolling' : '',
    _vm.isMoving ? 'moving' : '',
    _vm.isHover ? 'hover' : '',
    _vm.isBorderHide ? 'isBorderHide' : ''
  ],style:(_vm.characterStyle),attrs:{"title":_vm.storeObj.text[0].text,"id":_vm.storeObj.key},on:{"contextmenu":[function($event){$event.preventDefault();return (function (e) { return _vm.openContext(e, 'private.display.characterContext'); })($event)},function($event){$event.preventDefault();}],"mouseover":_vm.mouseover,"mouseout":_vm.mouseout,"dblclick":_vm.dblClick,"mousedown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"])){ return null; }if('button' in $event && $event.button !== 0){ return null; }$event.stopPropagation();return _vm.leftDown($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"right",39,$event.key,["Right","ArrowRight"])){ return null; }if('button' in $event && $event.button !== 2){ return null; }$event.stopPropagation();return _vm.rightDown($event)}],"mouseup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"])){ return null; }if('button' in $event && $event.button !== 0){ return null; }$event.stopPropagation();return _vm.leftUp($event)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"right",39,$event.key,["Right","ArrowRight"])){ return null; }if('button' in $event && $event.button !== 2){ return null; }$event.stopPropagation();return _vm.rightUp($event)}],"touchstart":_vm.leftDown,"touchend":_vm.leftUp,"touchcancel":_vm.leftUp}},[_vm._l((_vm.rangeList),function(range){return _c('range',{key:range.key,attrs:{"type":_vm.type,"objKey":_vm.objKey,"distance":range.distance,"distanceMode":range.distanceMode,"isVision":range.isVision,"color":range.color,"borderColor":range.borderColor,"targetColor":range.targetColor,"lineWidth":range.lineWidth}})}),_c('div',{staticClass:"checkPropertyArea"},_vm._l((_vm.checkPropertyList),function(checkObj,index){return _c('div',{key:index,staticClass:"checkProperty",style:({
        background: ("radial-gradient(\n          circle farthest-side at top left,\n          white 10%,\n          " + (checkObj.color) + " 90%,\n          black 120%\n        )")
      })})}),0),_c('div',{staticClass:"numberPropertyArea"},_vm._l((_vm.numberPropertyList),function(numObj,index){return _c('div',{key:index,staticClass:"numberProperty"},[_c('div',{staticClass:"bar"},[_c('div',{style:({
            backgroundColor: numObj.color,
            width: numObj.ratio
          })})]),_c('div',{staticClass:"value",style:({ color: numObj.fontColor })},[_vm._v("\n        "+_vm._s(numObj.value)+"\n      ")])])}),0),(_vm.isViewHighlight)?_c('div',{staticClass:"selectHighlight"}):_vm._e(),(!_vm.isBorderHide)?_c('div',{staticClass:"border"}):_vm._e(),_c('img',{directives:[{name:"img",rawName:"v-img",value:(_vm.imageObj.data),expression:"imageObj.data"}],staticClass:"image",class:{ reverse: _vm.imageObj.isReverse },attrs:{"draggable":"false"}}),_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.name))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:((_vm.isViewPieceRotateMarker && _vm.isHover) || _vm.isThisRolling),expression:"(isViewPieceRotateMarker && isHover) || isThisRolling"}],staticClass:"rotate",attrs:{"draggable":"false"}},[_c('i',{staticClass:"icon-redo2 roll-knob",on:{"mousedown":function($event){$event.stopPropagation();return _vm.rollStart($event)},"mouseup":function($event){$event.stopPropagation();return _vm.rollEnd($event)},"touchstart":function($event){$event.stopPropagation();return _vm.rollStart($event)},"touchend":function($event){$event.stopPropagation();return _vm.rollEnd($event)},"touchcancel":function($event){$event.stopPropagation();return _vm.rollEnd($event)}}})])],2)}
var staticRenderFns = []

export { render, staticRenderFns }