









import CreateRoomFromRoomData from "./CreateRoomFromRoomData.vue";
import CreateNewRoom from "./CreateNewRoom.vue";
import RoomInfo from "./RoomInfo.vue";
import PlayerInfo from "./PlayerInfo.vue";

import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";

@Component({
  components: {
    CreateRoomFromRoomData,
    CreateNewRoom,
    RoomInfo,
    PlayerInfo
  }
})
export default class Login extends Vue {
  @Getter("isRoomExist") private isRoomExist: any;
  @Getter("isRoomJoined") private isRoomJoined: any;
}
