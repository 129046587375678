














import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SpecFrame extends Vue {
  @Prop({ type: String, required: true })
  private property!: string;

  @Prop({ type: String, required: true })
  private labelStr!: string;

  @Prop({ type: String, required: true })
  private lastVersion!: string;

  @Prop({ type: Boolean, required: true })
  private isSpecFixed!: string;

  private isOpened: boolean = false;

  clickLink(event: any) {
    this.isOpened = !this.isOpened;
    if (this.isOpened) {
      const contentsElm: HTMLElement = document.getElementById(
        "welcomeWindowContents"
      )!;
      const targetElm: HTMLElement | null = contentsElm.querySelector(
        "#" + this.refProp
      );
      if (targetElm) {
        setTimeout(() => {
          contentsElm.scrollTop = targetElm.offsetTop;
        }, 0);
      }
    }
  }

  get refProp() {
    return "ref_" + this.property;
  }

  get version() {
    return this.$store.state.setting.version;
  }
}
