

















































































































import WindowFrame from "../WindowFrame.vue";
import WindowMixin from "../WindowMixin.vue";
import Divider from "../parts/Divider.vue";
import CtrlButton from "@/components/parts/CtrlButton.vue";
import CtrlSelect from "@/components/parts/CtrlSelect.vue";

import { Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { Component, Mixins } from "vue-mixin-decorator";

@Component({
  components: {
    CtrlSelect,
    CtrlButton,
    WindowFrame,
    Divider
  }
})
export default class addGroupChatWindow extends Mixins<WindowMixin>(
  WindowMixin
) {
  @Action("windowClose") private windowClose: any;
  @Action("windowOpen") private windowOpen: any;
  @Action("setProperty") private setProperty: any;
  @Action("changeListObj") private changeListObj: any;
  @Action("addGroupTargetTab") private addGroupTargetTab: any;
  @Getter("getViewName") private getViewName: any;
  @Getter("chatTabs") private chatTabs: any;
  @Getter("playerList") private playerList: any;
  @Getter("getMapObjectList") private getMapObjectList: any;
  @Getter("groupTargetTabList") private groupTargetTabList: any;
  @Getter("getSelfActors") private getSelfActors: any;
  @Getter("chatActorKey") private chatActorKey: any;

  private isSecret: boolean = false;
  private name: string = "";
  private targetTab: string | null = null;
  private isAll: boolean = false;
  private group: any[] = [];

  private initWindow() {
    this.isSecret = false;
    this.name = "";
    this.targetTab = null;
    this.isAll = false;
    this.group = [this.chatActorKey];
  }

  private commit() {
    this.addGroupTargetTab({
      isSecret: this.isSecret,
      name: this.name,
      targetTab: this.targetTab,
      isAll: this.isAll,
      group: this.group
    });
    this.windowClose("private.display.addGroupChatWindow");
  }

  private cancel() {
    this.windowClose("private.display.addGroupChatWindow");
  }

  private moveDev(event: any) {
    if (this.movingIndex > -1) {
      const diff = event.clientX - this.startX;
      const afterLeftWidth = this.startLeftWidth + diff;
      const afterRightWidth = this.startRightWidth - diff;
      if (afterLeftWidth >= 10 && afterRightWidth >= 10) {
        const paramObj: any = {};
        paramObj[this.movingIndex] = afterLeftWidth;
        paramObj[this.movingIndex + 1] = afterRightWidth;
        this.setProperty({
          property: "private.display.addGroupChatWindow.widthList",
          value: paramObj,
          logOff: true
        });
      }
    }
  }

  private moveDevEnd() {
    this.setProperty({
      property: "private.display.addGroupChatWindow",
      value: {
        hoverDevIndex: -1,
        movingIndex: -1,
        startX: -1,
        startLeftWidth: -1,
        startRightWidth: -1
      },
      logOff: true
    });
  }

  private selectLine(selectLineKey: string) {
    this.setProperty({
      property: "private.display.addGroupChatWindow.selectLineKey",
      value: selectLineKey,
      logOff: true
    });
    if (!this.isAll) {
      this.changeTargetCheck(selectLineKey, !this.isContain(selectLineKey));
    }
  }

  private isSelected(groupTargetTab: any, player: any): boolean {
    return groupTargetTab.group.filter((t: string) => t === player.key)[0];
  }

  private changeProp(groupTargetTab: any, prop: string, newValue: any) {
    const target = this.groupTargetTabList.filter(
      (tab: any) => tab.key === groupTargetTab.key
    )[0];
    if (!target) return;
    const index = this.groupTargetTabList.indexOf(target);

    const value: any = {};
    value[prop] = newValue;
    if (prop === "isAll" && newValue) {
      value.group = [];
    }

    this.setProperty({
      property: `public.chat.groupTargetTab.list.${index}`,
      value: value,
      isNotice: true,
      logOff: true
    });
  }

  private changeGroupTargetMember(
    groupTargetTab: any,
    player: any,
    flg: boolean
  ) {
    const newArr = groupTargetTab.group.concat();
    if (flg) {
      // 新增的場合
      newArr.push(player.key);
    } else {
      // 除外的場合
      const index = groupTargetTab.group.indexOf(player.key);
      newArr.splice(index, 1);
    }
    this.changeProp(groupTargetTab, "group", newArr);
  }

  private getViewNames(tab: any) {
    return tab.isAll
      ? "全部人"
      : tab.group.map((g: string) => this.getViewName(g)).join(", ");
  }

  private changeTargetCheck(key: string, value: boolean) {
    if (value) {
      this.group.push(key);
    } else {
      const index = this.group.indexOf(key);
      this.group.splice(index, 1);
    }
  }

  private isContain(key: string) {
    return this.group.filter(g => g === key)[0];
  }

  @Watch("isAll")
  private onChangeIsAll(isAll: boolean) {
    if (isAll) {
      this.group.splice(0, this.group.length);
    }
  }

  /* Start 列幅可変テーブル的プロパティ */
  private get selectLineKey() {
    return this.$store.state.private.display.addGroupChatWindow.selectLineKey;
  }

  private get widthList() {
    return this.$store.state.private.display.addGroupChatWindow.widthList;
  }

  private get movingIndex() {
    return this.$store.state.private.display.addGroupChatWindow.movingIndex;
  }

  private get startX() {
    return this.$store.state.private.display.addGroupChatWindow.startX;
  }

  private get startLeftWidth() {
    return this.$store.state.private.display.addGroupChatWindow.startLeftWidth;
  }

  private get startRightWidth() {
    return this.$store.state.private.display.addGroupChatWindow.startRightWidth;
  }

  private get colStyle() {
    return (index: number) => ({ width: `${this.widthList[index]}px` });
  }
  /* End 列幅可変テーブル的プロパティ */

  private get windowSize() {
    return this.$store.state.private.display.addGroupChatWindow.windowSize;
  }

  private get targetList() {
    const result: any[] = [];
    this.playerList.forEach((player: any) => {
      result.push(player);
      // 破壊的に新增
      result.push.apply(
        result,
        this.getMapObjectList({
          kind: "character",
          place: "field",
          playerKey: player.key
        })
      );
    });
    return result;
  }

  private get targetTabOptionInfoList(): any[] {
    const resultList: any[] = this.chatTabs
      .filter((tab: any) => !tab.isTotal)
      .map((tabObj: any) => ({
        key: tabObj.name,
        value: tabObj.key,
        text: tabObj.name,
        disabled: false
      }));
    resultList.unshift({
      key: "0",
      value: "",
      text: "無指定",
      disabled: false
    });
    return resultList;
  }
}
