















































































































































































































































import MenuBooleanItem from "./MenuBooleanItem.vue";

import { Action, Getter } from "vuex-class";
import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {
    MenuBooleanItem
  }
})
export default class Menu extends Vue {
  @Getter("activeChatTab") private activeChatTab: any;
  @Getter("playerKey") private playerKey: any;
  @Action("sendChatLog") private sendChatLog: any;
  @Action("windowOpen") private windowOpen: any;
  @Action("setProperty") private setProperty: any;
  @Action("doResetWindowLocate") private doResetWindowLocate: any;
  @Action("exportStart") private exportStart: any;
  @Action("addListObj") private addListObj: any;
  @Action("saveChatLogHtml") private saveChatLogHtml: any;
  @Getter("roomName") private roomName: any;
  @Getter("isModal") private isModal: any;
  @Getter("peerId") private peerId: any;
  @Getter("members") private members: any;
  @Getter("isRoomJoined") private isRoomJoined: any;

  private isConnectHover: boolean = false;
  private isSelecting: boolean = false;
  private currentMenu: string = "";

  menuClick(): void {
    this.isSelecting = !this.isSelecting;
  }

  isShow(this: any, ...props: any[]): any {
    return (
      this.isSelecting && props.filter(prop => prop === this.currentMenu)[0]
    );
  }

  menuHover(prop: string): void {
    this.currentMenu = prop;
  }

  hoverConnect(flg: boolean): void {
    this.isConnectHover = flg;
  }

  /** 房間情報鍵押下 */
  clickRoomInfo(): void {
    this.windowOpen("private.display.roomInfoWindow");
  }

  /** 共用筆記鍵押下 */
  clickPublicMemo() {
    this.addListObj({
      propName: "publicMemo",
      kind: "publicMemo",
      targetList: [],
      title: "共用筆記",
      tabList: []
    });
  }

  /** 登出鍵押下 */
  clickLogOut(): void {
    location.href = "https://hktrpg.com";
  }

  /* --------------------
   * 檔案
   * ----------------- */
  /** 儲存 */
  clickExport(): void {
    this.exportStart();
    this.menuClick();
  }

  /** 讀取 */
  clickImport(): void {
    this.setProperty({
      property: "private.display.unSupportWindow.title",
      value: "讀取",
      logOff: true
    });
    this.windowOpen("private.display.unSupportWindow");
    this.menuClick();
  }

  /** チャットログ保存 */
  clickChatLog(): void {
    this.saveChatLogHtml();
    this.menuClick();
  }

  /* --------------------
   * 顯示
   * ----------------- */
  /** 字體大小調整 */
  clickSettingFontSize(): void {
    this.setProperty({
      property: "private.display.unSupportWindow.title",
      value: "字體大小變更",
      logOff: true
    });
    this.windowOpen("private.display.unSupportWindow");
    this.menuClick();
  }

  /** 視窗配置初期化 */
  clickResetWindowLocate(): void {
    this.doResetWindowLocate();
    this.menuClick();
  }

  /* --------------------
   * 方塊
   * ----------------- */
  /** 角色添加 */
  clickAddCharacter(): void {
    this.windowOpen("private.display.addCharacterSettingWindow");
    this.menuClick();
  }

  /** 範圍添加 */
  clickAddRange(): void {
    this.setProperty({
      property: "private.display.unSupportWindow.title",
      value: "範圍添加",
      logOff: true
    });
    this.windowOpen("private.display.unSupportWindow");
    this.menuClick();
  }

  /** チット製作 */
  clickAddChit(): void {
    this.windowOpen("private.display.addChitWindow");
    this.menuClick();
  }

  /** 墓場 */
  clickGraveyard(): void {
    //alert("「墓場」由棋子管理盒畫面整合。");
    this.sendChatLog({
      actorKey: "HKTRPG",
      text: "「墓場」由棋子管理盒畫面整合。",
      chatTarget: this.playerKey,
      statusName: "◆",
      outputTab: this.activeChatTab
    });
    this.menuClick();
  }

  /** 角色等候室 */
  clickWaitingRoom(): void {
    this.sendChatLog({
      actorKey: "HKTRPG",
      text: "「角色等候室」由棋子管理盒畫面整合。",
      chatTarget: this.playerKey,
      statusName: "◆",
      outputTab: this.activeChatTab
    });
    // alert("「角色等候室」由棋子管理盒畫面整合。");
    this.menuClick();
  }

  /* --------------------
   * 地圖
   * ----------------- */
  /** 地圖變更 */
  clickChangeMap(): void {
    this.windowOpen("private.display.editMapWindow");
    this.menuClick();
  }

  /** フロアタイル添加 */
  clickAddFloorTile(): void {
    this.windowOpen("private.display.addFloorTileWindow");
    this.menuClick();
  }

  /** 地圖マスク添加 */
  clickAddMapMask(): void {
    this.windowOpen("private.display.addMapMaskWindow");
    this.menuClick();
  }

  /** 簡易地圖製作 */
  clickCreateEasyMap(): void {
    this.setProperty({
      property: "private.display.unSupportWindow.title",
      value: "簡易地圖",
      logOff: true
    });
    this.windowOpen("private.display.unSupportWindow");
    this.menuClick();
  }

  /** 地圖狀態保存 */
  clickSaveMap(): void {
    this.setProperty({
      property: "private.display.unSupportWindow.title",
      value: "保存地圖",
      logOff: true
    });
    this.windowOpen("private.display.unSupportWindow");
    this.menuClick();
  }

  /** 地圖切換 */
  clickSwitchMap(): void {
    this.setProperty({
      property: "private.display.unSupportWindow.title",
      value: "切換地圖",
      logOff: true
    });
    this.windowOpen("private.display.unSupportWindow");
    this.menuClick();
  }

  /* --------------------
   * 圖片
   * ----------------- */
  /** 檔案アップローダー */
  clickFileUploader(): void {
    this.windowOpen("private.display.fileUploaderWindow");
    this.menuClick();
  }

  /** 標籤編輯 */
  clickTagEdit(): void {
    this.setProperty({
      property: "private.display.unSupportWindow.title",
      value: "編輯圖片標籤",
      logOff: true
    });
    this.windowOpen("private.display.unSupportWindow");
    this.menuClick();
  }

  /** 圖片刪除 */
  clickDeleteImage(): void {
    this.setProperty({
      property: "private.display.unSupportWindow.title",
      value: "刪除圖片",
      logOff: true
    });
    this.windowOpen("private.display.unSupportWindow");
    this.menuClick();
  }

  /* --------------------
   * 說明
   * ----------------- */
  /** ようこそ */
  clickWelcome(): void {
    this.windowOpen("private.display.welcomeWindow");
    this.menuClick();
  }

  /** バージョン */
  clickVersion(): void {
    this.windowOpen("private.display.versionWindow");
    this.menuClick();
  }

  /** 說明書 */
  clickManual(): void {
    this.setProperty({
      property: "private.display.unSupportWindow.title",
      value: "說明書",
      logOff: true
    });
    this.windowOpen("private.display.unSupportWindow");
    this.menuClick();
  }

  /** オフィシャルサイトへ */
  clickOfficialSite(): void {
    window.open("http://quoridorn.com/", "_blank");
    this.menuClick();
  }
  clickHKTRPG(): void {
    window.open("https://www.hktrpg.com/", "_blank");
    this.menuClick();
  }

  /* --------------------
   * Demo
   * ----------------- */
  /** 開發歷史 */
  clickDevHistory(): void {
    this.windowOpen("private.display.devLogWindow");
    this.menuClick();
  }

  /** 錯誤報告 */
  clickBufForm(): void {
    window.open("https://9224.teacup.com/quoridorn_bug/bbs", "_blank");
    this.menuClick();
  }

  get menuStyle(): any {
    const result: any = {};
    if (this.isModal) result.filter = "blur(3px)";
    return result;
  }

  get roomInfoTitle(): string {
    return this.isRoomJoined === true
      ? "您可以看到成員列列表並更改房間設定。"
      : "不在房間裡。 \n通過按「連線」鍵來創建一間房間！！";
  }

  get publicMemoTitle(): string {
    return this.isRoomJoined === true
      ? "點擊此處與成員共享資料"
      : "進入房間前做一些準備是個好主意！？\n你是個好人！";
  }

  get logoutTitle(): string {
    return this.isRoomJoined === true
      ? "你確定要離開房間嗎？"
      : "不在房間裡。 \n通過按「連線」鍵來創建一間房間！！";
  }
}
