



















import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";

@Component
export default class ColorCheckBox extends Vue {
  @Prop({ type: Boolean, default: false })
  private checked!: boolean;

  @Prop({ type: String, default: "" })
  private label!: string;

  @Prop({ type: String, default: "left" })
  private labelSide!: string;

  @Prop({ type: String, default: "#000000" })
  private color!: string;

  @Prop({ type: Boolean, default: false })
  private disabled!: boolean;

  mounted() {
    this.value = this.checked;
  }

  @Emit("change")
  onChange(checked: boolean) {}

  @Watch("checked")
  private onChangeChecked(checked: boolean) {
    this.value = checked;
  }

  private value: boolean = false;
}
