








































import CtrlButton from "@/components/parts/CtrlButton.vue";

import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { execCopy } from "../../common/Utility";

@Component({ components: { CtrlButton } })
export default class RoomInfo extends Vue {
  @Action("getBcdiceSystemInfo") private getBcdiceSystemInfo: any;
  @Getter("roomName") private roomName: any;
  @Getter("roomPassword") private roomPassword: any;
  @Getter("inviteUrl") private inviteUrl: any;
  @Getter("isWait") private isWait: any;
  @Getter("roomSystem") private roomSystem: any;

  private systemName: string = "";

  @Watch("roomSystem", { immediate: true })
  onChangeRoomSystem(roomSystem: string) {
    this.getBcdiceSystemInfo(roomSystem)
      .then((info: any) => {
        this.systemName = info.name;
      })
      .catch((err: any) => {
        window.console.error(err);
        this.systemName = "無法獲得骰子bot的名稱。";
      });
  }

  /**
   * 文字內容をクリップボードに複製是
   * @param event
   */
  doCopy(event: any): void {
    const text = event.target.parentNode.previousElementSibling.value;
    if (!execCopy(text)) {
      alert("無法複製文字。\n" + text);
    }
    alert(text);
  }
}
