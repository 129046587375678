
























import WindowFrame from "../WindowFrame.vue";
import WindowMixin from "../WindowMixin.vue";
import CtrlButton from "@/components/parts/CtrlButton.vue";

import { Action, Getter, Mutation } from "vuex-class";
import { Vue, Watch } from "vue-property-decorator";
import { Component, Mixins } from "vue-mixin-decorator";

@Component({
  components: {
    CtrlButton,
    WindowFrame
  }
})
export default class SecretDiceWindow extends Mixins<WindowMixin>(WindowMixin) {
  @Action("windowOpen") private windowOpen: any;
  @Action("windowClose") private windowClose: any;
  @Action("addChatLog") private addChatLog: any;
  @Mutation("delSecretDice") private delSecretDice: any;
  @Getter("secretDiceList") private secretDiceList: any;

  publish(index: number): void {
    const secretDiceObj = this.secretDiceList[index];
    // 玩家發言
    this.addChatLog({
      name: secretDiceObj.name,
      text: secretDiceObj.text,
      tab: secretDiceObj.tab,
      from: secretDiceObj.from,
      statusName: secretDiceObj.statusName,
      target: secretDiceObj.target,
      owner: secretDiceObj.owner
    });
    // 骰子ロール結果
    this.addChatLog({
      name: secretDiceObj.diceBot,
      text: secretDiceObj.diceRollResult,
      tab: secretDiceObj.tab,
      from: secretDiceObj.from,
      statusName: secretDiceObj.statusName,
      target: secretDiceObj.target,
      owner: secretDiceObj.owner,
      dices: secretDiceObj.dices,
      isDiceBot: true,
      diceBot: secretDiceObj.diceBot
    });
    this.delSecretDice(index);
  }

  createTextAreaValue(secretDiceObj: any): string {
    const text: string[] = [];
    text.push(secretDiceObj.text);
    text.push(`${secretDiceObj.diceBot}：${secretDiceObj.diceRollResult}`);
    return text.join("\n");
  }

  @Watch("secretDiceList")
  onChangeSecretDiceList(secretDiceList: any[]): void {
    // シークレット骰子的結果的リスト的変動を感知して、顯示内容があればこ的畫面を顯示是
    if (secretDiceList.length) {
      this.windowOpen("private.display.secretDiceWindow");
    } else {
      this.windowClose("private.display.secretDiceWindow");
    }
  }
}
