var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"actor-tab-container"},[_c('div',{staticClass:"actor-tabs",class:{ isDiagonal: _vm.isDiagonal },on:{"contextmenu":function($event){$event.preventDefault();}}},[_c('label',{staticClass:"tab addButton"},[_c('self-actor-select',{ref:"actorSelect",attrs:{"selectedActorList":_vm.standActorList},model:{value:(_vm.selectActorKey),callback:function ($$v) {_vm.selectActorKey=$$v},expression:"selectActorKey"}}),_c('span',{staticClass:"icon-arrow-up-right2",class:{ isDiagonal: _vm.isDiagonal },attrs:{"title":"分頁斜め顯示功能"},on:{"click":_vm.diagonalButtonOnClick}})],1),_vm._l((_vm.standActorList),function(actor,index){return _c('label',{key:actor.key,staticClass:"tab",class:{
        active: actor.key === _vm.activeId,
        isDiagonal: _vm.isDiagonal,
        isHover: actor.key === _vm.hoverTabKey,
        isLast: index === _vm.standActorList.length - 1
      }},[(_vm.isDiagonal)?_c('div',{staticClass:"corner-container",on:{"mousedown":function($event){$event.preventDefault();return _vm.selectTab(actor.key)},"mouseenter":function($event){$event.preventDefault();return _vm.hoverTab(actor.key)},"mouseleave":function($event){$event.preventDefault();return _vm.hoverTab('')}}},[_c('div',{staticClass:"corner"})]):_vm._e(),_c('div',{staticClass:"tab-container",on:{"mousedown":function($event){$event.preventDefault();return _vm.selectTab(actor.key)},"mouseenter":function($event){$event.preventDefault();return _vm.hoverTab(actor.key)},"mouseleave":function($event){$event.preventDefault();return _vm.hoverTab('')}}},[(actor.key === _vm.hoverTabKey)?_c('span',{staticClass:"icon-eye-blocked",on:{"click":function($event){$event.stopPropagation();return _vm.delTab(actor.key)}}}):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.getViewName(actor.key)))])])])}),_c('div',{staticClass:"option-tab-container"},_vm._l((_vm.optionTabInfo),function(optionTab,index){return _c('label',{key:index,staticClass:"tab plane-tab",class:{
          active: optionTab.text === _vm.activeId,
          isDiagonal: _vm.isDiagonal,
          isHover: optionTab.text === _vm.hoverTabKey,
          isLast: index === _vm.optionTabInfo.length - 1
        },on:{"click":function($event){return _vm.selectTab(optionTab.text)},"mouseenter":function($event){$event.preventDefault();return _vm.hoverTab(optionTab.text)},"mouseleave":function($event){$event.preventDefault();return _vm.hoverTab('')}}},[_vm._v("\n        "+_vm._s(optionTab.text)+"\n      ")])}),0)],2),_c('div',{staticClass:"container"},[_vm._t("actor",null,{"actor":_vm.actor}),_vm._t("option",null,{"option":_vm.actor ? null : _vm.activeId})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }