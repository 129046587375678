




























import WindowFrame from "../WindowFrame.vue";
import WindowMixin from "../WindowMixin.vue";
import ChatPaletteSettingComponent from "@/components/chat-palette/ChatPaletteSettingComponent.vue";

import { Action, Getter } from "vuex-class";
import { Component, Mixins } from "vue-mixin-decorator";
import CtrlButton from "@/components/parts/CtrlButton.vue";

@Component({
  components: {
    CtrlButton,
    WindowFrame
  }
})
export default class EditChatPaletteWindow extends Mixins<WindowMixin>(
  WindowMixin
) {
  @Action("changeListObj") private changeListObj: any;
  @Action("windowClose") private windowClose: any;
  @Getter("getObj") private getObj: any;

  private text: string = "";

  /**
   * 畫面が開いた時的挙動
   */
  private open() {
    this.text = this.chatPaletteText;
    const input: HTMLTextAreaElement = this.$refs.input as HTMLTextAreaElement;
    input.focus();
  }

  private commit() {
    this.changeListObj({
      key: this.objKey,
      chatPalette: {
        list: this.text.split("\n")
      }
    });
    this.windowClose("private.display.editChatPaletteWindow");
    this.text = "";
  }

  private cancel() {
    this.windowClose("private.display.editChatPaletteWindow");
  }

  private get chatPaletteText(): string {
    const character = this.character;
    return character ? character.chatPalette.list.join("\n") : "";
  }

  private get character() {
    return this.getObj(this.objKey);
  }

  private get objKey() {
    return this.$store.state.private.display["editChatPaletteWindow"].objKey;
  }
}
