export default {
  state: {
    command: null,
    isDisplay: false,
    zIndex: 1,
    standImageList: []
  },
  actions: {},
  mutations: {},
  getters: {}
};
