<template>
  <div class="sourceContainer">
    ※ 以下省略敬語
    <ul class="type">
      <li>
        <span class="unSelectable">【特別協力】</span>
        <ul class="detail">
          <li>
            <div>
              kanko
              <span class="unSelectable">
                (
                <a target="_blank" href="https://twitter.com/kanko_oo">
                  on <span class="icon-twitter"></span> Twitter
                </a>
                )
              </span>
            </div>
            <ul>
              <li>
                <span class="unSelectable">LOGO製作</span>
              </li>
              <li>
                <span class="unSelectable">Favicon製作</span>
              </li>
              <li>
                <span class="unSelectable">
                  畫面設計及製作（歡迎畫面 - 使用說明）
                </span>
              </li>
              <li>
                <span class="unSelectable">
                  登錄的用戶流程設計
                </span>
              </li>
              <li>
                <span class="unSelectable">
                  裝飾設計（ 歡迎畫面- 登錄）
                </span>
              </li>
              <li>
                <span class="unSelectable">
                  繪畫方面的設計建議（玩家）
                </span>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        <span class="unSelectable">【圖片関係】</span>
        <ul class="detail illustration">
          <li>
            <div>
              渡空燕丸
              <span class="unSelectable">
                (
                <a target="_blank" href="https://twitter.com/karasuma_kojiro">
                  on
                  <span class="icon-twitter"></span>
                  Twitter
                </a>
                )
              </span>
            </div>
            <ul>
              <li>
                <span class="unSelectable">
                  設計和製作吉祥物角色
                </span>
              </li>
              <li>
                <span class="unSelectable">
                  設計和製作吉祥物角色擬人化人物
                </span>
              </li>
            </ul>
            <span style="font-size: 90%; color: red;">
              角色及素材在Quoridorn之外的使用： 如果很明顯用於Quoridorn的廣告和
              <br />出於非商業目的，我們會接受。 <br />如果發現惡意複製或使用
              <br />將立即收取插圖的使用費用
            </span>
            <br />
            <span style="font-size: 90%;">
              角色及素材的版權為
              <br />Quoridorn開發者HillTop擁有。
            </span>
          </li>
          <li>
            <div>
              <span class="unSelectable">默認地圖背景</span>
            </div>
            <div>
              <span class="unSelectable">網站名稱：</span>
              TEDDY-PLAZA
              <span class="unSelectable">
                (
                <a target="_blank" href="http://teddy-plaza.sakura.ne.jp">
                  LINK
                </a>
                )
              </span>
            </div>
            <div>
              <span class="unSelectable">作品名：</span>
              「麦畑と村」
              <span class="unSelectable">
                (
                <a
                  target="_blank"
                  href="http://teddy-plaza.sakura.ne.jp/?cat=105"
                >
                  LINK
                </a>
                )
              </span>
            </div>
          </li>
          <li>
            <div>
              <span class="unSelectable">Typing icon</span>
            </div>
            <div>
              <span class="unSelectable">網站名稱：</span>
              みんな的知識 ちょっと便利帳
              <span class="unSelectable">
                (
                <a target="_blank" href="https://www.benricho.org/">
                  LINK
                </a>
                )
              </span>
            </div>
            <div>
              <span class="unSelectable">作品名：</span>
              「不明」
              <span class="unSelectable">
                (
                <a
                  target="_blank"
                  href="https://www.benricho.org/loading_images/transparent01.html"
                >
                  LINK
                </a>
                )
              </span>
            </div>
          </li>
          <!--
          <li>
            <div><span class="unSelectable">favicon製作</span></div>
            <div><span class="unSelectable">網站名稱：</span>Free logo Maker - Supalogo.com<span class="unSelectable"> (<a target="_blank" href="https://supalogo.com/#">LINK</a>)</span></div>
            <div>
              <span class="unSelectable">設定：</span>
              <ul>
                <li>
                  <span class="unSelectable">text: </span>Q
                </li>
                <li>
                  <span class="unSelectable">SPECIFY THE EFFECT TO APPLY: </span>Gradient colors #1e90ff #00ffff
                </li>
                <li>
                  <span class="unSelectable">SPECIFY A FONT FOR YOUR LOGO: </span>Facon(new)
                </li>
                <li>
                  <span class="unSelectable">size: </span>21px
                </li>
              </ul>
            </div>
          </li>
          -->
        </ul>
      </li>
      <li>
        <span class="unSelectable">【卡片】</span>
        <ul class="detail">
          <li>
            <div>
              <span class="unSelectable">
                トランプ (タロットカード小アルカナ)
              </span>
            </div>
            <div>
              <span class="unSelectable">網站名稱：</span>
              Wikipedia Commons
              <span class="unSelectable">
                (
                <a target="_blank" href="https://commons.wikimedia.org">
                  LINK
                </a>
                )
              </span>
            </div>
            <div>
              <span class="unSelectable">参考列表：</span>
              <ul>
                <li>
                  English pattern playing cards deck
                  <span class="unSelectable">
                    (
                    <a
                      target="_blank"
                      href="https://commons.wikimedia.org/wiki/File:English_pattern_playing_cards_deck.svg"
                    >
                      LINK
                    </a>
                    )
                  </span>
                </li>
                <li>
                  Joker black 02
                  <span class="unSelectable">
                    (
                    <a
                      target="_blank"
                      href="https://commons.wikimedia.org/wiki/File:Joker_black_02.svg"
                    >
                      LINK
                    </a>
                    )
                  </span>
                </li>
                <li>
                  Joker red 02
                  <span class="unSelectable">
                    (
                    <a
                      target="_blank"
                      href="https://commons.wikimedia.org/wiki/File:Joker_red_02.svg"
                    >
                      LINK
                    </a>
                    )
                  </span>
                </li>
                <li>
                  Carta Francese retro Rosso
                  <span class="unSelectable">
                    (
                    <a
                      target="_blank"
                      href="https://commons.wikimedia.org/wiki/File:Carta_Francese_retro_Rosso.jpg"
                    >
                      LINK
                    </a>
                    )
                  </span>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <div>
              <span class="unSelectable">卡牌</span>
            </div>
            <div>
              <span class="unSelectable">網站名稱：</span>
              pixiv
              <span class="unSelectable">
                (
                <a target="_blank" href="https://www.pixiv.net/">
                  LINK
                </a>
                )
              </span>
            </div>
            <div>
              <span class="unSelectable">作者：</span>
              メタボルタ
            </div>
            <div>
              <span class="unSelectable">作品名：</span>
              「花札＠どどんとふ」
              <span class="unSelectable">
                (
                <a
                  target="_blank"
                  href="https://www.pixiv.net/member_illust.php?mode=medium&illust_id=52572048"
                >
                  LINK1
                </a>
                /
                <a
                  target="_blank"
                  href="https://www.pixiv.net/member_illust.php?mode=medium&illust_id=52619352"
                >
                  LINK2
                </a>
                )
              </span>
            </div>
          </li>
          <li>
            <div>
              <span class="unSelectable">タロットカード 大アルカナ</span>
            </div>
            <div>
              <span class="unSelectable">網站名稱：</span>
              flickr
              <span class="unSelectable">
                (
                <a target="_blank" href="https://www.flickr.com">
                  LINK
                </a>
                )
              </span>
            </div>
            <div><span class="unSelectable">作者：</span>Sigo Paolini</div>
            <div>
              <span class="unSelectable">作品名：</span>
              「Napo Tarot」
              <span class="unSelectable">
                (
                <a
                  target="_blank"
                  href="https://www.flickr.com/photos/thegreathorsecigar/sets/72157634257174286"
                >
                  LINK
                </a>
                )
              </span>
            </div>
          </li>
        </ul>
      </li>
      <li>
        <span class="unSelectable">【音楽】</span>
        <ul class="detail">
          <li>
            <div>
              <span class="unSelectable">効果音</span>
            </div>
            <div>
              <span class="unSelectable">網站名稱：</span>
              効果音ラボ
              <span class="unSelectable">
                (
                <a target="_blank" href="https://soundeffect-lab.info/">
                  LINK
                </a>
                )
              </span>
            </div>
            <div>
              <span class="unSelectable">作品名：</span>
              「出題1」など
              <span class="unSelectable">
                (
                <a
                  target="_blank"
                  href="https://soundeffect-lab.info/sound/anime/"
                >
                  LINK
                </a>
                )
              </span>
            </div>
          </li>
          <li>
            <div>
              <span class="unSelectable">BGM</span>
            </div>
            <div>
              <span class="unSelectable">網站名稱：</span>
              H/MIX GALLARY Free Music Download Site
              <span class="unSelectable">
                (
                <a target="_blank" href="http://www.hmix.net/">
                  LINK
                </a>
                )
              </span>
            </div>
            <div>
              <span class="unSelectable">作品名：</span>
              「馬車道」など
              <span class="unSelectable">
                (
                <a
                  target="_blank"
                  href="http://www.hmix.net/music_gallery/feeling/happy.htm"
                >
                  LINK
                </a>
                )
              </span>
            </div>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
export default {};
</script>

<style scoped src="./source.css"></style>
