

























































































































































import WindowFrame from "../WindowFrame.vue";
import WindowMixin from "../WindowMixin.vue";
import CtrlButton from "@/components/parts/CtrlButton.vue";
import SurfaceComponent from "@/components/public-memo/SurfaceComponent.vue";

import { Action, Getter } from "vuex-class";
import { Component, Mixins } from "vue-mixin-decorator";

@Component({ components: { CtrlButton, WindowFrame, SurfaceComponent } })
export default class PublicMemoWindow extends Mixins<WindowMixin>(WindowMixin) {
  @Action("windowOpen") windowOpen: any;
  @Action("setProperty") setProperty: any;
  @Action("changeListObj") changeListObj: any;
  @Action("changePublicMemoObj") changePublicMemoObj: any;
  @Action("windowClose") windowClose: any;
  @Getter("publicMemo") publicMemo: any;
  @Getter("playerKey") playerKey: any;

  private currentTabIndex: number = 0;
  private isFront: boolean = true;
  private hoverMenuItemIndex: number = -1;
  private hoverMenuImageIndex: number = -1;
  private imageDeletable: boolean = true;
  private hoverMenuX: number = -1;
  private hoverMenuY: number = -1;
  private isPreview: boolean = false;
  private editingPublicMemoData: any = null;

  private open() {
    this.currentTabIndex = 0;
    this.isFront = true;
    this.hoverMenuItemIndex = -1;
    this.hoverMenuImageIndex = -1;
    this.imageDeletable = true;
    this.hoverMenuX = -1;
    this.hoverMenuY = -1;
    this.isPreview = false;
    this.editingPublicMemoData = null;
    setTimeout(() => {
      this.editingPublicMemoData = JSON.parse(
        JSON.stringify(this.publicMemoObj)
      );

      setTimeout(() => {
        const surfaceElm: SurfaceComponent = this.$refs
          .surfaceElm as SurfaceComponent;
        if (surfaceElm) surfaceElm.requestFocus();
        else {
          const input: HTMLElement = this.$refs.input as HTMLElement;
          if (input) input.focus();
        }
      });
    });
  }

  private deleteTab() {
    const tabList: any[] = this.usePublicMemoObj.tabList;
    const tabName: string = tabList[this.currentTabIndex].tabName;
    const msg: string = `你真的要刪除${tabName}？`;
    setTimeout(() => {
      if (window.confirm(msg)) {
        tabList.splice(this.currentTabIndex, 1);
      }
    });
  }

  private copyTab() {
    const tabList: any[] = this.usePublicMemoObj.tabList;
    tabList.push(JSON.parse(JSON.stringify(tabList[this.currentTabIndex])));
  }

  private moveTabOnClick(isLeft: boolean) {
    const tabList: any[] = this.usePublicMemoObj.tabList;
    if (isLeft && this.currentTabIndex === 0) return;
    if (!isLeft && this.currentTabIndex === tabList.length - 1) return;
    const tabObj: any = tabList.splice(this.currentTabIndex, 1)[0];
    const afterIndex = this.currentTabIndex + (isLeft ? -1 : 1);
    tabList.splice(afterIndex, 0, tabObj);
    this.currentTabIndex = afterIndex;
  }

  private addTab(event: any) {
    this.usePublicMemoObj.tabList.push({
      tabName: event.target.value,
      front: {
        targetList: [],
        contentsList: [
          {
            kind: "text",
            text: "文字內容"
          }
        ]
      },
      back: {
        targetList: [],
        contentsList: []
      }
    });
    event.target.value = "";
    setTimeout(() => {
      this.currentTabIndex = this.usePublicMemoObj.tabList.length - 1;
    });
  }

  /**
   * タブが押下された時
   * @param index
   */
  private tabOnClick(index: number) {
    this.currentTabIndex = index;
  }

  /**
   * 表裏タブが押下された時
   * @param isFront
   */
  private surfaceTabOnClick(isFront: boolean) {
    this.isFront = isFront;
  }

  private addButtonOnClick() {
    // TODO
  }

  private checkOnChange(checked: boolean, itemIndex: number) {
    if (this.isPreview) return;
    const tabList: any = {};
    const tabObj: any = (tabList[this.currentTabIndex] = {});
    const surfaceObj: any = (tabObj[this.isFront ? "front" : "back"] = {});
    const contentsList: any = (surfaceObj.contentsList = {});
    const checkObj: any = (contentsList[itemIndex] = {});
    checkObj.checked = checked;
    this.changeListObj({
      key: this.objKey,
      tabList
    });
  }

  private commitButtonOnClick() {
    // ルームメイトにも反映是
    this.changeListObj(this.usePublicMemoObj);

    // 畫面を關閉
    this.windowClose("private.display.publicMemoWindow");
  }

  private previewButtonOnClick() {
    if (
      !this.isFront &&
      this.useSurfaceObj &&
      this.useSurfaceObj.contentsList.length === 0
    ) {
      this.isFront = true;
    }
    this.isPreview = true;
  }

  private editButtonOnClick() {
    this.isPreview = false;
  }

  private cancelButtonOnClick() {
    this.windowClose("private.display.publicMemoWindow");
  }

  /**
   * 文字內容エリアに輸入される度に、必要に応じて文字內容エリア的顯示大小を拡張是
   */
  private textareaOnInput(event: any) {
    const textarea: HTMLTextAreaElement = event.target as HTMLTextAreaElement;
    if (textarea.scrollHeight > textarea.offsetHeight) {
      textarea.style.height = textarea.scrollHeight + "px";
    }
  }

  /**
   * 項目設定菜單を開く
   */
  private surfaceItemConfigOnOpen(
    index: number,
    hoverMenuX: number,
    hoverMenuY: number
  ) {
    this.hoverMenuItemIndex = index;
    this.hoverMenuImageIndex = -1;
    this.hoverMenuX = hoverMenuX;
    this.hoverMenuY = hoverMenuY;
  }

  /**
   * 圖片設定菜單を開く
   */
  private surfaceImageConfigOnOpen(
    itemIndex: number,
    imageIndex: number,
    imageListSize: number,
    hoverMenuX: number,
    hoverMenuY: number
  ) {
    this.hoverMenuItemIndex = itemIndex;
    this.hoverMenuImageIndex = imageIndex;
    this.hoverMenuX = hoverMenuX;
    this.hoverMenuY = hoverMenuY;
    this.imageDeletable = imageListSize > 1;
  }

  private surfaceLastConfigOnOpen(hoverMenuX: number, hoverMenuY: number) {
    this.hoverMenuItemIndex = -2;
    this.hoverMenuImageIndex = -1;
    this.hoverMenuX = hoverMenuX;
    this.hoverMenuY = hoverMenuY;
  }

  /**
   * 設定菜單を關閉
   */
  private configOnClose() {
    this.hoverMenuItemIndex = -1;
    this.hoverMenuImageIndex = -1;
  }

  /**
   * 標題新增が押下された時
   */
  private insertTitleItemOnClick() {
    this.insertContents({
      kind: "title",
      text: "標題"
    });
  }

  /**
   * サブ標題新增が押下された時
   */
  private insertSubTitleItemOnClick() {
    this.insertContents({
      kind: "sub-title",
      text: "副標題"
    });
  }

  /**
   * 文字內容新增が押下された時
   */
  private insertTextItemOnClick() {
    this.insertContents({
      kind: "text",
      text: "文字內容"
    });
  }

  private insertCheckboxItemOnClick() {
    this.insertContents({
      kind: "checkbox",
      text: "檢查項目"
    });
  }

  /**
   * 区切り線新增が押下された時
   */
  private insertHrItemOnClick() {
    this.insertContents({
      kind: "separator"
    });
  }

  /**
   * 圖片フレーム新增が押下された時
   */
  private insertImageFrameItemOnClick() {
    this.insertContents({
      kind: "images",
      imageKeyList: [{ key: "image-0", tag: "(全部)" }]
    });
  }

  private insertContents(insertObj: any) {
    // window.console.log(
    //   "insertContents",
    //   this.hoverMenuItemIndex,
    //   insertObj,
    //   this.useSurfaceObj.contentsList
    // );
    const contentsList: any[] = this.useSurfaceObj.contentsList;
    if (this.hoverMenuItemIndex === -2) {
      contentsList.push(insertObj);
    } else {
      contentsList.splice(this.hoverMenuItemIndex, 0, insertObj);
    }
    this.configOnClose();
  }

  /**
   * 項目刪除が押下された時
   */
  private deleteItemOnClick() {
    const contentsList: any[] = this.useSurfaceObj.contentsList;
    contentsList.splice(this.hoverMenuItemIndex - 1, 1);
    this.configOnClose();
  }

  /**
   * 圖片新增が押下された時
   */
  private insertImageOnClick() {
    this.useSurfaceObj.contentsList[
      this.hoverMenuItemIndex
    ].imageKeyList.splice(this.hoverMenuImageIndex, 0, {
      key: "image-0",
      tag: "(全部)"
    });
    this.configOnClose();
  }

  /**
   * 圖片刪除が押下された時
   */
  private deleteImageOnClick() {
    if (!this.imageDeletable) return;
    this.useSurfaceObj.contentsList[
      this.hoverMenuItemIndex
    ].imageKeyList.splice(this.hoverMenuImageIndex, 1);
    this.configOnClose();
  }

  private get isEditMode(): boolean {
    return this.$store.state.private.display.publicMemoWindow.isEditMode;
  }

  private get objKey(): string {
    return this.$store.state.private.display.publicMemoWindow.objKey;
  }

  /**
   * 共用筆記資料
   */
  private get publicMemoObj(): any {
    return this.publicMemo.list.filter(
      (publicMemo: any) => publicMemo.key === this.objKey
    )[0];
  }

  private get usePublicMemoObj(): any {
    return this.isEditMode ? this.editingPublicMemoData : this.publicMemoObj;
  }

  /**
   * 使うタブ的リスト
   */
  private get usePublicMemoTabList(): any[] {
    return this.usePublicMemoObj ? this.usePublicMemoObj.tabList : [];
  }

  private get usePublicMemoTabObj(): any {
    return this.usePublicMemoTabList[this.currentTabIndex];
  }

  private get useSurfaceObj(): any {
    const usePublicMemoObj: any = this.isEditMode
      ? this.editingPublicMemoData
      : this.publicMemoObj;
    if (!usePublicMemoObj) return null;
    if (!usePublicMemoObj.tabList[this.currentTabIndex]) return null;
    return this.isFront
      ? usePublicMemoObj.tabList[this.currentTabIndex].front
      : usePublicMemoObj.tabList[this.currentTabIndex].back;
  }
}
