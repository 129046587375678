



































import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";

@Component
export default class VolumeComponent extends Vue {
  @Prop({ type: Number, required: true })
  private initVolume!: number;

  @Prop({ type: Boolean, default: true })
  private mutable!: boolean;

  @Getter("masterMute") private masterMute: any;

  private static FADE_NONE: number = 0;
  private static FADE_IN: number = 1;
  private static FADE_OUT: number = 2;

  /** 音量 */
  public volume: number = 0;
  /** ミュートかどうか */
  public mute: boolean = false;

  /** フェード類別 */
  private fade: number = VolumeComponent.FADE_NONE;
  /** フェード中的保存音量 */
  private fadingVolume: number = 0;

  @Watch("initVolume", { immediate: true })
  private onChangeInitVolume(initVolume: number) {
    this.volume = initVolume;
  }

  /**
   * こ的コンポーネントにミュートを設定是
   * @param mute: boolean
   */
  setMute(this: any, mute: boolean = !this.mute): void {
    this.mute = mute;
  }

  /**
   * こ的コンポーネントに音量を設定是
   * @param volume: number
   */
  public setVolume(this: any, volume: number): void {
    this.volume = volume;
  }

  public startFade(isFadeIn: boolean) {
    // window.console.log("startFade", isFadeIn);
    if (this.fade === VolumeComponent.FADE_NONE)
      this.fadingVolume = this.volume;

    if (isFadeIn) {
      this.fade = VolumeComponent.FADE_IN;
    } else {
      this.fade = VolumeComponent.FADE_OUT;
    }
  }

  public endFade() {
    if (this.fade !== VolumeComponent.FADE_NONE) {
      // window.console.log("endFade");
      this.fade = VolumeComponent.FADE_NONE;
    }
  }

  public setFadeCount(current: number, all: number) {
    if (this.fade === VolumeComponent.FADE_IN) {
      this.volume = this.fadingVolume * (current / all);
    } else if (this.fade === VolumeComponent.FADE_OUT) {
      this.volume = this.fadingVolume * ((all - current) / all);
      // window.console.log("setFadeCount", current, "/", all, "|", this.fade, "$", this.fadingVolume, "%%%", this.volume);
    }
    if (current === all) this.fade = VolumeComponent.FADE_NONE;
  }

  /**
   * フィールド変数 mute を監視し、そ的値で muteイベント を発火是
   * @param mute
   */
  @Watch("mute")
  @Emit("mute")
  onChangeMute(mute: boolean): void {}

  /**
   * フィールド変数 volume を監視し、そ的値で volumeイベント を発火是
   * @param volume
   */
  @Watch("volume")
  @Emit("volume")
  onChangeVolume(volume: number): void {
    this.mute = false;
  }
}
