




























































































import CtrlButton from "../../parts/CtrlButton.vue";
import WindowFrame from "../../WindowFrame.vue";
import WindowMixin from "../../WindowMixin.vue";

import { Action, Getter } from "vuex-class";
import { Component, Mixins } from "vue-mixin-decorator";

@Component({
  components: {
    CtrlButton,
    WindowFrame
  }
})
export default class EditMapMaskWindow extends Mixins<WindowMixin>(
  WindowMixin
) {
  @Action("changeListObj") private changeListObj: any;
  @Action("windowClose") private windowClose: any;
  @Getter("parseColor") private parseColor: any;
  @Getter("getObj") private getObj: any;
  @Getter("currentMap") private currentMap: any;

  private name: string = "";
  private width: number = 1;
  private height: number = 1;
  private color: string = "#000000";
  private transparency: number = 0;

  private commitEdit() {
    this.changeListObj({
      key: this.key,
      name: this.name,
      columns: this.width,
      rows: this.height,
      color: this.rgba,
      fontColor: this.fontColor,
      isNotice: true
    });
    this.windowClose("private.display.editMapMaskWindow");
  }

  private cancelEdit() {
    this.windowClose("private.display.editMapMaskWindow");
  }

  private initWindow() {
    // window.console.log(`initWindow`)
    let mapMaskObj = this.getObj(this.key);
    this.name = mapMaskObj.name;
    this.width = mapMaskObj.columns;
    this.height = mapMaskObj.rows;
    const colorObj = this.parseColor(mapMaskObj.color);
    this.color = colorObj.getColorCode();
    this.transparency = 100 - Math.floor(colorObj.a * 100);
    window.console.log(
      `  [methods] init window => EditMapMask:{name:"${this.name}", color:${
        this.color
      }, size:(${this.width}, ${this.height}), transparency:${
        this.transparency
      }}`
    );
  }

  private get key() {
    return this.$store.state.private.display["editMapMaskWindow"].key;
  }

  private get mapMaskStyle() {
    let width = this.width * this.gridSize;
    let height = this.height * this.gridSize;
    let zoom = 1;
    if (Math.max(width, height) > 160) {
      zoom = 160 / Math.max(width, height);
      width *= zoom;
      height *= zoom;
    }
    return {
      width: width + "px",
      height: height + "px",
      "background-color": this.rgba,
      color: this.fontColor
    };
  }

  private get rgba() {
    const colorObj = this.parseColor(this.color);
    colorObj.a = (100 - this.transparency) / 100;
    return colorObj.getRGBA();
  }

  private get fontColor() {
    return this.parseColor(this.color).getColorCodeReverse();
  }

  private get gridSize() {
    return this.currentMap.grid.size;
  }
}
