var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vue-slider',{attrs:{"width":'100%',"dotSize":10,"min":0,"max":100,"use-keyboard":true,"piecewiseLabel":true,"enableCross":false,"tooltip":"always","tooltipDir":'top',"formatter":"{value}%","mergeFormatter":"{value1}% ~ {value2}%","tooltipStyle":{
    backgroundColor: '#666666',
    borderColor: '#666666',
    fontSize: '1em'
  },"bgStyle":{
    backgroundColor: '#aadddd',
    borderTop: '1px solid #666666',
    borderBottom: '1px solid #666666',
    borderRadius: 0
  }},on:{"contextmenu":function($event){$event.preventDefault();}},scopedSlots:_vm._u([{key:"label",fn:function(ref){
  var label = ref.label;
  var active = ref.active;
  var index = ref.index;
return [(index % 10 === 0)?_c('span',{class:['custom-label', { active: active }, { line: index % 50 !== 0 }]},[_vm._v("\n      "+_vm._s(index % 50 === 0 ? label : "")+"\n    ")]):_vm._e()]}}]),model:{value:(_vm.localValue),callback:function ($$v) {_vm.localValue=$$v},expression:"localValue"}})}
var staticRenderFns = []

export { render, staticRenderFns }