












































import WindowFrame from "../WindowFrame.vue";
import WindowMixin from "../WindowMixin.vue";
import ChatPaletteSettingComponent from "@/components/chat-palette/ChatPaletteSettingComponent.vue";

import { Action, Getter } from "vuex-class";
import { Component, Mixins } from "vue-mixin-decorator";
import CtrlSelect from "@/components/parts/CtrlSelect.vue";
import { Watch } from "vue-property-decorator";
import DiceBotSelect from "@/components/parts/select/DiceBotSelect.vue";

@Component({
  components: {
    DiceBotSelect,
    CtrlSelect,
    ChatPaletteSettingComponent,
    WindowFrame
  }
})
export default class ChatPaletteSettingWindow extends Mixins<WindowMixin>(
  WindowMixin
) {
  private columns: string = "1";
  private currentDiceBotSystem: string = "DiceBot";

  private open() {
    const refs: ChatPaletteSettingComponent[] = this.$refs
      .component as ChatPaletteSettingComponent[];
    if (!refs || !refs[0]) return;
    refs[0].requestFocus();
  }

  private get fixSize() {
    const columnNum = parseInt(this.columns, 10);

    const width = columnNum * 329 + 16;

    return `${width}, 540`;
  }
}
