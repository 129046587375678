<template>
  <div class="devSupport">
    對於那些打算籌捐支持HillTop 開發Quoridorn的人<br />
    <a
      target="_blank"
      href="https://www.amazon.co.jp/Amazon%E3%82%AE%E3%83%95%E3%83%88%E5%88%B8-E%E3%83%A1%E3%83%BC%E3%83%AB%E3%82%BF%E3%82%A4%E3%83%97/dp/BT00DHI8G4"
    >
      Amazon禮券
    </a>
    請發送到以下電子郵件地址。
    <br />
    quoridorn@gmail.com
    <br /><br />
    發展支助金用於促進和維持有效的開發。 <br />
    以下是到目前為止的用法。
    <ul>
      <li>
        送貨上門（比薩餅）
        <br />→節省家務時間
      </li>
      <li>
        購買電熱器具
        <br />→ 預防慢性眼疲勞，消耗性眼罩無法滿足
      </li>
      <li>
        購買蔬菜和果汁
        <br />→保持營養平衡，不然會導致日常飲食習慣出現偏差，從而縮短工作時間
      </li>
      <li>
        WebStorm購買費用
        <br />→非常方便且易於開發
      </li>
    </ul>
  </div>
</template>

<script>
export default {};
</script>

<style scoped src="./devSupport.css"></style>
