






















































































































































































































































































































































































































import WindowMixin from "../WindowMixin.vue";
import WindowFrame from "../WindowFrame.vue";
import Divider from "../parts/Divider.vue";
import ColorCheckBox from "@/components/parts/ColorCheckBox.vue";
import CtrlButton from "@/components/parts/CtrlButton.vue";

import { Vue, Watch } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import { sum } from "@/components/common/Utility";
import { Component, Mixins } from "vue-mixin-decorator";

@Component({
  components: {
    CtrlButton,
    WindowFrame,
    Divider,
    ColorCheckBox
  }
})
export default class InitiativeWindow extends Mixins<WindowMixin>(WindowMixin) {
  @Action("setProperty") private setProperty: any;
  @Action("windowOpen") private windowOpen: any;
  @Action("changeListObj") private changeListObj: any;
  @Getter("getMapObjectList") private getMapObjectList: any;
  @Getter("getObj") private getObj: any;
  @Getter("round") private round: any;
  @Getter("roundPlayerKey") private roundPlayerKey: any;
  @Getter("propertyList") private propertyList: any;
  @Getter("isModal") private isModal: any;

  @Getter("activeChatTab") private activeChatTab: any;
  @Getter("playerKey") private playerKey: any;
  @Action("sendChatLog") private sendChatLog: any;

  private windowWidth: number = 0;
  private windowPadding: number = 0;
  private isWindowViewScroll: boolean = false;

  private get titleText(): string {
    const initiateCharacter: any = this.getObj(this.roundPlayerKey);
    if (this.round === 0 || !initiateCharacter) return "先攻表";
    const initiative: number = initiateCharacter.property.initiative;
    return `回合：${this.round}／先攻表：${initiative}`;
  }

  private openSettingWindow() {
    this.windowOpen("private.display.initiativeSettingWindow");
  }

  private selectLine(selectLineKey: string | null) {
    this.setProperty({
      property: "private.display.initiativeWindow.selectLineKey",
      value: selectLineKey,
      logOff: true
    });
    if (selectLineKey) {
      this.changeListObj({
        key: selectLineKey,
        isNotice: false,
        viewHighlight: true
      });
    }
  }

  private moveDev(event: any) {
    if (this.movingIndex < 0) return;
    const diff = event.clientX - this.startX;
    const afterLeftWidth = this.startLeftWidth + diff;
    if (afterLeftWidth < 10) return;
    this.widthList.splice(this.movingIndex, 1, afterLeftWidth);

    const sumWidth = sum(this.widthList) + this.widthList.length - 1;

    const minWidth = 30;
    const lastWidth =
      this.windowWidth -
      this.windowPadding -
      (sumWidth - this.widthList[this.widthList.length - 1]);
    this.isWindowViewScroll = lastWidth < minWidth;
    this.widthList.splice(
      this.widthList.length - 1,
      1,
      Math.max(lastWidth, minWidth)
    );
  }

  private doubleClick() {
    window.console.log("doubleClick", this.movedIndex);

    const TEXT_PADDING = 8;
    const NUMBER_PADDING = 20;
    const CHECK_BOX_WIDTH = 20;

    let maxWidth = this.getWidth("aaa");
    if (this.movedIndex === 0) {
      // 順序
      maxWidth = this.getWidth("順序");
    }
    if (this.movedIndex === 1) {
      // 先攻表
      maxWidth = this.getWidth("0") + NUMBER_PADDING;
      this.characterList.forEach((character: any) => {
        maxWidth = Math.max(
          maxWidth,
          this.getWidth(character.property.initiative) + NUMBER_PADDING
        );
      });
    }
    if (this.movedIndex === 2) {
      // 修正（先攻表同値時）
      maxWidth = this.getWidth("0") + NUMBER_PADDING;
      this.characterList.forEach((character: any) => {
        maxWidth = Math.max(
          maxWidth,
          this.getWidth(character.property.subInitiative) + NUMBER_PADDING
        );
      });
    }
    if (this.movedIndex === 3) {
      // 名稱
      maxWidth = this.getWidth("名稱") + TEXT_PADDING;
      this.characterList.forEach((character: any) => {
        maxWidth = Math.max(
          maxWidth,
          this.getWidth(character.name) + TEXT_PADDING
        );
      });
    }
    if (this.movedIndex > 3) {
      const formatIndex = this.movedIndex - 4;
      const prop = this.propertyList[formatIndex];
      switch (prop.type) {
        case "min":
        case "number":
        case "max":
          this.characterList.forEach((character: any) => {
            maxWidth = Math.max(
              maxWidth,
              this.getWidth(character.property[prop.refStr]) + NUMBER_PADDING
            );
          });
          break;
        case "checkbox":
          maxWidth = Math.max(maxWidth, CHECK_BOX_WIDTH);
          break;
        default:
          maxWidth = this.getWidth(prop.property) + TEXT_PADDING;
      }
    }
    this.widthList.splice(this.movedIndex, 1, maxWidth);

    const sumWidth = sum(this.widthList) + this.widthList.length - 1;
    const lastWidth = Math.max(
      this.windowWidth -
        this.windowPadding -
        (sumWidth - this.widthList[this.widthList.length - 1]),
      this.getWidth("其他")
    );

    this.widthList.splice(this.widthList.length - 1, 1, lastWidth);

    this.moveDevEnd();
  }

  private moveDevEnd() {
    this.setProperty({
      property: "private.display.initiativeWindow",
      value: {
        hoverDevIndex: -1,
        movingIndex: -1,
        startX: -1,
        startLeftWidth: -1,
        startRightWidth: -1
      },
      logOff: true
    });
  }

  private get characterList() {
    return this.getMapObjectList({ kind: "character" });
  }

  /* Start 列幅可変テーブルのプロパティ */
  private get selectLineKey(this: any) {
    return this.$store.state.private.display.initiativeWindow.selectLineKey;
  }

  private get widthList(this: any) {
    return this.$store.state.private.display.initiativeWindow.widthList;
  }

  private get movingIndex(this: any) {
    return this.$store.state.private.display.initiativeWindow.movingIndex;
  }

  private get movedIndex(this: any) {
    return this.$store.state.private.display.initiativeWindow.movedIndex;
  }

  private get startX(this: any) {
    return this.$store.state.private.display.initiativeWindow.startX;
  }

  private get startLeftWidth(this: any) {
    return this.$store.state.private.display.initiativeWindow.startLeftWidth;
  }

  private get baseWindowWidth(this: any) {
    return this.$store.state.private.display.initiativeWindow.baseWindowWidth;
  }

  private get startRightWidth(this: any) {
    return this.$store.state.private.display.initiativeWindow.startRightWidth;
  }

  private get colStyle() {
    return (index: number) => ({
      width: `${this.widthList[index]}px`
      // display: "inline-block"
    });
  }
  /* End 列幅可変テーブルのプロパティ */

  /**
   * 指定された文字列の顯示幅を取得する
   * @param text
   */
  private getWidth(text: string): number {
    const widthScale: HTMLSpanElement = this.$refs
      .widthScale! as HTMLSpanElement;
    widthScale.innerText = text;
    const width: number = widthScale.offsetWidth;
    widthScale.innerText = "";
    return width;
  }

  @Watch("propertyList", { deep: true })
  private onChangeDataLabels(propertyList: any[]) {
    this.characterList.forEach((character: any) => {
      propertyList.forEach((prop: any, index: number) => {
        let newValue: any = null;
        let propName: string = prop.refStr;
        if (prop.type === "min") {
          newValue = 0;
        } else if (prop.type === "max") {
          newValue = 99;
        } else if (prop.type === "number") {
          if (prop.min > 0) newValue = prop.min;
          else if (prop.max < 0) newValue = prop.max;
          else newValue = 0;
        } else if (prop.type === "checkbox") {
          newValue = false;
        } else {
          newValue = null;
        }
        if (character.property[propName] === undefined) {
          // character.property[propName] = newValue;
          Vue.set(character.property, propName, newValue);
        }
      });
    });
  }

  @Watch("windowWidth", { immediate: true })
  private onChangeWindowWidth(windowWidth: number, oldWidth: number) {
    const sumWidth = sum(this.widthList) + this.widthList.length - 1;
    if (oldWidth === 0 && !this.windowPadding) {
      this.windowPadding = windowWidth - sumWidth;
      return;
    }
    if (!oldWidth || !windowWidth) return;
    const minWidth = 30;
    const lastWidth =
      windowWidth -
      this.windowPadding -
      (sumWidth - this.widthList[this.widthList.length - 1]);
    this.isWindowViewScroll = lastWidth < minWidth;
    this.widthList.splice(
      this.widthList.length - 1,
      1,
      Math.max(lastWidth, minWidth)
    );
  }

  private onChangeWindowStyle(windowStyle: any) {
    const widthMatchResult: string[] = windowStyle.width.match(/^[0-9]+/);
    if (widthMatchResult) {
      this.windowWidth = parseInt(widthMatchResult[0], 10);
    }
  }

  private arrangeWindowSize(isWide: boolean) {
    const windowFrame: WindowFrame = this.$refs.window as WindowFrame;
    const width = this.windowWidth;
    if (!isWide) {
      const newWidth: number = this.baseWindowWidth;
      const diff: number = newWidth - width;
      windowFrame.windowFactor.w += diff;
    } else {
      this.setProperty({
        property: "private.display.initiativeWindow.baseWindowWidth",
        value: width,
        logOff: true
      });
      const newWidth: number =
        sum(this.widthList) + this.widthList.length - 1 + this.windowPadding;
      const diff: number = newWidth - width;
      windowFrame.windowFactor.w += diff;
    }
  }

  private setInitiative(objKey: string, value: number) {
    this.changeListObj({
      key: objKey,
      isNotice: true,
      property: {
        initiative: value
      }
    });
  }

  private setSubInitiative(objKey: string, value: number) {
    this.changeListObj({
      key: objKey,
      isNotice: true,
      property: {
        subInitiative: value
      }
    });
  }

  private setMin(objKey: string, index: number, value: number) {
    const propertyObj: any = {};
    propertyObj[this.propertyList[index].refStr] = value;
    this.changeListObj({
      key: objKey,
      isNotice: true,
      property: propertyObj
    });
  }

  private arrangeMin(this: any, objKey: string, index: number, value: number) {
    if (this.getPropValue(objKey, index + 1) < value) {
      const propertyObj: any = {};
      propertyObj[this.propertyList[index + 1].refStr] = value;
      this.changeListObj({
        key: objKey,
        isNotice: true,
        property: propertyObj
      });
    }
  }

  private setMax(objKey: string, index: number, value: number) {
    // window.console.log("setMax", index, value);
    const propertyObj: any = {};
    propertyObj[this.propertyList[index].refStr] = value;
    this.changeListObj({
      key: objKey,
      isNotice: true,
      property: propertyObj
    });
  }

  private arrangeMax(this: any, objKey: string, index: number, value: number) {
    if (value < this.getPropValue(objKey, index - 1)) {
      const propertyObj: any = {};
      propertyObj[this.propertyList[index - 1].refStr] = value;
      this.changeListObj({
        key: objKey,
        isNotice: true,
        property: propertyObj
      });
    }
  }

  private setPropValue(objKey: string, index: number, value: any) {
    const prop: any = this.propertyList[index];

    const propertyObj: any = {};
    propertyObj[prop.property] = value;

    window.console.log(objKey, prop.property, value);

    this.changeListObj({
      key: objKey,
      isNotice: true,
      property: propertyObj
    });
  }

  private getMin(objKey: string, index: number): number | null {
    const prevProp: any = this.propertyList[index - 1];
    if (!prevProp || prevProp.type !== "min") {
      return this.propertyList[index].min || 0;
    } else {
      // 別プロパティ値を返却
      return this.getPropValue(objKey, index - 1) as number;
    }
  }

  private getMax(objKey: string, index: number): number | null {
    const nextProp: any = this.propertyList[index + 1];
    if (!nextProp || nextProp.type !== "max") {
      return this.propertyList[index].max || 99;
    } else {
      // 別プロパティ値を返却
      return this.getPropValue(objKey, index + 1) as number;
    }
  }

  private getInitiative(objKey: string) {
    return this.getObj(objKey).property.initiative;
  }

  private getSubInitiative(objKey: string) {
    return this.getObj(objKey).property.subInitiative;
  }

  private getPropValue(objKey: string, index: number): number | boolean | null {
    return this.getObj(objKey).property[this.propertyList[index].refStr];
  }

  private get sortCharacterList(): any[] {
    return this.characterList
      .concat()
      .filter((c: any) => c.place === "field" && !c.isHide)
      .sort((char1: any, char2: any) => {
        const prop1: any = char1.property;
        const prop2: any = char2.property;

        if (prop1.initiative > prop2.initiative) return -1;
        if (prop1.initiative < prop2.initiative) return 1;
        if (prop1.subInitiative > prop2.subInitiative) return -1;
        if (prop1.subInitiative < prop2.subInitiative) return 1;
        return 0;
      });
  }

  private get backDisabled(): boolean {
    const index: number = this.sortCharacterList.findIndex(
      (character: any) => character.key === this.roundPlayerKey
    );
    return index === 0 && this.round === 1;
  }

  private roundBack() {
    if (!this.roundPlayerKey) return;
    const index: number = this.sortCharacterList.findIndex(
      (character: any) => character.key === this.roundPlayerKey
    );
    const isPrevRound: boolean = index === 0;
    this.updateInitiative(
      isPrevRound ? this.round - 1 : this.round,
      this.sortCharacterList[
        isPrevRound ? this.sortCharacterList.length - 1 : index - 1
      ].key
    );
  }

  private roundNext() {
    if (!this.roundPlayerKey) return;
    const index: number = this.sortCharacterList.findIndex(
      (character: any) => character.key === this.roundPlayerKey
    );
    const isNextRound: boolean = index === this.sortCharacterList.length - 1;
    this.updateInitiative(
      isNextRound ? this.round + 1 : this.round,
      this.sortCharacterList[isNextRound ? 0 : index + 1].key
    );
  }

  private battleStart() {
    const character = this.sortCharacterList[0];
    this.updateInitiative(character ? 1 : 0, character ? character.key : null);
  }

  private battleEnd() {
    this.updateInitiative(0, null);
  }

  private updateInitiative(round: number, roundPlayerKey: string | null) {
    this.setProperty({
      property: "public.initiative",
      value: { round, roundPlayerKey },
      isNotice: true,
      logOff: true
    });
    this.selectLine(roundPlayerKey);
  }

  private editButtonOnClick() {
    this.sendChatLog({
      actorKey: "HKTRPG",
      text: "未實裝此功能。",
      chatTarget: this.playerKey,
      statusName: "◆",
      outputTab: this.activeChatTab
    });
    // alert("未實裝此功能。");
  }
}
