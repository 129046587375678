








































































import WindowMixin from "../WindowMixin.vue";
import ActorTabComponent from "@/components/parts/tab-component/ActorTabComponent.vue";
import CtrlButton from "@/components/parts/CtrlButton.vue";

import { Action, Getter } from "vuex-class";
import { Component, Mixins } from "vue-mixin-decorator";
import ChatTabSelect from "@/components/parts/select/ChatTabSelect.vue";
import ActorStatusSelect from "@/components/parts/select/ActorStatusSelect.vue";
import { Prop, Watch } from "vue-property-decorator";
import DiceBotSelect from "@/components/parts/select/DiceBotSelect.vue";
import GroupChatTabSelect from "@/components/parts/select/GroupChatTabSelect.vue";
import { saveJson } from "@/components/common/Utility";

@Component({
  components: {
    GroupChatTabSelect,
    DiceBotSelect,
    ActorStatusSelect,
    ChatTabSelect,
    CtrlButton,
    ActorTabComponent
  }
})
export default class ChatPaletteSettingWindow extends Mixins<WindowMixin>(
  WindowMixin
) {
  @Prop({ type: String, required: true })
  private currentDiceBotSystem!: string;

  @Action("sendChatLog") private sendChatLog: any;
  @Action("windowOpen") private windowOpen: any;
  @Action("setProperty") private setProperty: any;
  @Getter("getViewName") private getViewName: any;
  @Getter("getObj") private getObj: any;

  private actorKey: string = "";
  private chatTemporarily: string = "";
  private selectedIndex: number = -1;
  private sendTab: string = "";
  private statusName: string = "◆";
  /** 發言先 */
  private chatTarget: string = "groupTargetTab-0";

  public requestFocus(): void {
    const elm: ActorTabComponent = this.$refs.tab as ActorTabComponent;
    elm.requestFocus();
  }

  private changeActor(actorKey: string): void {
    this.actorKey = actorKey;
  }

  @Watch("actorKey", { deep: true, immediate: true })
  private onChangeActorKey(actorKey: any) {
    const actor: any = this.getObj(actorKey);
    if (!actor) return;
    const status: any = actor.statusList.filter(
      (status: any) => status.name === this.statusName
    )[0];
    if (!status) {
      this.statusName = "◆";
    }
  }

  /**
   * 對話組合版的１行が送出された時的挙動
   * @param actor
   * @param text
   */
  private sendLine(actor: any, text: string | undefined) {
    if (!text) {
      text = this.chatTemporarily;
    }
    this.sendChatLog({
      actorKey: actor.key,
      text,
      outputTab: this.sendTab ? this.sendTab : undefined,
      statusName: this.statusName,
      chatTarget: this.chatTarget,
      currentDiceBotSystem: this.currentDiceBotSystem
    });
    this.chatTemporarily = "";
  }

  /**
   * 對話組合版的１行が選択された時的挙動
   * @param actor
   * @param index
   */
  private selectLine(actor: any, index: number) {
    this.chatTemporarily = actor.chatPalette.list[index];
    this.selectedIndex = index;
  }

  /**
   * 對話組合版を編輯是
   * @param actor
   */
  private edit(actor: any) {
    this.setProperty({
      property: "private.display.editChatPaletteWindow.objKey",
      value: actor.key,
      logOff: true
    }).then(() => {
      this.windowOpen("private.display.editChatPaletteWindow");
    });
  }

  /**
   * 對話組合版を儲存是
   * @param actor
   */
  private doExport(actor: any) {
    const data = {
      saveData: {
        lines: actor.chatPalette.list
      },
      saveDataTypeName: "Quoridorn_ChatPalette01"
    };
    saveJson(`聊天組合板_${actor.name}`, data);
  }

  /**
   * 對話組合版を讀取是
   * @param actor
   */
  private doImport(actor: any) {
    this.setProperty({
      property: "private.display.importChatPaletteWindow.objKey",
      value: actor.key,
      logOff: true
    }).then(() => {
      this.windowOpen("private.display.importChatPaletteWindow");
    });
  }
}
