<template>
  <spec-frame
    property="fileUploadSpec"
    label-str="上傳檔案"
    lastVersion="1.0.0"
    :isSpecFixed="true"
  >
    <!----------------------
     ! 操作方法
     !--------------------->
    <spec-item class-str="operation" label-str="操作方法">
      <li>畫面上方菜單的「圖片」選擇</li>
      <li>「檔案上傳」選擇</li>
    </spec-item>
    <!----------------------
     ! 規格
     !--------------------->
    <spec-item class-str="spec" label-str="規格">
      <li>
        以下的情報を付与して圖片ファイルを利用可能に是
        <ol>
          <li>標籤</li>
          <li>隱藏圖片密碼</li>
        </ol>
      </li>
      <li>
        支援是拡張子は以下的通り
        <ol>
          <li>png</li>
          <li>jpg(jpeg)</li>
          <li>gif(アニメーション可能)</li>
        </ol>
        容量制限は無（軽い圖片に否と通信的に悪影響が出るかもしれないけど技術的には可能な的で制限は設けない）
      </li>
      <li>
        ファイル名に "_"(アンダースコア) が２つ連続で含まれている場合、
        それ以降的 "_" で区切られた部分を「圖片に付与した情報」として処理是。
        <br />
        立ち絵的ベース圖片が選ばれた際、ファイル名がベース圖片的ファイル名で始まる圖片ファイルを全て探し出し、
        差分圖片として設定是。<br />
        差分圖片的設定で利用される各情報は、こ的「圖片に付与した情報」があれば、そこから値を利用是。<br />
        <br />
        例）下記的圖片が讀取されていたと是。<br />
        圖片①：base-image.png<br />
        圖片②：base-image_eye__0_30_40_70_40_15_65.png<br />
        圖片③：base-image_mouth.png<br />
        圖片④：other.png<br />
        <br />
        立ち絵設定において、圖片①をベース圖片に選擇した場合、
        圖片②と圖片③が差分圖片として自動で設定される。<br />
        ※ 圖片④は無視される<br />
        圖片②的ファイル名は "__" が含まれているため、それ以降的 "_"
        で区切られている7つ的数値が「圖片に付与した情報」として取得できる。<br />
        「圖片に付与した情報」は利用用途に応じて順番に意味を持っており、今回的立ち絵的場合は以下的意味になる。<br />
        1番目：圖片処理方法(0: 重ねる, 1:置換)<br />
        2番目：X座標(通常)<br />
        3番目：Y座標(通常)<br />
        4番目：X座標(反転時)<br />
        5番目：Y座標(反転時)<br />
        6番目：顯示時間(開始%) 0~100<br />
        7番目：顯示時間(完結%) 0~100<br />
        圖片②的場合、アニメーション周期的15%~65%的時に描画される。<br />
        反転しなければ(30px, 40px), 反転した場合は(70px,
        40px)的位置に、ベース圖片に対して重ねる方法で描画されるという意味になる。<br />
        圖片③的場合、「圖片に付与した情報」が存在否ため、上記的7つ的情報は全てデフォルト値が利用される。<br />
        なお、立ち絵差分以外的機能で「圖片に付与した情報」を利用是昨日は現時点では存在否。
      </li>
      <li>
        「差分圖片」は「本体圖片」的ファイルと同じ圖片大小であること。<br />
        「差分圖片」は「本体圖片」と重ねて顯示是が、顯示領域に合わせて伸縮させる的で、大小が合ってないと変な顯示になる。
      </li>
    </spec-item>
    <!----------------------
     ! 用戶要求
     !--------------------->
    <spec-item class-str="request" label-str="用戶要求">
      <li>無</li>
    </spec-item>
    <!----------------------
     ! 未實裝項目
     !--------------------->
    <spec-item class-str="unImplemented" label-str="未實裝項目">
      <li>本機能自体が未實裝<br />→ 初回リリースに含める。</li>
    </spec-item>
    <!----------------------
     ! 未實現的功能
     !--------------------->
    <spec-item class-str="unSupport" label-str="未實現的功能">
      <li>無</li>
    </spec-item>
    <!----------------------
     ! 相關項目
     !--------------------->
    <spec-item class-str="ref" label-str="相關項目">
      <li>
        <spec-link property="menuBar" title-str="">主菜單</spec-link>
      </li>
    </spec-item>
  </spec-frame>
</template>

<script>
import SpecMixin from "./SpecMixin";
export default { mixins: [SpecMixin] };
</script>

<style scoped src="./spec.css"></style>
