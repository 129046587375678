export default {
  /** 共用筆記 */
  state: {
    maxKey: 0,
    list: []
  },
  actions: {},
  mutations: {},
  getters: {
    publicMemo: (state: any) => state
  }
};
