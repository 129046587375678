














import { Getter } from "vuex-class";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component
export default class SubBlockTitle extends Vue {
  @Prop({ type: String, required: true })
  private text!: string;

  @Getter("paramRoomName") private paramRoomName: any;
  @Getter("isRoomExist") private isRoomExist: any;

  @Emit("open")
  onClickLink(): void {}
}
