






















































import WindowFrame from "../WindowFrame.vue";
import WindowMixin from "../WindowMixin.vue";

import BGMYoutubeComponent from "@/components/music/component/BGMYoutubeComponent.vue";
import MasterVolumeComponent from "@/components/music/component/MasterVolumeComponent.vue";
import BGMFileComponent from "@/components/music/component/BGMFileComponent.vue";

import { Action, Getter } from "vuex-class";
import { Component, Mixins } from "vue-mixin-decorator";
import { Watch } from "vue-property-decorator";

@Component({
  components: {
    WindowFrame,
    BGMFileComponent,
    BGMYoutubeComponent,
    MasterVolumeComponent
  }
})
export default class JukeboxWindow extends Mixins<WindowMixin>(WindowMixin) {
  @Action("windowClose") private windowClose: any;
  @Action("windowOpen") private windowOpen: any;
  @Getter("bgmList") private bgmList: any;

  private playList: any[] = [];

  private add(addBgmObj: any): void {
    if (!addBgmObj) return;

    // 強制リスタートじゃなければ、もともと播放されてる曲を中断しない。
    if (!addBgmObj.isReject && !addBgmObj.forceReset) {
      const bgmObj: any = this.playList.filter(
        (bgmObj: any) => bgmObj.key === addBgmObj.key
      )[0];
      if (bgmObj) {
        return;
      }
    }

    // 標籤が同じものはプレイリストから刪除する
    const delList = this.playList.filter(plObj => {
      const bgmObj = this.bgmList.filter(
        (bgmObj: any) => bgmObj.key === plObj.key
      )[0];
      if (!bgmObj) {
        // 見つからなかったらタイミング悪く刪除されたということなので、刪除リストに新增
        return true;
      }
      return addBgmObj.tag === bgmObj.tag;
    });
    delList.forEach(delObj => {
      const index = this.playList.indexOf(delObj);
      this.playList.splice(index, 1);
    });

    // 新增処理
    if (addBgmObj.url !== "") {
      setTimeout(() => {
        this.playList.unshift(addBgmObj);
        this.windowOpen("private.display.jukeboxWindow");
      }, 0);
    } else {
      if (this.playList.length === 0) {
        this.windowClose("private.display.jukeboxWindow");
      }
    }
  }

  private remove(bgmKey: string): void {
    const index = this.playList.findIndex(
      (bgmObj: any) => bgmObj.key === bgmKey
    );
    this.playList.splice(index, 1);

    if (this.playList.length === 0) {
      this.windowClose("private.display.jukeboxWindow");
    }
  }
}
