import saveAs from "file-saver";
import moment from "moment";
import * as Mustache from "mustache";

export default {
  actions: {
    /** ========================================================================
     * HTML形式で聊天視窗ログをダウン讀取是
     * @param rootGetters
     */
    async saveChatLogHtml({ rootGetters }: { rootGetters: any }) {
      if (!rootGetters.isGameMaster) {
        alert(
          "由於對標准的考慮，這是GM的獨有功能。 \n我們將盡快進行修改。 \n請由GM獲取紀錄。"
        );
        return;
      }
      const dateStr = moment().format("YYYYMMDD_HHmmss");
      const title = `Quoridorn_chatLog_${dateStr}`;

      const func: Function = (list: any[]): string =>
        JSON.stringify(
          list.map((obj: any) =>
            rootGetters.encrypt({ planeText: JSON.stringify(obj) })
          )
        );

      const data = {
        chatLogs: func(rootGetters.chatLogs),
        actors: func(rootGetters.getAllActors),
        groupTargetTabList: func(rootGetters.groupTargetTabList),
        chatTabList: func(rootGetters.chatTabList),
        title,
        mode: "dev"
      };

      return (
        Promise.resolve()
          // テンプレートファイル読み込み
          .then(() =>
            fetch(`${process.env.BASE_URL}/static/chatLogTemplate.html`).then(
              res => res.text()
            )
          )

          // テンプレートエンジン
          .then((templateStr: string) => Mustache.render(templateStr, data))

          // HTML出力
          .then((contents: string) => {
            const blob = new Blob([contents], { type: "text/html" });
            saveAs(blob, `${title}.html`);
          })

          // 錯誤処理
          .catch((err: any) => window.console.error(err))
      );
    }
  }
};
