import { render, staticRenderFns } from "./DiceSpec.vue?vue&type=template&id=621dee07&scoped=true&"
import script from "./DiceSpec.vue?vue&type=script&lang=js&"
export * from "./DiceSpec.vue?vue&type=script&lang=js&"
import style0 from "./spec.css?vue&type=style&index=0&id=621dee07&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "621dee07",
  null
  
)

export default component.exports