




















































































































import WindowFrame from "../../WindowFrame.vue";
import WindowMixin from "../../WindowMixin.vue";

import { Action, Getter } from "vuex-class";
import { Component, Mixins } from "vue-mixin-decorator";

@Component({
  components: {
    WindowFrame
  }
})
export default class AddMapMaskWindow extends Mixins<WindowMixin>(WindowMixin) {
  @Action("windowActive") private windowActive: any;
  @Action("windowClose") private windowClose: any;
  @Getter("parseColor") private parseColor: any;
  @Getter("currentMap") private currentMap: any;

  private transparency: number = 0;
  private width: number = 1;
  private height: number = 1;
  private name: string = "";
  private isMulti: boolean = true;
  private color: string = "#000000";

  private open() {
    const input: HTMLInputElement = this.$refs.input as HTMLInputElement;
    input.focus();
  }

  private dragStart(event: any): void {
    event.dataTransfer.setData("kind", "mapMask");
    event.dataTransfer.setData("name", this.name);
    event.dataTransfer.setData("color", this.rgba);
    event.dataTransfer.setData("fontColor", this.fontColor);
    event.dataTransfer.setData("columns", this.width);
    event.dataTransfer.setData("rows", this.height);
    event.dataTransfer.setData("isMulti", this.isMulti);
    window.console.log(
      `  [methods] drag start mapMask => {name:"${this.name}", color:${
        this.color
      }, size:(${this.width}, ${this.height}), transparency:${
        this.transparency
      }`
    );
  }

  private get mapMaskStyle() {
    let width = this.width * this.gridSize;
    let height = this.height * this.gridSize;
    let zoom = 1;
    if (Math.max(width, height) > 160) {
      zoom = 160 / Math.max(width, height);
      width *= zoom;
      height *= zoom;
    }
    let result = {
      width: width + "px",
      height: height + "px",
      "background-color": this.rgba,
      color: this.fontColor
    };
    return result;
  }

  private get rgba() {
    const colorObj = this.parseColor(this.color);
    colorObj.a = (100 - this.transparency) / 100;
    return colorObj.getRGBA();
  }

  private get fontColor() {
    return this.parseColor(this.color).getColorCodeReverse();
  }

  private get gridSize() {
    return this.currentMap.grid.size;
  }
}
