























import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";

@Component({})
export default class Logo extends Vue {
  @Getter("version") private version: any;
}
