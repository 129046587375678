















































import Divider from "../../parts/Divider.vue";
import CtrlButton from "@/components/parts/CtrlButton.vue";

import { Action, Getter } from "vuex-class";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component({ components: { CtrlButton, Divider } })
export default class SettingBgmTrComponent extends Vue {
  @Action("setProperty") setProperty: any;
  @Action("windowOpen") windowOpen: any;
  @Action("addListObj") addListObj: any;
  @Action("deleteListObj") deleteListObj: any;
  @Action("moveListObj") moveListObj: any;
  @Getter("bgmList") bgmList: any;
  @Getter("isGameMaster") private isGameMaster: any;
  @Getter("magicWord") private magicWord: any;
  @Getter("encrypt") encrypt: any;

  @Prop({ type: Object, required: true })
  private bgmObj!: any;

  @Prop({ type: String, required: true })
  private windowParam!: string;

  @Emit("dblclick")
  private doDblClick() {}

  private isYoutube(url: string) {
    return /www\.youtube\.com/.test(url);
  }

  private isDropBox(url: string) {
    return /dropbox/.test(url);
  }

  private selectLine(bgmKey: string): void {
    this.setProperty({
      property: `private.display.${this.windowParam}.selectLineKey`,
      value: bgmKey,
      logOff: true
    });
  }

  private get convertSecond(): Function {
    return (start: number, end: number): string => {
      if (start && end) return `${start}〜${end}`;
      if (start) return `${start}〜`;
      if (end) return `〜${end}`;
      return "All";
    };
  }

  /* Start 列幅可変テーブル的プロパティ */
  private get selectLineKey(): string {
    return this.$store.state.private.display[this.windowParam].selectLineKey;
  }

  private get widthList(): number[] {
    return this.$store.state.private.display[this.windowParam].widthList;
  }

  private get movingIndex(): number {
    return this.$store.state.private.display[this.windowParam].movingIndex;
  }

  private get startX(): number {
    return this.$store.state.private.display[this.windowParam].startX;
  }

  private get startLeftWidth(): number {
    return this.$store.state.private.display[this.windowParam].startLeftWidth;
  }

  private get startRightWidth(): number {
    return this.$store.state.private.display[this.windowParam].startRightWidth;
  }

  private get colStyle(): any {
    return (index: number) => ({ width: `${this.widthList[index]}px` });
  }
  /* End 列幅可変テーブル的プロパティ */

  private get fadeStr(): Function {
    return (bgmObj: any): string => {
      if (bgmObj.fadeIn > 0 && bgmObj.fadeOut > 0) return "in/out";
      if (bgmObj.fadeIn > 0 && bgmObj.fadeOut === 0) return "in";
      if (bgmObj.fadeIn === 0 && bgmObj.fadeOut > 0) return "out";
      return "-";
    };
  }

  private get fadeTitle(): Function {
    return (bgmObj: any): string => {
      if (bgmObj.fadeIn > 0 && bgmObj.fadeOut > 0)
        return `in:${bgmObj.fadeIn}\nout:${bgmObj.fadeOut}`;
      if (bgmObj.fadeIn > 0 && bgmObj.fadeOut === 0)
        return `in:${bgmObj.fadeIn}`;
      if (bgmObj.fadeIn === 0 && bgmObj.fadeOut > 0)
        return `out:${bgmObj.fadeOut}`;
      return "-";
    };
  }

  private get linkageStr(): Function {
    return (bgmObj: any): string => {
      if (bgmObj.chatLinkage === 1)
        return `【末尾文字】\n${bgmObj.chatLinkageSearch}`;
      if (bgmObj.chatLinkage === 2)
        return `【正規表現】\n${bgmObj.chatLinkageSearch}`;
      return "無";
    };
  }
}
