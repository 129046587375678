export default {
  /** 棋子 */
  state: {
    list: [],
    maxKey: -1
  },
  actions: {},
  mutations: {},
  getters: {
    chitList: (state: any): any[] => state.list
  }
};
