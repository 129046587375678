






















import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class CtrlFileSelector extends Vue {
  @Prop({ type: Boolean, default: false })
  private disabled!: boolean;

  public requestFocus() {
    const input: HTMLInputElement = this.$refs.input as HTMLInputElement;
    input.focus();
  }

  @Emit("change")
  private fileOnChange(event: any) {}
}
