



















import SelectMixin from "./SelectMixin";
import CtrlSelect from "@/components/parts/CtrlSelect.vue";

import { Prop, Watch } from "vue-property-decorator";
import { Component, Mixins } from "vue-mixin-decorator";

@Component({ components: { CtrlSelect } })
export default class SelectBase extends Mixins<SelectMixin>(SelectMixin) {
  @Prop({ type: Boolean, default: false })
  private disabled!: boolean;

  @Prop({ type: Array, required: true })
  private optionInfoList!: any[];
}
