





















































import DiceBotSelect from "@/components/parts/select/DiceBotSelect.vue";
import WindowFrame from "../WindowFrame.vue";
import WindowMixin from "../WindowMixin.vue";

import { Action, Getter } from "vuex-class";
import { Component, Mixins } from "vue-mixin-decorator";

@Component({
  components: {
    WindowFrame,
    DiceBotSelect
  }
})
export default class RoomInfoWindow extends Mixins<WindowMixin>(WindowMixin) {
  @Action("setProperty") private setProperty: any;
  @Getter("roomName") private roomName: any;
  @Getter("playerKey") private playerKey: any;
  @Getter("playerList") private playerList: any;
  @Getter("inviteUrl") private inviteUrl: any;
  @Getter("roomSystem") private roomSystem: any;

  get currentDiceBotSystem(): string {
    return this.roomSystem;
  }

  set currentDiceBotSystem(currentDiceBotSystem: string) {
    this.setProperty({
      property: `public.room.system`,
      value: currentDiceBotSystem,
      isNotice: true,
      logOff: true
    });
  }

  createURL(player: any) {
    return `${this.inviteUrl}&playerName=${player.name}`;
  }
}
