








































































import WindowMixin from "../WindowMixin.vue";
import WindowFrame from "../WindowFrame.vue";
import CtrlButton from "@/components/parts/CtrlButton.vue";

import { Action, Getter } from "vuex-class";
import { Component, Mixins } from "vue-mixin-decorator";
import { listDelete } from "@/components/common/Utility";

@Component({
  components: {
    CtrlButton,
    WindowFrame
  }
})
export default class CounterRemoconWindow extends Mixins<WindowMixin>(
  WindowMixin
) {
  @Action("windowOpen") private windowOpen: any;
  @Action("addChatLog") private addChatLog: any;
  @Action("changeListObj") private changeListObj: any;
  @Action("sendBcdiceServer") private sendBcdiceServer: any;
  @Action("setProperty") private setProperty: any;
  @Getter("publicCounterRemoconList") private publicCounterRemoconList: any;
  @Getter("getMapObjectList") private getMapObjectList: any;
  @Getter("getObj") private getObj: any;
  @Getter("chatActorKey") private chatActorKey: any;
  @Getter("propertyList") private propertyList: any;
  @Getter("activeChatTab") private activeChatTab: any;
  @Getter("playerKey") private playerKey: any;
  @Action("sendChatLog") private sendChatLog: any;

  private selectBlockList: any[] = [];

  private open() {
    const button: CtrlButton[] = this.$refs.button as CtrlButton[];
    if (!button || !button[0]) return;
    button[0].requestFocus();
  }

  /**
   * 保存を行う
   */
  private saveButtonOnClick() {
    // TODO
    this.sendChatLog({
      actorKey: "HKTRPG",
      text: "未實裝此功能。",
      chatTarget: this.playerKey,
      statusName: "◆",
      outputTab: this.activeChatTab
    });
    // alert("未實裝此功能。");
  }

  /**
   * 読み込みを行う
   */
  private loadButtonOnClick() {
    // TODO
    //    alert("未實裝此功能。");
    this.sendChatLog({
      actorKey: "HKTRPG",
      text: "未實裝此功能。",
      chatTarget: this.playerKey,
      statusName: "◆",
      outputTab: this.activeChatTab
    });
  }

  /**
   * 累計遙控器鍵を新增是
   */
  private addButtonOnClick() {
    this.setProperty({
      property: `private.display.counterRemoconEditorWindow.objKey`,
      value: null,
      isNotice: false,
      logOff: true
    });
    this.windowOpen("private.display.counterRemoconEditorWindow");
  }

  /**
   * 累計遙控器的菜單を顯示是
   * @param event
   * @param remoconKey
   */
  private openContext(event: any, remoconKey: any): void {
    // ブロックを初期化
    this.selectBlockList = [];

    let pageX = event.pageX;
    let pageY = event.pageY;

    const contextProperty: string = "private.display.counterRemoconContext";

    const obj = {
      remoconKey,
      x: pageX,
      y: pageY
    };
    this.setProperty({
      property: contextProperty,
      value: obj,
      logOff: true
    }).then(() => this.windowOpen(contextProperty));
  }

  /**
   * 子畫面的内側的クリックで、展開している累計遙控器的選擇肢を消す
   */
  private contentsOnClick() {
    // ブロックを初期化
    this.selectBlockList = [];
  }

  /*********************************************************************************************************************
   * 累計遙控器で指定是情報的選擇を開始
   * @param remoconObj
   * @param event
   */
  private remoconButtonOnClick(remoconObj: any, event: any) {
    // ブロックを初期化
    this.selectBlockList = [];

    const plusMarkProc: Function = (value: number): string =>
      value > 0 ? `+${value}` : `${value}`;

    /**=================================================================================================================
     * 計數器更新処理<br />
     * 累計遙控器指定情報的選擇を全部終えた時に呼び出す
     * @param counterName
     * @param value
     * @param character
     */
    const doChange: Function = (
      counterName: string,
      value: string,
      character: any
    ) => {
      // 對像的プロパティを特定
      let prop: string;
      switch (counterName) {
        case "先攻表":
          prop = "initiative";
          break;
        case "修正（先攻表同値時比較用）":
          prop = "subInitiative";
          break;
        default:
          prop = this.propertyList.filter(
            (prop: any) => prop.property === counterName
          )[0].refStr;
      }

      /**---------------------------------------------------------------------------------------------------------------
       * 実処理
       * @param beforeValue
       * @param commitValue
       * @param command
       */
      const commit: Function = (
        beforeValue: number,
        commitValue: number,
        command: string = ""
      ) => {
        const useCommitValue: number =
          remoconObj.modifyType === this.COUNTER_REMOCON_TYPE.MINUS
            ? -commitValue
            : commitValue;

        const afterValue: number =
          remoconObj.modifyType === this.COUNTER_REMOCON_TYPE.EQUALS
            ? useCommitValue
            : beforeValue + useCommitValue;

        // ログに出力
        this.addChatLog({
          text: remoconObj.message
            .replace("{0}", character.name)
            .replace("{1}", counterName)
            .replace(
              "{2}",
              `${
                remoconObj.modifyType === this.COUNTER_REMOCON_TYPE.EQUALS
                  ? useCommitValue
                  : plusMarkProc(useCommitValue)
              }` + (command ? `（${command}）` : "")
            )
            .replace(
              "{3}",
              `${useCommitValue}` + (command ? `（${command}）` : "")
            )
            .replace("{4}", `（${counterName}：${beforeValue}->${afterValue}）`)
        });

        // 実際に先攻表的値を更新
        const propertyObj: any = {};
        propertyObj[prop] = afterValue;
        this.changeListObj({
          key: character.key,
          isNotice: true,
          property: propertyObj
        });

        // ブロックを初期化
        this.selectBlockList = [];
      };

      const beforeValue: number = parseInt(character.property[prop], 10);
      if (/^-?[0-9]+$/.test(value)) {
        // 累計遙控器に指定されていた變更値が数値だった場合、または値選擇された場合
        // 即更新 -------------------------------------------------------------------------------------------------------
        commit(beforeValue, parseInt(value, 10));
      } else {
        // 累計遙控器に指定されていた變更値が数値ではなかった場合
        // BCDice-apiで評価してもらい、そ的応答的値を利用是
        this.sendBcdiceServer({
          system: "DiceBot",
          command: `${value}`
        }).then((json: any) => {
          if (json.ok) {
            // bcdiceとして結果が取れた場合
            const resultValue = json.result.replace(/^.+＞ /, "");
            if (/^-?[0-9]+$/.test(resultValue)) {
              // 数値として応答された
              const matchResult = json.result.match(
                /^.+＞ ([^＞]+) ＞ [^＞]+$/
              );
              // 応答的結果をもって更新 -----------------------------------------------------------------------------------
              commit(
                beforeValue,
                parseInt(resultValue, 10),
                `${value}=${matchResult[1]}`
              );
              return;
            }
          }
          alert("在執行結果中無法獲得數值。\n取消。");
        });
      }
    };

    /**=================================================================================================================
     * プロックを新增是処理
     * @param event
     * @param level
     * @param counterName
     * @param character
     * @param range
     */
    const addBlock: Function = (
      event: any,
      level: number,
      counterName: string,
      character: any = null,
      range: number[] | null = null
    ) => {
      if (remoconObj.target) {
        character = this.getObj(remoconObj.target);
      }

      const blockInfo: any = {
        onMouse: true
      };

      /**---------------------------------------------------------------------------------------------------------------
       * 値的選擇肢を新增是処理
       */
      const addValueItems: Function = () => {
        blockInfo.isEnd = true;
        blockInfo.label = "選擇數値";
        blockInfo.itemList = [];
        for (let i: number = range![0]; i <= range![1]; i++) {
          const text =
            remoconObj.modifyType === this.COUNTER_REMOCON_TYPE.EQUALS
              ? `${i}`
              : plusMarkProc(i);
          blockInfo.itemList.push({
            text,
            onMouse: (event: any, blockIndex: number, itemIndex: number) => {
              blockInfo.onMouse = true;
              this.selectBlockList[blockIndex].itemList.forEach(
                (item: any, index: number) =>
                  (item.isHover = index === itemIndex)
              );
              if (blockIndex > 0)
                this.selectBlockList[blockIndex - 1].onMouse = true;
            },
            onClick: () => {
              // 計數器更新処理
              doChange(
                counterName,
                remoconObj.modifyType === this.COUNTER_REMOCON_TYPE.MINUS
                  ? -i
                  : i,
                character
              );
            }
          });
        }
      };

      /**---------------------------------------------------------------------------------------------------------------
       * 範圍的選擇肢を新增是処理
       */
      const addRangeItems: Function = () => {
        blockInfo.label = "選擇範圍";
        blockInfo.itemList = [];
        const diff = 10;
        let min = -90;
        let max = 99;
        if (remoconObj.modifyType === this.COUNTER_REMOCON_TYPE.PLUS) {
          min = 0;
        }
        if (remoconObj.modifyType === this.COUNTER_REMOCON_TYPE.MINUS) {
          max = -10;
        }
        for (let i = min; i <= max; i += diff) {
          const text =
            remoconObj.modifyType === this.COUNTER_REMOCON_TYPE.EQUALS
              ? `${i}〜${i + diff - 1}`
              : `${plusMarkProc(i)}〜${plusMarkProc(i + diff - 1)}`;
          blockInfo.itemList.push({
            text,
            onMouse: (event: any, blockIndex: number, itemIndex: number) => {
              blockInfo.onMouse = true;
              this.selectBlockList[blockIndex].itemList.forEach(
                (item: any, index: number) =>
                  (item.isHover = index === itemIndex)
              );
              if (blockIndex > 0)
                this.selectBlockList[blockIndex - 1].onMouse = true;
              addBlock(event, level + 1, counterName, character, [
                i,
                i + diff - 1
              ]);
            },
            onClick: () => {}
          });
        }
      };

      /**---------------------------------------------------------------------------------------------------------------
       * 角色的選擇肢を新增是処理
       */
      const addCharacterItems: Function = () => {
        if (remoconObj.modifyValue) {
          blockInfo.isEnd = true;
        }
        blockInfo.label = "選擇角色";
        blockInfo.itemList = this.getMapObjectList({
          kind: "character",
          place: "field"
        }).map((character: any) => {
          const result: any = {
            text: character.name
          };

          if (remoconObj.modifyValue) {
            // 變更是値も決まっている場合
            result.onMouse = () => {};
            result.onClick = () => {
              // 計數器更新処理
              doChange(counterName, remoconObj.modifyValue, character);
            };
          } else {
            // 變更是値は決まってない場合
            result.onMouse = (
              event: any,
              blockIndex: number,
              itemIndex: number
            ) => {
              blockInfo.onMouse = true;
              this.selectBlockList[blockIndex].itemList.forEach(
                (item: any, index: number) =>
                  (item.isHover = index === itemIndex)
              );
              if (blockIndex > 0)
                this.selectBlockList[blockIndex - 1].onMouse = true;
              addBlock(event, level + 1, counterName, character);
            };
            result.onClick = () => {};
          }

          return result;
        });
      };

      /**---------------------------------------------------------------------------------------------------------------
       * 計數器的選擇肢を新增是処理
       */
      const addCounterItems: Function = () => {
        const usePropertyList: any[] = this.propertyList.concat();

        usePropertyList.unshift({
          property: "修正（先攻表同値時比較用）"
        });

        usePropertyList.unshift({
          property: "先攻表"
        });

        blockInfo.label = "選擇計數器";
        blockInfo.itemList = usePropertyList
          .filter((prop: any) => prop.type !== "checkbox")
          .map((prop: any) => ({
            text: prop.property,
            onMouse: (event: any, blockIndex: number, itemIndex: number) => {
              blockInfo.onMouse = true;
              this.selectBlockList[blockIndex].itemList.forEach(
                (item: any, index: number) =>
                  (item.isHover = index === itemIndex)
              );
              if (blockIndex > 0)
                this.selectBlockList[blockIndex - 1].onMouse = true;
              addBlock(event, level + 1, prop.property, character);
            },
            onClick: () => {}
          }));
      };

      // ---------------------------------------------------------------------------------------------------------------

      if (!counterName) {
        // 計數器が選擇されていない場合
        addCounterItems();
      } else if (!character) {
        // 角色が選擇されていない場合
        addCharacterItems();
      } else if (!remoconObj.modifyValue) {
        // 變更是値が決まってない場合
        if (!range) {
          // 範圍が未指定的場合
          addRangeItems();
        } else {
          // 範圍が指定されている場合
          addValueItems();
        }
      } else {
        // 計數器更新処理
        doChange(counterName, remoconObj.modifyValue, character);
        return;
      }

      const contentsElm: HTMLDivElement = this.$refs.contents as HTMLDivElement;
      const relativeElm: HTMLDivElement = contentsElm.parentNode as HTMLDivElement;
      const relRect = relativeElm.getBoundingClientRect();
      const targetRect = event.target.getBoundingClientRect();
      blockInfo.x =
        targetRect.left + targetRect.width - relRect.left - 1 + "px";
      blockInfo.y = `calc(${targetRect.top - relRect.top + 2}px - 1.5em)`;
      blockInfo.level = level;

      listDelete(this.selectBlockList, (item: any) => level <= item.level);
      this.selectBlockList.push(blockInfo);
    };

    addBlock(event, 1, remoconObj.counterName);
  }
}
