





















import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";

@Component
export default class ActorStatusCombo extends Vue {
  @Prop({ type: String, required: true })
  private value!: string;

  @Prop({ type: Array, required: true })
  private statusList!: any[];

  @Prop({ type: Array, required: true })
  private selectedStatusList!: any[];

  @Emit("input")
  private change(value: string) {}

  get localValue(): string {
    return this.value;
  }

  set localValue(value: string) {
    this.change(value);
  }

  changeValue(value: string) {
    this.localValue = value;
  }

  get useStatusList(): any[] {
    const selectedStatusNameList = this.selectedStatusList.map(
      (status: any) => status.name
    );
    return this.statusList
      .map((status: any) => status.name)
      .filter(
        (statusName: string) =>
          selectedStatusNameList.indexOf(statusName) === -1
      );
  }
}
