












import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component
export default class WindowFrame extends Vue {
  @Prop({ type: String, required: true })
  private name!: string;

  @Emit("resize")
  private resize(
    event: any,
    direct: string,
    flg: boolean,
    isTouch: boolean = false
  ): void {}
}
