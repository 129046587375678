export default {
  state: {
    command: null,
    isDisplay: false,
    zIndex: 1,
    // テーブル形式用資料ここから
    widthList: [30, 30, 30, 112, 53, 25, 30, 40],
    selectLineKey: null,
    hoverDevIndex: -1,
    movingIndex: -1,
    movedIndex: -1,
    startX: -1,
    startLeftWidth: -1,
    startRightWidth: -1
    // テーブル形式用資料ここまで
  },
  actions: {},
  mutations: {},
  getters: {}
};
