

















































































































































import WindowFrame from "../WindowFrame.vue";
import WindowMixin from "../WindowMixin.vue";
import CtrlButton from "@/components/parts/CtrlButton.vue";

import { Getter } from "vuex-class";
import { Component, Mixins } from "vue-mixin-decorator";

import Login from "./login/Login.vue";
import Logo from "../simple/Logo.vue";
import Source from "./source/Source.vue";
import DevSupport from "./devSupport/DevSupport.vue";

import EnvironmentSpec from "./spec/EnvironmentSpec.vue";
import MenuBarSpec from "./spec/MenuBarSpec.vue";
import RoomDataSaveSpec from "./spec/RoomDataSaveSpec.vue";
import RoomDataLoadSpec from "./spec/RoomDataLoadSpec.vue";
import ChatSpec from "./spec/ChatSpec.vue";
import DiceSpec from "./spec/DiceSpec.vue";
import PlayerBoxSpec from "./spec/PlayerBoxSpec.vue";
import ChangeFontSizeSpec from "./spec/ChangeFontSizeSpec.vue";
import ResetAllWindowSpec from "./spec/ResetAllWindowSpec.vue";
import CharacterSpec from "./spec/CharacterSpec.vue";
import RangeSpec from "./spec/RangeSpec.vue";
import ChitSpec from "./spec/ChitSpec.vue";
import GraveyardSpec from "./spec/GraveyardSpec.vue";
import WaitingRoomSpec from "./spec/WaitingRoomSpec.vue";
import MapSpec from "./spec/MapSpec.vue";
import FloorTileSpec from "./spec/FloorTileSpec.vue";
import MapMaskSpec from "./spec/MapMaskSpec.vue";
import EasyMapSpec from "./spec/EasyMapSpec.vue";
import MapSaveSpec from "./spec/MapSaveSpec.vue";
import MapLoadSpec from "./spec/MapLoadSpec.vue";
import FileUploadSpec from "./spec/FileUploadSpec.vue";
import EditImageTagSpec from "./spec/EditImageTagSpec.vue";
import DeleteImageSpec from "./spec/DeleteImageSpec.vue";
import WelcomeWindowSpec from "./spec/WelcomeWindowSpec.vue";
import VersionSpec from "./spec/VersionSpec.vue";
import OfficialSiteLinkSpec from "./spec/OfficialSiteLinkSpec.vue";
import RoomInfoSpec from "./spec/RoomInfoSpec.vue";
import PublicMemoSpec from "./spec/PublicMemoSpec.vue";
import LogoutSpec from "./spec/LogoutSpec.vue";
import InitiativeSpec from "./spec/InitiativeSpec.vue";
import CounterRemoconSpec from "./spec/CounterRemoconSpec.vue";
import BgmSpec from "./spec/BgmSpec.vue";
import BgmSpec2 from "./spec/BgmSpec2.vue";
import DiceSymbolSpec from "./spec/DiceSymbolSpec.vue";
import CustomDiceBotSpec from "./spec/CustomDiceBotSpec.vue";
import StandImageSpec from "./spec/StandImageSpec.vue";
import ChatPaletteSpec from "./spec/ChatPaletteSpec.vue";
import ChatLogSaveSpec from "./spec/ChatLogSaveSpec.vue";

@Component({
  components: {
    CtrlButton,
    WindowFrame,
    Logo,
    Login,
    EnvironmentSpec,
    MenuBarSpec,
    RoomDataSaveSpec,
    RoomDataLoadSpec,
    ChatSpec,
    DiceSpec,
    PlayerBoxSpec,
    InitiativeSpec,
    ChangeFontSizeSpec,
    ResetAllWindowSpec,
    CharacterSpec,
    RangeSpec,
    ChitSpec,
    GraveyardSpec,
    WaitingRoomSpec,
    MapSpec,
    FloorTileSpec,
    MapMaskSpec,
    EasyMapSpec,
    MapSaveSpec,
    MapLoadSpec,
    FileUploadSpec,
    EditImageTagSpec,
    DeleteImageSpec,
    WelcomeWindowSpec,
    VersionSpec,
    OfficialSiteLinkSpec,
    RoomInfoSpec,
    PublicMemoSpec,
    LogoutSpec,
    Source,
    DevSupport,
    BgmSpec,
    BgmSpec2,
    CustomDiceBotSpec,
    StandImageSpec,
    CounterRemoconSpec,
    DiceSymbolSpec,
    ChatPaletteSpec,
    ChatLogSaveSpec
  }
})
export default class WelcomeWindow extends Mixins<WindowMixin>(WindowMixin) {
  @Getter("isRoomJoined") private isRoomJoined: any;
  @Getter("version") private version: any;

  private tabNum: string = "1";

  specAll(openFlg: boolean) {
    const elms = document
      .getElementById("welcomeWindowContents")!
      .querySelectorAll("input[type=checkbox]");
    Array.prototype.slice
      .call(elms)
      .filter(elm => elm.checked !== openFlg)
      .forEach(elm => elm.click());
  }

  scrollTo(target: string | undefined) {
    const contentsElm: HTMLElement = document.getElementById(
      "welcomeWindowContents"
    )!;
    setTimeout(() => {
      contentsElm.scrollTop = 0;
    }, 0);
  }
}
