export default {
  state: {
    command: null,
    isDisplay: false,
    zIndex: 1,
    name: "",
    size: 1,
    useImageList: "",
    isHide: false,
    url: "",
    text: "",
    useImageIndex: 0,
    currentImageTag: "",
    isContinuous: false,
    continuousNum: 1
  },
  actions: {},
  mutations: {},
  getters: {}
};
