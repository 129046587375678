export default {
  state: {
    command: null,
    isDisplay: false,
    x: 0,
    y: 0
  },
  actions: {},
  mutations: {},
  getters: {}
};
