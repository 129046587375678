






















































































































































import WindowFrame from "../../WindowFrame.vue";
import WindowMixin from "../../WindowMixin.vue";
import CtrlButton from "@/components/parts/CtrlButton.vue";
import ImageSelector from "@/components/parts/ImageSelector.vue";

import { Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { Component, Mixins } from "vue-mixin-decorator";

@Component({
  components: {
    CtrlButton,
    WindowFrame,
    ImageSelector
  }
})
export default class EditCharacterWindow extends Mixins<WindowMixin>(
  WindowMixin
) {
  @Action("setProperty") private setProperty: any;
  @Action("windowOpen") private windowOpen: any;
  @Action("windowClose") private windowClose: any;
  @Action("changeListObj") private changeListObj: any;
  @Getter("getObj") private getObj: any;
  @Getter("parseColor") private parseColor: any;
  @Getter("imageList") private imageList: any;

  private selectImage: string = "image-2";

  @Watch("selectImage")
  onChangeSelectImage(selectImage: string) {
    const index = this.switchImageList.findIndex(
      image => image.key === this.switchCurrentKey
    );
    const switchImageObj = this.switchImageList[index];

    switchImageObj.imgKey = selectImage.replace(":R", "");
    switchImageObj.isReverse = /:R/.test(selectImage);
    this.switchImageList.splice(index, 1, switchImageObj);
  }

  private isOpenSwitch: boolean = false;
  private currentImageTag: string = "imgTag-2";
  private switchImageList: any[] = [
    { key: 0, imgKey: "image-2", isReverse: false }
  ];
  private switchCurrentKey: number = 0;
  private name: string = "";
  private size: number = 1;
  private isHide: boolean = false;
  private url: string = "";
  private text: string = "";

  private addSwitch(): void {
    const nextKey: number =
      Math.max.apply(
        null,
        this.switchImageList.map(image => image.key)
      ) + 1;

    this.switchImageList.push({
      key: nextKey,
      imgKey: this.selectImage.replace(":R", ""),
      isReverse: false
    });
    this.switchCurrentKey = nextKey;
  }

  private doReverse(): void {
    const index = this.switchImageList.findIndex(
      image => image.key === this.switchCurrentKey
    );
    const switchImageObj = this.switchImageList[index];
    switchImageObj.isReverse = !switchImageObj.isReverse;
    this.switchImageList.splice(index, 1, switchImageObj);
  }

  private getImage(key: number | string): string | null {
    const imageObj = this.imageList.filter(
      (image: any) => image.key === key
    )[0];
    return imageObj ? imageObj.data : null;
  }

  private getKeyObj(list: any[], key: number | string): any | null {
    return list.filter(obj => obj.key === key)[0];
  }

  private selectSwitchImage(key: number): void {
    this.switchCurrentKey = key;
  }

  private selectTagImage(key: number): void {
    const index = this.switchImageList.findIndex(
      image => image.key === this.switchCurrentKey
    );
    const switchImageObj = this.switchImageList[index];
    switchImageObj.imgKey = key;
    switchImageObj.isReverse = false;
    this.switchImageList.splice(index, 1, switchImageObj);
  }

  private deleteSwitch(): void {
    const index = this.switchImageList.findIndex(
      image => image.key === this.switchCurrentKey
    );
    const switchImageObj = this.switchImageList[index];
    // 刪除
    this.switchImageList.splice(index, 1);
    this.switchCurrentKey = this.switchImageList[
      index < this.switchImageList.length
        ? index
        : this.switchImageList.length - 1
    ].key;
  }

  private commit(): void {
    this.name = this.name.trim();
    if (!this.name) {
      alert(`請輸入你的名字。`);
      return;
    }
    this.changeListObj({
      key: this.key,
      isNotice: true,
      name: this.name,
      columns: this.size,
      rows: this.size,
      useImageList: this.switchImageList
        .map(imgObj => imgObj.imgKey + (imgObj.isReverse ? ":R" : ""))
        .join("|"),
      isHide: this.isHide,
      url: this.url,
      text: [this.text],
      useImageIndex: 0,
      currentImageTag: this.currentImageTag
    });
    this.windowClose("private.display.editCharacterWindow");
  }

  private cancel(): void {
    this.windowClose("private.display.editCharacterWindow");
  }

  private open(): void {
    this.isOpenSwitch = false;
    let characterObj = this.getObj(this.key);
    this.currentImageTag = characterObj.currentImageTag;
    this.switchImageList.splice(0, this.switchImageList.length);
    characterObj.useImageList
      .split("|")
      .forEach((imageStr: string, index: number) => {
        const isReverse = imageStr.indexOf(":R") >= 0;
        const imageKey = imageStr.replace(":R", "");
        this.switchImageList.push({
          key: index,
          imgKey: imageKey,
          isReverse: isReverse
        });
      });
    this.switchCurrentKey = characterObj.useImageIndex;
    this.name = characterObj.name;
    this.size = characterObj.columns;
    this.isHide = characterObj.isHide;
    this.url = characterObj.url;
    const textObj = characterObj.text.filter((t: any) => t.kind === "text")[0];
    this.text = textObj ? textObj.text : "";
    this.selectImage = this.switchImageList[this.switchCurrentKey].imgKey;
  }

  private get key(): string {
    return this.$store.state.private.display["editCharacterWindow"].key;
  }

  private get selectedTagIndexText(): string {
    const imageList = this.imageList;
    const keyObj = this.getKeyObj(imageList, this.currentImageKey);
    const index = keyObj ? imageList.indexOf(keyObj) + 1 : 0;
    return `${index}/${imageList.length}`;
  }

  private get isReverse(): boolean {
    return this.getKeyObj(this.switchImageList, this.switchCurrentKey)
      .isReverse;
  }

  private get isCanSwitchDelete(): boolean {
    return this.switchImageList.length > 1;
  }

  private get currentImage(): string | null {
    return this.getImage(this.currentImageKey);
  }

  private get currentImageKey(): string {
    return this.getKeyObj(this.switchImageList, this.switchCurrentKey).imgKey;
  }
}
