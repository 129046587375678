
























import PieceMixin from "../../PieceMixin.vue";

import { Component } from "vue-property-decorator";
import { Getter } from "vuex-class";

@Component
export default class CharacterChip extends PieceMixin {
  @Getter("imageList") private imageList: any;

  getKeyObj(list: any[], key: string) {
    const filteredList = list.filter(obj => obj.key === key);
    if (filteredList.length === 0) return null;
    if (filteredList.length > 1) return null;
    return filteredList[0];
  }

  get characterStyle(): void {
    let obj: any = this.style;
    obj.left = 0;
    obj.top = 0;
    obj.width = this.gridSize + "px";
    obj.height = this.gridSize + "px";
    // delete obj.transform
    // window.console.log(` [computed] character(${this.objKey}) style => lt(${obj.left}, ${obj.top}), wh(${obj.width}, ${obj.height}), bg:"${obj['background-color']}", font:"${obj.color}"`)
    return obj;
  }
  get name() {
    return this.storeObj.name;
  }
  get useImageList() {
    return this.storeObj.useImageList;
  }
  get useImageIndex() {
    return this.storeObj.useImageIndex;
  }
  get isBorderHide(): number {
    return this.storeObj.isBorderHide;
  }
  get imageObj() {
    if (this.useImageList === "") {
      return "";
    }
    const imageStr = this.useImageList.split("|")[this.useImageIndex];
    // window.console.log(`list:${this.useImageList}(${this.useImageIndex}), image:${imageStr}`)
    const isReverse = imageStr.indexOf(":R") >= 0;
    const imageKey = imageStr.replace(":R", "");
    return {
      isReverse: isReverse,
      data: this.getKeyObj(this.imageList, imageKey).data
    };
  }
}
