










import ContextFrame from "../ContextFrame.vue";
import WindowMixin from "../WindowMixin.vue";

import { Action, Getter } from "vuex-class";
import { Component, Mixins } from "vue-mixin-decorator";

@Component({ components: { ContextFrame } })
export default class PublicMemoContext extends Mixins<WindowMixin>(
  WindowMixin
) {
  @Action("windowOpen") private windowOpen: any;
  @Action("setProperty") private setProperty: any;
  @Action("changeListObj") private changeListObj: any;
  @Action("deleteListObj") private deleteListObj: any;
  @Action("windowClose") private windowClose: any;
  @Getter("getObj") private getObj: any;
  @Getter("characterContextObjKey") private characterContextObjKey: any;
  @Getter("playerKey") private playerKey: any;

  private viewItemOnClick() {
    this.openPublicMemoWindow(false);
    this.windowClose("private.display.publicMemoContext");
  }

  private editItemOnClick() {
    this.openPublicMemoWindow(true);
    this.windowClose("private.display.publicMemoContext");
  }

  private openPublicMemoWindow(isEditMode: boolean) {
    this.setProperty({
      property: "private.display.publicMemoWindow",
      value: {
        objKey: this.objKey,
        isEditMode: isEditMode
      },
      logOff: true
    });
    this.windowOpen("private.display.publicMemoWindow");
    this.windowClose("private.display.publicMemoContext");
  }

  private deleteItemOnClick() {
    this.deleteListObj({
      propName: "publicMemo",
      key: this.objKey
    });
    this.windowClose("private.display.publicMemoContext");
  }

  private get objKey() {
    return this.$store.state.private.display.publicMemoContext.objKey;
  }
}
