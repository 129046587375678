










































































import WindowFrame from "../WindowFrame.vue";
import WindowMixin from "../WindowMixin.vue";
import CtrlButton from "@/components/parts/CtrlButton.vue";
import CtrlSelect from "@/components/parts/CtrlSelect.vue";
import ImageTagSelect from "@/components/parts/select/ImageTagSelect.vue";
import CtrlFileSelector from "@/components/parts/CtrlFileSelector.vue";

import { fileToBase64 } from "../common/Utility";
import { Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { Component, Mixins } from "vue-mixin-decorator";

@Component({
  components: {
    CtrlFileSelector,
    CtrlButton,
    CtrlSelect,
    ImageTagSelect,
    WindowFrame
  }
})
export default class FileUploaderWindow extends Mixins<WindowMixin>(
  WindowMixin
) {
  @Action("addImage") private addImage: any;
  @Action("windowClose") private windowClose: any;
  @Getter("imageTagList") private imageTagList: any;
  @Getter("playerKey") private playerKey: any;
  @Getter("activeChatTab") private activeChatTab: any;
  @Action("sendChatLog") private sendChatLog: any;

  private inputImageTag: string = "角色";
  private selectImageTag: string = "imgTag-2";
  private useImageList: any[] = [];
  private hoverImageKey: number | null = null;
  private committed: boolean = false;
  private windowMessage: string = "";

  private open() {
    this.inputImageTag = "角色";
    this.selectImageTag = "imgTag-2";
    this.useImageList = [];
    this.hoverImageKey = null;
    this.committed = false;
    this.windowMessage = "";

    const button: CtrlFileSelector = this.$refs.button as CtrlFileSelector;
    button.requestFocus();
  }

  /**
   * selectボックスが選擇されたら、輸入欄的内容を更新是
   */
  @Watch("selectImageTag")
  private onChangeSelectImageTag(selectImageTag: string) {
    const imageTagObj = (this.imageTagList as Array<any>).filter(
      (imageTag: any) => imageTag.key === selectImageTag
    )[0];
    if (!imageTagObj) return;

    this.inputImageTag = selectImageTag === "imgTag-0" ? "" : imageTagObj.name;
  }

  private passwordButtonOnClick() {
    this.sendChatLog({
      actorKey: "HKTRPG",
      text: "未實裝此功能。",
      chatTarget: this.playerKey,
      statusName: "◆",
      outputTab: this.activeChatTab
    });
    // alert("未實裝此功能。");
  }

  /**
   * 輸入欄に輸入されたら、selectボックス的内容を更新是
   */
  @Watch("inputImageTag")
  private onChangeInputImageTag(inputImageTag: string) {
    const regExp = new RegExp("[ 　]+");
    const tagNameList: string[] = this.inputImageTag.trim().split(regExp);

    if (tagNameList.length === 1 && tagNameList[0] === "") {
      this.selectImageTag = "imgTag-0";
      return;
    }

    if (tagNameList.length > 1) {
      this.selectImageTag = "";
      return;
    }

    const filtered: any = this.imageTagList.filter(
      (imageTagObj: any) => imageTagObj.name === tagNameList[0]
    )[0];
    this.selectImageTag = filtered ? filtered.key : "";
  }

  private fileOnChange(event: any) {
    const files: File[] = Array.from(event.target.files);
    const imageFiles: File[] = files.filter(
      file => file.type.indexOf("image/") === 0
    );

    fileToBase64(imageFiles).then((values: any[]) => {
      values.forEach((valueObj: any, index: number) => {
        const lastKey = this.useImageList.length
          ? this.useImageList[this.useImageList.length - 1].key
          : -1;
        valueObj.key = index + lastKey + 1;
      });

      this.useImageList = this.useImageList.concat(values);
      event.target.value = "";
    });
    this.windowMessage = "";
  }

  private get imageTagObjList(): any[] {
    const regExp = new RegExp("[ 　]+");
    const tagNameList: string[] = this.inputImageTag.trim().split(regExp);
    const resultList: any[] = [];
    tagNameList.forEach(tagName => {
      const filtered: any = this.imageTagList.filter(
        (imageTagObj: any) => imageTagObj.name === tagName
      )[0];
      if (filtered) {
        resultList.push(filtered);
      } else {
        // TODO
      }
    });
    return resultList;
  }

  private deleteImageOnClick(key: number) {
    const index: number = this.useImageList.findIndex(
      imageObj => imageObj.key === key
    );
    this.useImageList.splice(index, 1);
    this.hoverImageKey = null;
  }

  private imageOnHover(key: number | undefined) {
    this.hoverImageKey = key !== undefined ? key : null;
  }

  private get message() {
    const imageObj: any = this.useImageList.filter(
      imageObj => imageObj.key === this.hoverImageKey
    )[0];

    if (imageObj) return imageObj.name;

    return this.windowMessage;
  }

  private commitOnClick() {
    const regExp: RegExp = new RegExp("[ 　]+", "g");
    this.useImageList.forEach(imageObj => {
      this.addImage({
        name: imageObj.name,
        tag: this.inputImageTag.replace(regExp, " "),
        data: imageObj.image,
        thumbnail: imageObj.thumbnail,
        imageArgList: imageObj.imageArgList,
        owner: this.playerKey
      });
    });
    this.open();
    this.windowMessage = "圖片加載完成。";
  }

  private cancelOnClick() {
    this.windowClose("private.display.fileUploaderWindow");
  }
}
