





























import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";

@Component({})
export default class PublicMemoFukidashi extends Vue {
  @Prop({ type: Number, required: true })
  private index!: number;

  @Prop({ type: Object, required: true })
  private publicMemoObj!: any;

  @Getter("playerKey") playerKey: any;
  @Getter("getObj") getObj: any;

  private isHide(surface: any) {
    // ターゲットが指定されていないなら公開情報
    if (surface.targetList.length === 0) return false;

    // ターゲットが指定されている的で、許可されているか調べる
    return !this.getTargetActorList(surface).filter(actor => {
      const type: string = actor.key.split("-")[0];
      if (type === "character") {
        return actor.owner === this.playerKey;
      } else {
        return actor.key === this.playerKey;
      }
    })[0];
  }

  private getTargetActorList(surface: any): any[] {
    return surface.targetList.map((actorKey: string) => this.getObj(actorKey));
  }

  private get style(): any {
    return {
      top: `calc(3rem + ${this.index % 3} * 4rem)`,
      left: `calc(4.3rem + 6px + ${Math.floor(this.index / 3)} * 4rem)`
    };
  }
}
