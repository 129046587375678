











































import CtrlButton from "@/components/parts/CtrlButton.vue";

import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { execCopy } from "../../common/Utility";

@Component({ components: { CtrlButton } })
export default class RoomInfo extends Vue {
  @Getter("playerList") private playerList: any;
  @Getter("inviteUrl") private inviteUrl: any;
  @Getter("getMembers") private getMembers: any;
  @Getter("playerKey") private playerKey: any;
  @Getter("isWait") private isWait: any;

  createUrl(player: any): string {
    return `${this.inviteUrl}&playerName=${player.name}`;
  }

  /**
   *
   * @param event
   */
  doCopy(event: any): void {
    // const text = event.target.previousElementSibling.value;
    const text = event.target.parentNode.previousElementSibling.value;
    if (!execCopy(text)) {
      alert("無法複製文字內容。");
    }
    alert(text);
  }
}
