







import { Action, Getter } from "vuex-class";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class MenuBooleanItem extends Vue {
  @Prop({ type: String, required: true })
  private property!: string;

  @Action("reverseProperty") private reverseProperty: any;
  @Getter("isWindowOpen") private isWindowOpen: any;

  @Emit("click")
  private itemOnClick() {
    this.reverseProperty({ property: this.property });
  }

  private mouseEnter(event: any) {
    this.$emit("mouseenter", event);
  }

  private get propValue() {
    return this.isWindowOpen(this.property);
  }
}
