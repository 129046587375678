











import Vue from "vue";

import { Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { Component, Mixins } from "vue-mixin-decorator";
import DiceBotSelect from "../parts/select/DiceBotSelect.vue";

@Component
export default class DiceBotMessage extends Vue {
  @Action("setProperty") private setProperty: any;
  @Getter("diceBotMessageText") private diceBotMessageText: any;
  @Getter("diceBotMessageView") private diceBotMessageView: any;

  private animationClass: string = "";
  private isView: boolean = false;
  private colorClass: string = "";

  /**
   * 訊息的文言に「成功」「失敗」が含まれていたら、背景的色を変える規格
   * @param diceBotMessageText
   */
  @Watch("diceBotMessageText", { immediate: true })
  private onChangeCustomDiceBotMessage(diceBotMessageText: string) {
    let successCount: number =
      diceBotMessageText.split(/成功|[Ss]uccess/).length - 1;
    let failureCount: number =
      diceBotMessageText.split(/失敗|[Ff]ailure/).length - 1;
    if (successCount > failureCount) this.colorClass = "success";
    else if (successCount < failureCount) this.colorClass = "failure";
    else this.colorClass = "normal";
  }

  private onClick() {
    this.setProperty({
      property: `public.chat.diceBotMessage`,
      value: {
        // message: "",
        isView: false
      },
      isNotice: true,
      logOff: true
    });
  }

  private get viewMessage() {
    return this.diceBotMessageText
      .split("。")
      .map((lineStr: string) => {
        const sepList = lineStr.split("、");
        for (let i = 0; i < sepList.length; i++) {
          if (
            i < sepList.length - 1 &&
            sepList[i].length + sepList[i + 1].length < 25
          ) {
            sepList[i] += "、" + sepList.splice(i + 1, 1)[0];
            i--;
          }
        }
        return sepList.join("、<br />");
      })
      .join("。<br />");
  }

  private timer: any = null;

  /**
   * 「顯示」「不顯示」的際に「フェードイン」「フェードアウト」是規格
   * @param diceBotMessageView
   */
  @Watch("diceBotMessageView", { immediate: true })
  private onChangeCustomDiceBotView(diceBotMessageView: boolean) {
    if (diceBotMessageView) {
      this.animationClass = "fade-in";
      this.isView = true;

      this.timer = setTimeout(() => {
        this.setProperty({
          property: `public.chat.diceBotMessage.isView`,
          value: false,
          isNotice: false,
          logOff: true
        });
      }, 5000);
    } else {
      if (this.timer !== null) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.animationClass = "fade-out";
      setTimeout(() => {
        this.isView = false;
      }, 200);
    }
  }
}
