


















import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import VolumeComponent from "@/components/music/component/VolumeComponent.vue";

@Component({
  components: {
    VolumeComponent
  }
})
export default class MasterVolumeComponent extends Vue {
  /** Vuex的 action へ的參考 */
  @Action("setProperty") private setProperty: any;
  /** Vuex的 getter へ的參考 */
  @Getter("masterMute") private masterMute: any;
  @Getter("masterVolume") private masterVolume: any;

  /**
   * ライフサイクルメソッド
   */
  mounted(this: any): void {
    this.$refs.volumeComponent.setMute(this.masterMute);
    this.$refs.volumeComponent.setVolume(this.masterVolume);
  }

  /**
   * マスターミュートを設定是
   * @param mute
   */
  mute(this: any, mute: boolean): void {
    this.$refs.volumeComponent.setMute(mute);
    this.setProperty({
      property: `private.display.jukeboxWindow.masterMute`,
      value: mute,
      logOff: true
    });
  }

  /**
   * マスターボリュームを設定是
   * @param volume
   */
  volume(this: any, volume: number): void {
    this.$refs.volumeComponent.setVolume(volume);
    this.setProperty({
      property: `private.display.jukeboxWindow.masterVolume`,
      value: volume,
      logOff: true
    });
  }
}
