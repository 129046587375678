

















































import WindowFrame from "../../WindowFrame.vue";
import WindowMixin from "../../WindowMixin.vue";
import ImageSelector from "@/components/parts/ImageSelector.vue";

import { Action, Getter } from "vuex-class";
import { Component, Mixins } from "vue-mixin-decorator";
import { Watch } from "vue-property-decorator";

@Component({
  components: {
    WindowFrame,
    ImageSelector
  }
})
export default class AddFloorTileWindow extends Mixins<WindowMixin>(
  WindowMixin
) {
  @Action("windowOpen") private windowOpen: any;
  @Action("windowClose") private windowClose: any;
  @Getter("imageList") private imageList: any;
  @Getter("imageListFromTagKey") private imageListFromTagKey: any;

  private currentImageTag: string = "imgTag-0";
  private selectImage: string = "image-12";
  private isReverse: boolean = false;
  private rows: number = 1;
  private columns: number = 1;
  private description: string = "";

  private dragStart(event: any) {
    event.dataTransfer.setData("kind", "floorTile");
    event.dataTransfer.setData("currentImageTag", this.currentImageTag);
    event.dataTransfer.setData("imageKey", this.selectImage);
    event.dataTransfer.setData("isReverse", this.isReverse);
    event.dataTransfer.setData("rows", this.rows);
    event.dataTransfer.setData("columns", this.columns);
    event.dataTransfer.setData("description", this.description);
  }

  @Watch("selectImage")
  private onChangeSelectImage(selectImage: string) {
    this.isReverse = /:R/.test(this.selectImage);
  }

  private getImage(key: string) {
    return this.getKeyObj(this.imageList, key).data;
  }

  private getKeyObj(list: any[], key: string): any {
    return list.filter(obj => obj.key === key)[0];
  }

  private open() {
    this.currentImageTag = "imgTag-0";
    this.selectImage = "image-12";
    this.isReverse = false;
    this.rows = 1;
    this.columns = 1;
    this.description = "";
  }

  private get currentImage() {
    const imageObj = this.getKeyObj(
      this.imageList,
      this.selectImage.replace(":R", "")
    );
    return imageObj ? imageObj.data : null;
  }
}
