









































































































































































import WindowFrame from "../WindowFrame.vue";
import WindowMixin from "../WindowMixin.vue";
import ActorTabComponent from "@/components/parts/tab-component/ActorTabComponent.vue";
import ActorStatusTabComponent from "@/components/parts/tab-component/ActorStatusTabComponent.vue";
import DiffComponent from "@/components/stand-image/DiffComponent.vue";
import ActorOtherStatusSelect from "@/components/parts/select/ActorOtherStatusSelect.vue";
import StandImageComponent from "@/components/parts/StandImageComponent.vue";
import CtrlButton from "@/components/parts/CtrlButton.vue";

import { removeExt } from "@/components/common/Utility";
import { Action, Getter } from "vuex-class";
import { Component, Mixins } from "vue-mixin-decorator";

@Component({
  components: {
    CtrlButton,
    ActorOtherStatusSelect,
    WindowFrame,
    ActorTabComponent,
    ActorStatusTabComponent,
    DiffComponent,
    StandImageComponent
  }
})
export default class StandImageSettingWindow extends Mixins<WindowMixin>(
  WindowMixin
) {
  @Action("setProperty") private setProperty: any;
  @Action("windowOpen") private windowOpen: any;
  @Action("addStandImageDiff") private addStandImageDiff: any;
  @Action("deleteActorStatus") private deleteActorStatus: any;
  @Action("changeListObj") private changeListObj: any;
  @Getter("imageList") private imageList: any;
  @Getter("getObj") private getObj: any;

  private actorKey: string = "";
  private statusName: string | null = "";
  private isPreview: boolean = true;
  private baseSize: any = { w: 0, h: 0 };

  private open() {
    const tab: ActorTabComponent = this.$refs.tab as ActorTabComponent;
    tab.requestFocus();
  }

  changeActor(actorKey: string): void {
    this.actorKey = actorKey;
  }

  doDeleteActorStatus(key: string, statusName: string) {
    const comp: ActorStatusTabComponent = this.$refs
      .actorStatusTabComponent as ActorStatusTabComponent;
    this.statusName = comp.deleteTab();
    this.deleteActorStatus(key, statusName);
  }

  getViewStatus(status: any) {
    const useName = status.standImage.ref || this.statusName;
    const actor = this.getObj(this.actorKey);

    return actor.statusList.filter((status: any) => status.name === useName)[0];
  }

  private updateStatus(standImage: any) {
    const actor: any = this.getObj(this.actorKey);
    const statusIndex: number = actor.statusList.findIndex(
      (status: any) => status.name === this.statusName
    );
    const updateStatusList: any = {};
    updateStatusList[statusIndex] = {
      standImage: standImage
    };

    setTimeout(() =>
      this.changeListObj({
        key: this.actorKey,
        statusList: updateStatusList
      })
    );
  }

  changeRef(ref: string): void {
    this.updateStatus({ ref });
  }

  changeStatus(statusName: string): void {
    this.statusName = statusName;
  }

  changeAutoResize(autoResize: boolean) {
    this.updateStatus({
      autoResize: autoResize
    });
  }

  changeLocate(locate: number) {
    this.updateStatus({
      locate: locate
    });
  }

  changeAnimationLength(animationLength: number) {
    this.updateStatus({
      animationLength: animationLength
    });
  }

  get imageKey(): string | null {
    if (!this.actorKey) return null;
    if (!this.statusName) return null;

    const statusList: any[] = this.getObj(this.actorKey).statusList;
    const status = statusList.filter(
      (status: any) => status.name === this.statusName
    )[0];

    return this.getViewStatus(status).standImage.base;
  }

  get image(): string | null {
    const imageKey = this.imageKey;
    if (!imageKey) return null;
    const imageObj = this.imageList.filter(
      (image: any) => image.key === imageKey.replace(":R", "")
    )[0];
    return imageObj ? imageObj.data : null;
  }

  get isReverse(): boolean {
    const imageKey = this.imageKey;
    if (!imageKey) return false;
    return /:R/.test(imageKey);
  }

  selectBaseImage(): void {
    const actor = this.getObj(this.actorKey);
    const statusIndex = actor.statusList.findIndex(
      (status: any) => status.name === this.statusName
    );
    const status = actor.statusList[statusIndex];
    const base = status.standImage.base;
    const baseTag = status.standImage.baseTag;
    Promise.resolve()
      .then(() =>
        // リアクティブ的ため的更新と、それに伴うコールバック的一時無効的ため的指定
        this.setProperty({
          property: "private.display.imageSelectorWindow",
          value: {
            imageKey: null,
            imageTag: null,
            callback: null
          },
          logOff: true
        })
      )
      .then(() =>
        this.setProperty({
          property: "private.display.imageSelectorWindow",
          value: {
            imageKey: base,
            imageTag: baseTag,
            callback: (imageKey: string, imageTag: string) => {
              if (this.imageKey === imageKey) return;

              const arg = {
                base: imageKey,
                baseTag: imageTag
              };

              this.updateStatus(arg);

              const isReverse: boolean = /:R/.test(imageKey);

              // 圖片的ファイル名的情報を利用
              const baseImage: any = this.imageList.filter(
                (image: any) => image.key === imageKey.replace(":R", "")
              )[0];
              if (baseImage) {
                const baseImageName: string = removeExt(baseImage.name);
                const diffImageList: any[] = this.imageList.filter(
                  (image: any) =>
                    image.key !== imageKey.replace(":R", "") &&
                    image.name.startsWith(baseImageName)
                );
                const diffList: DiffComponent[] | undefined = this.$refs
                  .diffList as DiffComponent[];
                diffImageList.forEach((diffImage: any) => {
                  let isFind: boolean = false;
                  if (diffList) {
                    for (const diff of diffList) {
                      if (
                        diff &&
                        diff.imageKey &&
                        diff.imageKey.replace(":R", "") ===
                          diffImage.key.replace(":R", "")
                      ) {
                        isFind = true;
                        break;
                      }
                    }
                  }
                  if (!isFind) {
                    const argObj = DiffComponent.getArg(diffImage);
                    this.addDiff(
                      diffImage.key,
                      imageTag,
                      argObj.type,
                      isReverse ? argObj.reverseX : argObj.x,
                      isReverse ? argObj.reverseY : argObj.y,
                      argObj.from,
                      argObj.to
                    );
                  }
                });
              }
            }
          },
          logOff: true
        })
      )
      .then(() => {
        this.windowOpen("private.display.imageSelectorWindow");
      });
  }

  onBaseResize(size: any) {
    this.baseSize = size;
  }

  get standImageStyle(): any {
    const canvasSize: any = this.baseSize;
    if (canvasSize.w === 0 || canvasSize.h === 0) return {};

    const ratioW: number = 192 / canvasSize.w;
    const ratioH: number = 256 / canvasSize.h;

    const ratio: number = Math.min(ratioW, ratioH);

    const translate: number[] = [0, 0];
    if (ratioW < ratioH) {
      // 横長的場合は下寄せに是
      translate[1] = 256 - canvasSize.h * ratio;
    } else {
      // 縦長的場合は左寄せでいい的で何も否
    }
    const transformList: string[] = [];
    transformList.push(`translate(${translate[0]}px, ${translate[1]}px)`);
    transformList.push(`scale(${ratio}, ${ratio})`);
    return {
      transform: transformList.join(" "),
      transformOrigin: "left top"
    };
  }

  private addDiff(
    image: string = "",
    tag: string = "imgTag-4",
    type: number = 0,
    x: number = 0,
    y: number = 0,
    from: number = 30,
    to: number = 70
  ): void {
    this.addStandImageDiff({
      key: this.actorKey,
      statusName: this.statusName,
      image,
      tag,
      x,
      y,
      type: type,
      time: [from, to]
    });
  }
}
