













import WindowFrame from "../WindowFrame.vue";
import WindowMixin from "../WindowMixin.vue";

import { Getter } from "vuex-class";
import { Component, Mixins } from "vue-mixin-decorator";

@Component({ components: { WindowFrame } })
export default class ImageViewWindow extends Mixins<WindowMixin>(WindowMixin) {
  @Getter("getObj") getObj: any;

  private get imageData() {
    const imageObj = this.getObj(this.objKey);
    return imageObj ? imageObj.data : null;
  }

  private get objKey() {
    return this.$store.state.private.display.imageViewWindow.objKey;
  }
}
