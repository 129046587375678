





























import SelectMixin from "@/components/parts/select/base/SelectMixin";

import { Prop, Watch } from "vue-property-decorator";
import { Component, Mixins } from "vue-mixin-decorator";

@Component({})
export default class CtrlSelect extends Mixins<SelectMixin>(SelectMixin) {
  @Prop({ type: Boolean, default: false })
  private disabled!: boolean;

  @Prop({ type: Array, required: true })
  private optionInfoList!: any[];

  @Prop({ type: Number, default: 0 })
  private maxWidth!: number;

  private fontColor: string = "";

  public requestFocus(): void {
    const elm: HTMLSelectElement = this.$refs.select as HTMLSelectElement;
    elm.focus();
  }

  @Watch("optionInfoList", { immediate: true })
  onChangeOptionInfoList(optionInfoList: any[]) {
    if (this["test"]) {
      window.console.log(optionInfoList);
    }
  }

  @Watch("value", { immediate: true })
  onChangeValue(value: string | null) {
    const optionInfo: any = this.optionInfoList.filter(
      optionInfo => optionInfo.value === value
    )[0];
    this.fontColor = optionInfo && optionInfo.disabled ? "#999999" : "#000000";
  }
}
