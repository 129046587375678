




import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";

@Component
export default class ModalScreen extends Vue {
  @Getter("isModal") private isModal: any;
}
