















































































































































































































import WindowFrame from "../WindowFrame.vue";
import WindowMixin from "../WindowMixin.vue";
import VolumeComponent from "./component/VolumeComponent.vue";
import CtrlButton from "@/components/parts/CtrlButton.vue";
import CtrlSelect from "@/components/parts/CtrlSelect.vue";

import { Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { Component, Mixins } from "vue-mixin-decorator";

@Component({
  components: {
    CtrlSelect,
    CtrlButton,
    WindowFrame,
    VolumeComponent
  }
})
export default class AddBGMWindow extends Mixins<WindowMixin>(WindowMixin) {
  @Action("windowClose") private windowClose: any;
  @Action("addListObj") private addListObj: any;
  @Action("setProperty") private setProperty: any;
  @Getter("playerKey") private playerKey: any;

  private isYoutube: boolean = false;
  private url: string = "";
  private title: string = "";
  private creditUrl: string = "";
  private tag: string = "";
  private isLoop: boolean = false;
  private fadeIn: number = 0;
  private fadeOut: number = 0;
  private start: number = 0;
  private end: number = 0;
  private playLength: number = 0;
  private isMute: boolean = false;
  private volume: number = 0.8;
  private options: any[] = [
    { value: "0", label: "無" },
    { value: "1", label: "末端文字" },
    { value: "2", label: "正規表示" }
  ];
  private tags: string[] = ["BGM", "SE"];
  private chatLinkage: string = "0";
  private chatLinkageSearch: string = "";
  private forceReset: boolean = true;

  private initWindow(this: any): void {
    this.isYoutube = false;
    this.url = "";
    this.title = "";
    this.creditUrl = "";
    this.tag = "";
    this.isLoop = false;
    this.fadeIn = 0;
    this.fadeOut = 0;
    this.start = 0;
    this.end = 0;
    this.playLength = 0;
    this.isMute = false;
    this.volume = 0.8;
    this.chatLinkage = "0";
    this.chatLinkageSearch = "";
    this.forceReset = true;

    const urlElm: HTMLElement = this.$refs.urlElm;
    setTimeout(() => urlElm.focus(), 0);
  }

  private commit(): void {
    this.addListObj({
      propName: "bgm",
      kind: "bgm",
      url: this.url,
      title: this.title,
      creditUrl: this.creditUrl,
      tag: this.tag,
      isLoop: this.isLoop,
      fadeIn: Math.floor(this.fadeIn * 10) / 10,
      fadeOut: Math.floor(this.fadeOut * 10) / 10,
      playLength: Math.floor(this.playLength * 10) / 10,
      isMute: this.isMute,
      volume: this.volume,
      chatLinkage: this.chatLinkage,
      chatLinkageSearch: this.chatLinkageSearch,
      forceReset: this.forceReset,
      owner: this.playerKey
    });
    this.windowClose("private.display.addBGMWindow");
  }

  private cancel(): void {
    this.windowClose("private.display.addBGMWindow");
  }

  private getCredit(): void {
    this.creditUrl = this.url.replace(/^(https?:\/\/[^/]+).+$/, "$1");
  }

  private preview() {
    this.setProperty({
      property: "private.display.jukeboxWindow.command",
      logOff: true,
      isNotice: false,
      value: {
        command: "add",
        payload: {
          key: "bgm-add",
          url: this.url,
          title: this.title,
          creditUrl: this.creditUrl,
          tag: this.tag,
          isLoop: this.isLoop,
          start: parseInt(String(this.start), 10),
          end: parseInt(String(this.end), 10),
          fadeIn: Math.floor(parseFloat(String(this.fadeIn)) * 10) / 10,
          fadeOut: Math.floor(parseFloat(String(this.fadeOut)) * 10) / 10,
          isMute: this.isMute,
          volume: this.volume,
          chatLinkage: this.chatLinkage,
          chatLinkageSearch: this.chatLinkageSearch,
          forceReset: this.forceReset
        }
      }
    });
  }

  private change(this: any, param: string): void {
    this[param] = !this[param];
  }

  private setIsMute(isMute: boolean): void {
    this.isMute = isMute;
  }

  private setVolume(volume: string): void {
    this.volume = Math.floor(parseFloat(volume) * 100) / 100;
  }

  private get chatLinkageOptionInfoList() {
    return this.options.map((option: any) => ({
      key: option.value,
      value: option.value,
      text: option.label
    }));
  }

  @Watch("url")
  private onChangeUrl(url: string): void {
    this.isYoutube = /www\.youtube\.com/.test(url);
  }
}
