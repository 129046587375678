



























































































import SelfActorSelect from "../select/SelfActorSelect.vue";
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";

@Component({
  components: {
    SelfActorSelect
  }
})
export default class ActorTabComponent extends Vue {
  @Prop({
    type: Array,
    default: () => {
      [];
    }
  })
  private optionTabInfo!: any[];

  @Getter("getViewName") private getViewName: any;
  @Getter("getObj") private getObj: any;

  private standActorList: any[] = [];
  private activeId: string = "";
  private selectActorKey: string = "";
  private isDiagonal: boolean = false;
  private hoverTabKey: string = "";

  public requestFocus(): void {
    const elm: SelfActorSelect = this.$refs.actorSelect as SelfActorSelect;
    elm.requestFocus();
  }

  private selectTab(activeId: string) {
    this.activeId = activeId;
  }

  private hoverTab(actorKey: string) {
    this.hoverTabKey = actorKey;
  }

  private delTab(actorKey: string) {
    const index = this.standActorList.findIndex(
      actor => actor.key === actorKey
    );
    this.standActorList.splice(index, 1);

    if (this.standActorList.length === 0) {
      this.activeId = "";
      return;
    }

    this.activeId = this.standActorList[
      index < this.standActorList.length ? index : index - 1
    ].key;
  }

  @Emit("change")
  public change(value: string) {}

  @Watch("activeId")
  private onChangeActiveTabIndex(value: number) {
    const actor: any = this.actor;
    this.change(actor ? actor.key : null);
  }

  @Watch("selectActorKey")
  private onChangeSelectActorKey(selectActorKey: string) {
    if (selectActorKey) {
      const actor = this.getObj(selectActorKey);

      // 先頭に新增
      this.standActorList.unshift(actor);
      this.activeId = "";
      setTimeout(() => {
        this.selectActorKey = "";
        this.activeId = selectActorKey;
        this.hoverTabKey = "";
      }, 0);
    }
  }

  private diagonalButtonOnClick() {
    this.isDiagonal = !this.isDiagonal;
  }

  private get actor(): any {
    return this.standActorList.filter(
      (actor: any) => actor.key === this.activeId
    )[0];
  }
}
