






























import ActorStatusCombo from "../ActorStatusCombo.vue";
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";

@Component({
  components: {
    ActorStatusCombo
  }
})
export default class ActorStatusTabComponent extends Vue {
  @Prop({ type: Object, required: true })
  private actor!: any;

  @Action("addActorStatus") private addActorStatus: any;
  @Getter("getObj") private getObj: any;

  private statusList: any[] = [];
  private activeTabIndex: number = -1;
  private selectStatus: string = "";

  selectTab(statusName: string) {
    this.activeTabIndex = this.statusList.findIndex(
      (status: any) => status.name === statusName
    );
  }

  public deleteTab(index: number = this.activeTabIndex): string | null {
    this.activeTabIndex--;
    if (this.activeTabIndex < 0) this.activeTabIndex = 0;
    this.statusList.splice(index, 1);
    const status = this.statusList[this.activeTabIndex];
    return status ? status.name : null;
  }

  delTab(statusName: string) {
    const index = this.statusList.findIndex(
      (status: any) => status.name === statusName
    );
    this.deleteTab(index);
  }

  @Watch("actor")
  onChangeActor(actor: any) {
    this.statusList.splice(0, this.statusList.length);
  }

  @Emit("change")
  public change(value: string) {}

  @Watch("activeTabIndex")
  onChangeActiveTabIndex(value: number) {
    const status: any = this.status;
    this.change(status ? status.name : null);
  }

  @Watch("selectStatus")
  onChangeSelectStatus(selectStatus: string) {
    if (selectStatus) {
      const index = this.actor.statusList.findIndex(
        (status: any) => status.name === selectStatus
      );

      const func = (index: number) => {
        const findIndex = this.statusList.findIndex(
          (status: any) => status.name === selectStatus
        );
        if (findIndex === -1) {
          const status = this.actor.statusList[index];
          this.statusList.unshift(status);
          this.activeTabIndex++;
          setTimeout(() => {
            this.selectStatus = "";
            this.activeTabIndex = 0;
          }, 0);
        } else {
          this.activeTabIndex++;
          setTimeout(() => {
            this.selectStatus = "";
            this.activeTabIndex = findIndex;
          }, 0);
        }
      };

      if (index === -1) {
        this.addActorStatus({
          key: this.actor.key,
          name: selectStatus,
          standImage: {
            ref: "◆",
            base: "",
            baseTag: "imgTag-4",
            autoResize: true,
            animationLength: 1,
            locate: 1,
            diffList: [],
            isSystemLock: false
          }
        }).then(() => {
          func.call(this, this.actor.statusList.length - 1);
        });
      } else {
        func(index);
      }
    }
  }

  get status(): any {
    if (this.activeTabIndex === -1) return null;
    return this.statusList[this.activeTabIndex];
  }
}
