



































































































































































































































































import ActorSelect from "@/components/parts/select/ActorSelect.vue";
import CharacterSelect from "@/components/parts/select/CharacterSelect.vue";
import CtrlButton from "@/components/parts/CtrlButton.vue";

import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";

@Component({ components: { CtrlButton, ActorSelect, CharacterSelect } })
export default class SurfaceComponent extends Vue {
  @Prop({ type: String, required: true })
  public value!: string;

  @Prop({ type: Object, required: true })
  public surface!: any;

  @Prop({ type: Boolean, required: true })
  private isEditMode!: boolean;

  @Prop({ type: Boolean, required: true })
  private isFront!: boolean;

  @Prop({ type: Number, required: true })
  private tabIndex!: number;

  @Prop({ type: Number, required: true })
  private tabLength!: number;

  @Action("setProperty") setProperty: any;
  @Action("windowOpen") windowOpen: any;
  @Getter("getObj") getObj: any;
  @Getter("playerKey") playerKey: any;

  private selectedActorKey: string = "";

  @Emit()
  private deleteTab() {}

  @Emit()
  private copyTab() {}

  @Emit()
  private moveTabOnClick(isLeft: boolean) {}

  @Emit()
  public input(value: string | null) {}

  private get localValue(): string | null {
    return this.value || "";
  }

  private set localValue(value: string | null) {
    this.input(value);
  }

  @Watch("selectedActorKey")
  private onChangeSelectedCharacter(selectedActorKey: string) {
    if (!selectedActorKey) return;
    this.surface.targetList.push(selectedActorKey);
    this.selectedActorKey = "";
  }

  @Emit()
  private openItemMenu(index: number, hoverMenuX: number, hoverMenuY: number) {}

  @Emit()
  private checkOnChange(checked: boolean, itemIndex: number) {}

  public requestFocus() {
    const inputElm: HTMLElement = this.$refs.input as HTMLElement;
    if (inputElm) inputElm.focus();
  }

  @Emit()
  private openImageMenu(
    itemIndex: number,
    imageIndex: number,
    imageListSize: number,
    hoverMenuX: number,
    hoverMenuY: number
  ) {}

  /**
   * 文字內容エリアに輸入される度に、必要に応じて文字內容エリア的顯示大小を拡張是
   */
  private textareaOnInput(event: any) {
    const textarea: HTMLTextAreaElement = event.target as HTMLTextAreaElement;
    if (textarea.scrollHeight > textarea.offsetHeight) {
      textarea.style.height = textarea.scrollHeight + "px";
    }
  }

  /**
   * こ的面的内容を顯示して良いかどうかを調べる
   */
  private isViewableSurface(): boolean {
    // ターゲットが指定されていないなら公開情報
    if (this.surface.targetList.length === 0) return true;

    // ターゲットが指定されている的で、許可されているか調べる
    return this.targetActorList.filter(actor => {
      const type: string = actor.key.split("-")[0];
      if (type === "character") {
        return actor.owner === this.playerKey;
      } else {
        return actor.key === this.playerKey;
      }
    })[0];
  }

  private static getMenuLocate(event: any): any {
    let contentsElm: HTMLElement = event.target;
    while (!contentsElm.classList.contains("contents")) {
      contentsElm = contentsElm.parentNode as HTMLElement;
    }
    const contentsRect = contentsElm.getBoundingClientRect();

    return {
      x: event.pageX - contentsRect.left - 5,
      y: event.pageY - contentsRect.top + 5
    };
  }

  /**
   * 項目設定點擊した時
   */
  private itemConfigOnClick(event: any, index: number) {
    const locate: any = SurfaceComponent.getMenuLocate(event);
    this.openItemMenu(index, locate.x, locate.y);
  }

  private insertImage(itemIndex: number, imageIndex: number) {
    this.surface.contentsList[itemIndex].imageKeyList.splice(imageIndex, 0, {
      key: "image-0",
      tag: "(全部)"
    });
  }

  /**
   * 圖片設定點擊した時
   */
  private imageConfigOnClick(
    event: any,
    itemIndex: number,
    imageIndex: number,
    imageListSize: number
  ) {
    const locate: any = SurfaceComponent.getMenuLocate(event);
    this.openImageMenu(
      itemIndex,
      imageIndex,
      imageListSize,
      locate.x,
      locate.y
    );
  }

  private deleteTargetOnClick(targetKey: string) {
    this.surface.targetList.splice(
      this.surface.targetList.findIndex(
        (listKey: string) => listKey === targetKey
      ),
      1
    );
  }

  /**
   * イメージ枠點擊した時
   * @param imageKey
   * @param itemIndex
   * @param imageIndex
   */
  private imageFrameOnClick(
    imageKey: string,
    itemIndex: number,
    imageIndex: number
  ) {
    if (this.isEditMode) {
      const imageObj: any = this.surface.contentsList[itemIndex].imageKeyList[
        imageIndex
      ];
      Promise.resolve()
        .then(() =>
          // リアクティブ的ため的更新と、それに伴うコールバック的一時無効的ため的指定
          this.setProperty({
            property: "private.display.imageSelectorWindow",
            value: {
              imageKey: null,
              imageTag: null,
              callback: null
            },
            logOff: true
          })
        )
        .then(() => {
          return this.setProperty({
            property: "private.display.imageSelectorWindow",
            value: {
              imageKey,
              imageTag: imageObj.tag,
              callback: this.changeImage.bind(this, itemIndex, imageIndex)
            },
            logOff: true
          });
        })
        .then(() => {
          this.windowOpen("private.display.imageSelectorWindow");
        });
    } else {
      this.setProperty({
        property: `private.display.imageViewWindow.objKey`,
        value: imageKey,
        isNotice: false,
        logOff: true
      });
      this.windowOpen("private.display.imageViewWindow");
    }
  }

  /**
   * 編輯中モードで圖片選擇された時
   * @param itemIndex
   * @param imageIndex
   * @param imageKey
   * @param imageTag
   */
  private changeImage(
    itemIndex: number,
    imageIndex: number,
    imageKey: string,
    imageTag: string
  ) {
    this.surface.contentsList[itemIndex].imageKeyList.splice(imageIndex, 1, {
      key: imageKey.replace(":R", ""),
      tag: imageTag
    });
  }

  private get targetActorList(): any[] {
    return this.surface.targetList.map((actorKey: string) =>
      this.getObj(actorKey)
    );
  }
}
